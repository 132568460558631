 <template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Entrada Salida Caja</span>
        </v-card-title>
        <v-card-text style="max-height: 500px">
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-item-group mandatory v-model="tipo">
                    <v-container>
                      <v-row>
                        <v-col v-for="(item, index) in tipos" :key="index" cols="12" sm="4">
                          <v-item v-slot="{ active, toggle }">
                            <v-card
                              :color="active ? 'primary' : ''"
                              class="d-flex align-center"
                              dark
                              height="50"
                              @click="toggle"
                            >
                              <v-scroll-y-transition>
                                <div class="flex-grow-1 text-center white--text">
                                  {{ item.descripcion }} <v-icon>{{ item.icon }}</v-icon>
                                </div>
                              </v-scroll-y-transition>
                            </v-card>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-item-group>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="pago.id_caja_origen"
                    label="Caja origen"
                    dense
                    outlined
                    :items="cajas"
                    item-text="nombre"
                    item-value="id"
                    hide-details="auto"
                    :rules="reglas.diferenteCaja"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title> No hay cajas </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.nombre }} / {{ item.nombre_completo }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.nombre }}
                          <br />
                          <small> {{ item.nombre_completo }}</small>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="tipo == 2">
                  <v-autocomplete
                    v-model="pago.id_caja_destino"
                    label="Caja Destino"
                    dense
                    outlined
                    :items="cajas"
                    item-text="nombre"
                    item-value="id"
                    hide-details="auto"
                    :rules="reglas.diferenteCaja"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title> No hay cajas </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span>{{ item.nombre }} / {{ item.nombre_completo }}</span>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.nombre }}
                          <br />
                          <small> {{ item.nombre_completo }}</small>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <div class="secondary">
                    <v-container>
                      <v-row>
                        <v-bottom-navigation
                          v-model="pago.moneda"
                          class="pt-2"
                          color="success"
                          background-color="transparent"
                          light
                        >
                          <v-btn
                            v-for="(item, index) in monedasFilter()"
                            :key="index"
                            :value="item"
                            color="white"
                            class="mx-2"
                          >
                            <span>{{ item.nombre }}</span>
                            {{ item.simbolo }}
                          </v-btn>
                        </v-bottom-navigation>
                      </v-row>
                      <v-row>
                        <v-col class="text-center py-0 my-0">
                          <v-btn
                            class="ma-2"
                            :color="item.id == pago.id_tipo_pago ? 'primary' : 'white'"
                            small
                            v-for="(item, index) in pago.moneda.tipo_pago"
                            :key="'btn-' + index"
                            @click="SelectFormaPago(item)"
                          >
                            {{ item.nombre }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
                <v-col :md="!tasaIgualDolar() ? '6' : '12'" class="my-0 py-0">
                  <v-text-field
                    v-model="pago.monto_moneda"
                    v-bind:label="'Monto moneda'"
                    :prefix="pago.moneda.simbolo"
                    outlined
                    clearable
                    persistentHint
                    dense
                    type="number"
                    @keyup="calcularMontoDolar(1)"
                    ref="montoMonedaRef"
                    :rules="reglas.required"
                  />
                </v-col>
                <v-col md="6" class="my-0 py-0" v-if="!tasaIgualDolar()">
                  <v-text-field
                    v-model="pago.monto_real"
                    :label="'Monto $'"
                    prefix="$"
                    outlined
                    clearable
                    persistentHint
                    dense
                    type="number"
                    @keyup="calcularMontoDolar(2)"
                    :rules="reglas.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    rows="2"
                    v-model="pago.descripcion"
                    label="Descripcion"
                    clearable
                    dense
                    hide-details="auto"
                    outlined
                    :rules="reglas.required"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="primary" small @click="guardar()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

 
<script>
import { ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
import { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiSwapVerticalCircleOutline } from '@mdi/js'
export default {
  watch: {
    'pago.moneda': function (news, old) {
      if (news != undefined && news?.tipo_pago != undefined) {
        console.log('news', news?.tipo_pago)
        this.SelectFormaPago(news?.tipo_pago[0])
      }

      this.pago.monto_real = ''
      this.pago.monto_moneda = ''
    },
    tipo: function (news, old) {
      if (news + 1 != 3) {
        this.pago.id_empleado_destino = null
        this.pago.id_caja_destino = null
        this.validarForm()
      }
    },
  },
  setup() {
    const comanda_pagoINIT = {
      id: 0,
      id_caja_origen: 0,
      id_caja_destino: 0,
      id_empleado_origen: 0,
      id_empleado_destino: 0,
      id_empleado_responsable: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      id_tipo_movimiento_caja: 1,
      id_tipo_pago: '',
      monto_moneda: '',
      monto_real: '',
      descripcion: '',
      tasa: 0,
      id_estado: 81,
    }
    const form = ref(null)
    const pago = ref(
      JSON.parse(
        JSON.stringify({
          ...comanda_pagoINIT,
          id_caja_origen: store.state.user.caja.id,
        }),
      ),
    )
    const dialog = ref(false)
    const tipo = ref(0)
    const tipos = ref([
      { id: 1, descripcion: 'Entrada', icon: mdiPlusCircleOutline },
      { id: 2, descripcion: 'Salida', icon: mdiMinusCircleOutline },
      { id: 3, descripcion: 'Transferencia', icon: mdiSwapVerticalCircleOutline },
    ])
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }

    const guardar = () => {
      if (validarForm()) {
        pago.value.id_tipo_movimiento_caja = tipos.value[tipo.value].id
        pago.value.id_empleado_origen = cajas.value.find(item => item.id == pago.value.id_caja_origen)?.empleado_actual
        pago.value.tasa = pago.value.moneda.tasa

        if (pago.value.id_tipo_movimiento_caja == 3) {
          pago.value.id_empleado_destino = cajas.value.find(
            item => item.id == pago.value.id_caja_destino,
          )?.empleado_actual
        } else {
          pago.value.id_empleado_destino = null
          pago.value.id_caja_destino = null
        }

        ComandaService.cajaEntradaSalidaActualizar(pago.value)
          .then(response => {
            if (response.data.estatus) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              limpiar()
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
              funcion: 'guardar',
            })
          })

        console.log('guardar', pago.value)
      }
    }
    const limpiar = () => {
      pago.value = JSON.parse(
        JSON.stringify({
          ...comanda_pagoINIT,
          id_caja_origen: store.state.user.caja.id,
          id_empleado_responsable: pago.value.id_empleado_responsable,
        }),
      )
    }
    const cajas = ref([])
    const EmpleadoEncargado = ref(null)
    const abrir = _dato => {
      console.log(_dato)

      dialog.value = true
      cargarCajas()
      CargarDatosGenerales()

      pago.value = JSON.parse(
        JSON.stringify({
          ...comanda_pagoINIT,
          id_caja_origen: store.state.user.caja.id,
          id_empleado_responsable: _dato.id_empleado_autoriza,
        }),
      )
    }
    const calcularMontoDolar = valor => {
      console.log(valor)
      if (valor == 1) {
        pago.value.monto_real = (Number(pago.value.monto_moneda) / Number(pago.value.moneda.tasa)).toFixed(2)
      } else {
        pago.value.monto_moneda = (Number(pago.value.monto_real) * Number(pago.value.moneda.tasa)).toFixed(2)
      }
    }
    const cargarCajas = () => {
      MaestroService.CajasListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            //  mesoneros.value =response.data.datos
            cajas.value = response.data.datos.filter(item => item.empleado_actual != null)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const monedas = ref([])
    const CargarDatosGenerales = () => {
      ComandaService.obtenerDatosGeneralesComanda()
        .then(response => {
          monedas.value = response.data.datos.monedas
          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
        })
    }

    const monedasFilter = () => {
      return monedas.value?.filter(item => item.id != 4)
    }
    const SelectFormaPago = item => {
      pago.value.id_tipo_pago = item.id
      pago.value.monto_real = ''
      pago.value.monto_moneda = ''
    }

    const tasaIgualDolar = () => {
      if (!pago.value.moneda.tasa) {
        return true
      } else {
        if (pago.value.moneda.tasa == 1) {
          return true
        } else {
          return false
        }
      }
    }
    return {
      dialog,
      abrir,
      tipo,
      tipos,
      cajas,
      pago,
      monedasFilter,
      monedas,
      SelectFormaPago,
      calcularMontoDolar,
      tasaIgualDolar,
      guardar,
      validarForm,
      form,
      reglas: {
        required: [value => !!value || 'Requerido.'],
        diferenteCaja: [
          value => !!value || 'Requerido.',
          value => pago.value.id_caja_destino != pago.value.id_caja_origen || 'No pueden ser las cajas iguales',
        ],
      },
      icons: {
        mdiPlusCircleOutline,
        mdiMinusCircleOutline,
        mdiSwapVerticalCircleOutline,
      },
    }
  },
}
</script>