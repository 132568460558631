import axios from 'axios';
import config from './config';

export default axios.create({
  baseURL: `${config.api_estacionamiento}`,
  auth: {
    username: 'prueba',
    password: 'prueba'
  }, 
});
