 
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <v-row n class="mt-1">
            <v-col>
              <span class="text-h5">Transacciones</span>

              <v-btn :loading="cargandoDatos" icon x-small color="secondary" @click="cargarTransacciones(punto_venta)">
                <v-icon small>{{ icons.mdiRefresh }}</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="auto" class="text-right">
              <v-btn small class="info mr-1">Imprimir</v-btn>
              <v-btn small class="info mr-1">PDF</v-btn>
              <v-btn small class="info mr-1">Excel</v-btn>
              <v-btn small class="primary" @click="abrirDialogo2()">Nueva Transaccion</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div class="text-center" v-if="cargandoDatos">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <p>Cargando...</p>
          </div>
          <v-row class="mt-1" v-if="datos.length > 0">
            <v-col cols="6">
              <v-text-field hide-details v-model="Filtros.filtro" dense outlined label="Filtrar"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-model="Filtros.desde"
                dense
                outlined
                label="Monto Desde"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                hide-details
                v-model="Filtros.hasta"
                dense
                outlined
                label="Monto Hasta"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-simple-table dense style="max-height: 300px" height="300px" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Fecha</th>
                  <th class="text-left">Tipo Transaccion</th>
                  <th class="text-left">No doc</th>
                  <th class="text-right">Trans</th>
                  <th class="text-left">Tipo Pago</th>
                  <th class="text-left">ref</th>
                  <th class="text-right">Monto</th>
                  <th class="text-right">Accion</th>
                </tr>
              </thead>
              <tbody v-for="(tr, index) in datosFiltrados()" :key="index">
                <tr :class="tr.id_estado == 6 ? 'error1' : ''">
                  <td>
                    <small
                      >{{ moment(tr.fecha_creacion).format('YYYY-MM-DD') }} <br />
                      {{ moment(tr.fecha_creacion).format('HH:mm:SS') }}
                    </small>
                  </td>
                  <td>
                    <small>
                      {{ tr.descripcion_tipo_transaccion }}
                      <small v-if="tr.id_estado == 6">
                        <br />
                        ANULADO
                      </small>
                    </small>
                  </td>
                  <td>
                    {{ tr.id_documento_padre }}
                  </td>
                  <td class="text-right">
                    {{ tr.transaccion }}
                  </td>
                  <td>
                    <small>{{ tr.nombre_tipo_pago }}</small>
                  </td>
                  <td>
                    <small>{{ tr.referencia }}</small>
                  </td>

                  <td class="text-right">
                    <small> {{ tr.simbolo }}.{{ tr.monto_moneda }} </small>
                  </td>
                  <td>
                    <v-row>
                      <v-btn
                        v-if="tr.id_tipo_transaccion != 3"
                        x-small
                        color="info"
                        icon
                        @click="abrirModalComanda(tr.id_documento_padre)"
                      >
                        <v-icon small>{{ icons.mdiEye }} </v-icon>
                      </v-btn>
                      <v-btn
                        @click="anularTransaccion(tr)"
                        v-if="tr.id_tipo_transaccion == 3"
                        :disabled="tr.id_estado == 6"
                        x-small
                        color="error"
                        icon
                      >
                        <v-icon small>{{ icons.mdiClose }} </v-icon>
                      </v-btn>
                      <v-btn x-small class="ml-1" icon color="secondary" @click="abrirDialogoDetalle(tr)">
                        <v-icon small>{{ icons.mdiFormatListBulleted }} </v-icon>
                      </v-btn>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-h5">Agregar Nueva Transaccion</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-select
                  :rules="reglas.requerido"
                  v-model="nueva_transaccion.id_tipo_pago"
                  item-value="id_tipo_pago"
                  item-text="tipo_pago_nombre"
                  :items="tipos_pago"
                  hide-details
                  dense
                  outlined
                  label="Tipo Pago"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  :rules="reglas.requerido"
                  type="number"
                  :prefix="tipo_pago_select().moneda_simbolo"
                  hide-details
                  dense
                  outlined
                  label="Monto"
                  v-model="nueva_transaccion.monto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :rules="reglas.requerido"
                  hide-details
                  dense
                  outlined
                  label="Ref"
                  v-model="nueva_transaccion.referencia"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :rules="reglas.requerido"
                  dense
                  outlined
                  v-model="nueva_transaccion.descripcion"
                  label="Descripcion"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialog2 = false"> Cerrar </v-btn>
          <v-btn small color="primary" @click="agregar()" :loading="guardando"> Agregar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetalle" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Numero transaccion : {{ transaccionSelect.id }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <strong>Fecha</strong> <br />
              <span>{{ transaccionSelect.fecha }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Tipo Transaccion</strong> <br />
              <span>{{ transaccionSelect.descripcion_tipo_transaccion }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Tipo Pago</strong> <br />
              <span>{{ transaccionSelect.nombre_tipo_pago }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Nro Transaccion</strong> <br />
              <span>{{ transaccionSelect.transaccion }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Numero Documento</strong> <br />
              <span>{{ transaccionSelect.id_documento_padre }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Monto</strong> <br />
              <span>{{ transaccionSelect.simbolo }} {{ transaccionSelect.monto_moneda }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Referencia</strong> <br />
              <span>{{ transaccionSelect.referencia }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Descripcion</strong> <br />
              <span>{{ transaccionSelect.descripcion }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Fecha Crea</strong> <br />
              <span>{{ transaccionSelect.fecha_creacion }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Fecha Modifica</strong> <br />
              <span>{{ transaccionSelect.fecha_modificacion }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Empleado Crea</strong> <br />
              <span>{{ transaccionSelect.empleado_crea }}</span>
            </v-col>
            <v-col cols="6">
              <strong>Empleado Modifica</strong> <br />
              <span>{{ transaccionSelect.empleado_modifica }}</span>
            </v-col>

            <v-col cols="6">
              <strong>Estado</strong> <br />
              <span>{{ transaccionSelect.id_estado == 5 ? 'ACTIVO' : 'ANULADO' }}</span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogDetalle = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalVerComanda
      :comanda="comandaSelect"
      :soloVer="false"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    />
    <ModalConfirmacion ref="ModalConfirmacionRef" />
  </v-row>
</template>

 
<script>
import { ref } from '@vue/composition-api'
import CuentasService from '@/api/servicios/CuentasService'
import moment from 'moment'
import { mdiEye, mdiClose, mdiFormatListBulleted, mdiRefresh, mdiInformation } from '@mdi/js'
import store from '@/store'
import ModalVerComanda from '@/views/sistema/administracion/dashboard/componentes/ModalVerComanda.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
export default {
  components: {
    ModalVerComanda,
    ModalConfirmacion,
  },
  setup(props, context) {
    const dialog = ref(false)
    const dialogDetalle = ref(false)
    const cargandoDatos = ref(false)
    const datos = ref([])
    const cargando = ref(false)
    const Filtros = ref({
      filtro: '',
      desde: '',
      hasta: '',
    })
    const form = ref(null)
    const nueva_transaccion = ref({
      id_tipo_pago: null,
      monto: '',
      referencia: '',
      descripcion: '',
    })

    const anularTransaccion = item => {
      ModalConfirmacionRef.value.abrir({
        inputs: [
          {
            valor: '', //valor ingresado por el usuario
            nombreObjeto: 'motivo', //objeto q se devolvera en la funcion callback
            texto: 'Motivo', //valor a postrar en laberl de input
            obligatorio: true, //valdiar antes de dar condifrmar
            tipoInput: 'textArea', //text,number,textArea,select,checkbox
            opciones: [], //validos para select y checkbox
          },
        ],
        subTitulo: `¿Esta seguro que quiere anular la transaccion No ${item.transaccion}  Tipo: ${item.nombre_tipo_pago} Monto: ${item.monto_moneda} ?`,
        icon: mdiInformation,
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si, Seguro.',
          color: 'primary',
          funcionDatos: {
            id: item.id,
            id_empleado: store.state.user.id_empleado,
            //motivo,
          },
          funcion: async _dat => {
            try {
              await CuentasService.puntoVentaTransaccionAnular(_dat)
                .then(response => {
                  if (response.data.estatus) {
                    cargarTransacciones(punto_venta.value)
                    context.emit('GetActualizarPuntos', '')
                    store.commit('setAlert', {
                      message: response.data.mensaje,
                      type: 'success',
                    })
                  } else {
                    store.commit('setAlert', {
                      message: response.data.mensaje,
                      type: 'warning',
                    })
                  }
                })
                .catch(error => {
                  store.commit('setAlert', {
                    message: `error N1: ${error}`,
                    type: 'error',

                    error: {
                        ...error, 
                        response: error?.response?.data
                    },
                    funcion: 'anularTransaccion',
                  })
                })
            } catch (error) {
              store.commit('setAlert', {
                message: `error N2: ${error}`,
                type: 'error',

                error: error,
                funcion: 'anularTransaccion',
              })
            }
            ModalConfirmacionRef.value.cerrar()
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {
            ModalConfirmacionRef.value.cerrar()
          },
        },
      })
    }

    const ModalConfirmacionRef = ref()
    const limpiar = () => {
      nueva_transaccion.value = {
        id_tipo_pago: null,
        monto: '',
        referencia: '',
        descripcion: '',
      }
      form.value.resetValidation()
    }
    const comandaSelect = ref({ id: 0 })
    const abrirModalComanda = item => {
      comandaSelect.value = { id: item }
      openModalComanda.value = true
    }
    const openModalComanda = ref(false)
    const GetOpenModalComanda = item => {
      openModalComanda.value = item
    }
    const transaccionSelect = ref({})

    const abrirDialogoDetalle = item => {
      transaccionSelect.value = item
      dialogDetalle.value = true
    }
    const dialog2 = ref(false)
    const datosFiltrados = () => {
      let _datos = datos.value

      if (Filtros.value.filtro != '') {
        _datos = datos.value.filter(
          item =>
            item.descripcion_tipo_transaccion.toString().toUpperCase().includes(Filtros.value.filtro.toUpperCase()) ||
            item.referencia.toUpperCase().includes(Filtros.value.filtro.toUpperCase()) ||
            item.id_documento.toString().toUpperCase().includes(Filtros.value.filtro.toUpperCase()) ||
            item.transaccion.toString().toUpperCase().includes(Filtros.value.filtro.toUpperCase()) ||
            item.nombre_tipo_pago.toUpperCase().includes(Filtros.value.filtro.toUpperCase()),
        )
      }

      if (Filtros.value.desde != '' && Filtros.value.desde > 0) {
        _datos = _datos.filter(items => items.monto_moneda >= Filtros.value.desde)
      }

      if (Filtros.value.hasta != '' && Filtros.value.hasta > 0) {
        _datos = _datos.filter(item => item.monto_moneda <= Filtros.value.hasta)
      }

      return _datos
    }
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    const tipo_pago_select = () => {
      let valor = {}
      tipos_pago.value.forEach(item => {
        if (item.id_tipo_pago == nueva_transaccion.value.id_tipo_pago) {
          valor = item
        }
      })

      return valor
    }

    const agregar = () => {
      if (validarForm()) {
        puntoVentaTransaccionNuevo({
          ...nueva_transaccion.value,
          id_punto_venta: tipo_pago_select().id_punto_venta,
        })
      } else {
        alert('false')
      }
    }

    const abrir = id_punto_venta => {
      dialog.value = true
      cargarTransacciones({ id_punto_venta })
      Filtros.value = {
        filtro: '',
        desde: '',
        hasta: '',
      }
    }
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }
    const abrirDialogo2 = () => {
      dialog2.value = true
    }
    const tipos_pago = ref([])

    const guardando = ref(false)

    const puntoVentaTransaccionNuevo = _datos => {
      guardando.value = true
      CuentasService.puntoVentaTransaccionNuevo(_datos)
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'GUARDADO CON EXITO') {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'success',
            })
            limpiar()
            cargarTransacciones(_datos)
            context.emit('GetActualizarPuntos', '')
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',

            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'puntoVentaTransaccionNuevo',
          })
        })
        .finally(() => {
          guardando.value = false
        })
    }
    const punto_venta = ref(null)
    const cargarTransacciones = _datos => {
      punto_venta.value = _datos
      cargandoDatos.value = true
      datos.value = []
      CuentasService.puntoVentaTransaccionesActualListar(_datos)
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos.datos
            tipos_pago.value = response.data.datos.tipo_pago
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarTransacciones',
          })
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      dialog,
      abrir,
      cargandoDatos,
      datos,
      datosFiltrados,
      Filtros,
      moment,
      icons: {
        mdiEye,
        mdiClose,
        mdiFormatListBulleted,
        mdiRefresh,
      },
      abrirDialogo2,
      dialog2,
      tipos_pago,
      nueva_transaccion,
      tipo_pago_select,
      agregar,
      form,
      limpiar,
      validarForm,
      reglas,
      guardando,
      dialogDetalle,
      transaccionSelect,
      abrirDialogoDetalle,
      punto_venta,
      comandaSelect,
      abrirModalComanda,
      openModalComanda,
      GetOpenModalComanda,
      cargarTransacciones,
      ModalConfirmacionRef,
      anularTransaccion,
    }
  },
}
</script>