<template>
  <v-card>
    <div>
      <v-img
        gradient="to bottom, rgba(0,0,0,.8), rgba(0,0,0,.5)"
        height="100px"
        :src="`${config.api_imagenes}/producto/img/l-${producto.producto.img}`"
        :lazy-src="`${config.api_imagenes}/producto/img/100-agua 300ml.jpg`"
      >
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-btn color="white" icon dark @click="ClickAtras()">
            <v-icon>{{ mdiArrowLeft }}</v-icon>
          </v-btn>
          <v-toolbar-title class="text-h7 white--text pl-0"> {{ producto.producto.nombre }} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-img>

      <div class="lista-productos">
        <modal-descripcion-producto :producto="producto"></modal-descripcion-producto>
        <v-card-subtitle class="pb-0"> {{ producto.producto.nombre_puesto }} </v-card-subtitle>
        <br />
        <v-container>
          <v-row>
            <v-col cols="2" class="text-center">
              <v-btn fab dark x-small color="primary" @click="IncrementarCantidad(-1)">
                <v-icon dark>{{ mdiMinus }}</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="8" class="text-center">
              <v-text-field
                hide-details="auto"
                dense
                outlined
                v-model="cantidad"
                label="Cantidad"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="text-center">
              <v-btn fab dark x-small color="primary" @click="IncrementarCantidad(1)">
                <v-icon dark>{{ mdiPlus }}</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" :md="extras.length > 0 && extras.length > 0 ? '6' : '12'" v-if="ingredientes.length > 0">
              <v-btn
                :disabled="ingredientes.filter(item => item.opcional).length == 0"
                class="py-6"
                color="primary"
                @click="AbrirIngredienteExtra(1)"
                small
                block
              >
                <v-avatar size="20" left class="white mr-2">
                  <span class="black--text"> {{ ingredientes.filter(item => item.opcional).length }} </span>
                </v-avatar>
                Ingredientes</v-btn
              >
              <span v-if="ingredientes.filter(item => !item.checkbox).length > 0">
                <small>
                  Ingredientes Excluidos
                  <br />
                </small>
                <v-chip
                  v-for="(item, index) in ingredientes.filter(item => !item.checkbox)"
                  :key="index"
                  class="ma-1"
                  :color="item.checkbox ? 'primary' : 'error'"
                  x-small
                >
                  {{ item.nombre }}
                </v-chip>
              </span>
            </v-col>
            <v-col cols="12" :md="extras.length > 0 && extras.length > 0 ? '6' : '12'" v-if="extras.length > 0">
              <v-btn
                :disabled="extras.length == 0"
                class="py-6"
                color="success"
                @click="AbrirIngredienteExtra(2)"
                small
                block
              >
                <v-avatar size="20" left class="white mr-2">
                  <span class="black--text">{{ extras.length }}</span>
                </v-avatar>
                Extras</v-btn
              >
              <span v-if="extras.filter(item => item.checkbox).length > 0">
                <small> Extras </small>
                <br />
                <v-chip
                  v-for="(item, index) in extras.filter(item => item.checkbox)"
                  :key="index"
                  class="ma-1"
                  color="success"
                  x-small
                >
                  {{ item.cantidad }} / {{ item.nombre }} / ${{ item.cantidad * item.precio_venta }}
                </v-chip>
              </span>
            </v-col>
            <v-col cols="12" v-if="IngredienteMaximo > 0">
              <v-card @click="AbrirIngredienteExtra(3)" class="primary">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center white--text my-0 py-0"> Acompañantes </v-col>
                    <v-col class="text-center white--text py-0">
                      <small>Opciones</small> <br />
                      {{ ingredientesVariableCant }}
                    </v-col>
                    <v-col class="text-center py-0 white--text">
                      <small>Seleccionadas</small> <br />
                      {{ ingredientesVariableCantSelect }} de {{ IngredienteMaximo }}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-progress-linear
                  color="success"
                  :background-color="ingredientesVariableCantSelect < IngredienteMinimo ? 'error' : ''"
                  height="5"
                  :value="(ingredientesVariableCantSelect / IngredienteMaximo) * 100"
                  striped
                ></v-progress-linear>
              </v-card>
              <span v-if="ingredientesVariable.filter(item => item.checkbox).length > 0">
                <small> Acompañantes Selecionados </small>
                <br />
                <v-chip
                  v-for="(item, index) in ingredientesVariable.filter(item => item.checkbox)"
                  :key="index"
                  class="ma-1"
                  color="success"
                  x-small
                >
                  {{ item.cantidad }} / {{ item.nombre }}
                </v-chip>
              </span>
            </v-col>
            <!--   <v-col cols="12" class="py-0" v-if="ingredientes.filter(item => !item.checkbox).length > 0">
              <small>
                Ingredientes Excluidos
                <br />
              </small>

              <v-chip
                v-for="(item, index) in ingredientes.filter(item => !item.checkbox)"
                :key="index"
                class="ma-1"
                :color="item.checkbox ? 'primary' : 'error'"
                x-small
              >
                {{ item.nombre }}
              </v-chip>
              <v-divider class="mt-1"></v-divider>
            </v-col>

            <v-col cols="12" class="py-0" v-if="extras.filter(item => item.checkbox).length > 0">
              <small>Extras <br /></small>

              <v-chip
                v-for="(item, index) in extras.filter(item => item.checkbox)"
                :key="index"
                class="ma-1"
                color="success"
                x-small
              >
                {{ item.cantidad }} / {{ item.nombre }} / ${{ item.cantidad * item.precio_venta }}
              </v-chip>
              <v-divider class="mt-1"></v-divider>
            </v-col>
            <v-col cols="12" class="py-0" v-if="ingredientesVariable.filter(item => item.checkbox).length > 0">
              <small>Acompañantes <br /></small>

              <v-chip
                v-for="(item, index) in ingredientesVariable.filter(item => item.checkbox)"
                :key="index"
                class="ma-1"
                color="success"
                x-small
              >
                {{ item.cantidad }} / {{ item.nombre }} 
              </v-chip>
              <br />
            </v-col>-->
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-if="producto.producto.ind_responsable"
                hide-details="auto"
                v-model="mesonero"
                :items="mesoneros"
                label="Empleado"
                item-value="id"
                outlined
                :prepend-icon="mdiAccountStar"
                dense
                item-text="nombre_completo"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-textarea
                outlined
                name="input-7-4"
                label="Observación"
                hide-details=""
                v-model="observacion"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <v-footer dark padless class="footer-detalle">
      <v-container class="grey lighten-5 mb-6">
        <v-row class="d-flex justify-center mb-2 mt-0 white--text"> Total: {{ round(Total) }}$ </v-row>
        <v-row>
          <v-btn
            :disabled="!validarAcciones(acciones.DASHBOARD_COMANDA_AGREGAR_PRODUCTO)"
            @click="AgregarProducto"
            block
            color="primary"
          >
            Agregar
          </v-btn>
        </v-row>
      </v-container>
    </v-footer>
    <ModalIngredientesVue
      @aceptar="ModalIngredientesAceptar"
      ref="ModalIngredientesVueRef"
      :Tipo="ModalIngredientesVueTipo"
      :Lista="ModalIngredientesVueList"
      :minino="IngredienteMinimo"
      :maximo="IngredienteMaximo"
      :indMaximo="indMaximo"
    />
    <ConfirmacionUsuario @GetConfirmacionUsuario="GetConfirmacionUsuario" ref="ConfirmacionUsuarioRef" />
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import config from '@/api/config'
import { mdiArrowLeft, mdiPlus, mdiInformationOutline, mdiFolderOutline, mdiMinus } from '@mdi/js'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import store from '@/store'
import { acciones } from '@/modulos'
import ModalDescripcionProducto from './ModalDescripcionProducto.vue'
import ConfirmacionUsuario from '@/components/ConfirmacionUsuario.vue'
import ModalIngredientesVue from './ModalIngredientes.vue'
export default {
  components: { ConfirmacionUsuario, ModalDescripcionProducto, ModalIngredientesVue },
  props: {
    producto: Object,
    mesoneros: Array,
  },
  setup(props, context) {
    const ModalIngredientesVueList = ref([])
    const ModalIngredientesVueTipo = ref(1)
    const ModalIngredientesVueRef = ref(null)
    const ingredientes = ref([])
    const ingredientesVariable = ref([])
    const extras = ref([])
    const cantidad = ref(1)
    const observacion = ref('')
    const totalExtra = ref(0)
    const Total = ref(0)
    const mesonero = ref(store.state.user.id_empleado)
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }

    const GetConfirmacionUsuario = (_funcion, _datos, _funcionCancel, _cancel) => {
      if (_cancel) {
        _funcionCancel()
      } else {
        _funcion(_datos)
      }
    }
    const ConfirmacionUsuarioRef = ref(false)
    const IngredienteMinimo = ref(1)
    const IngredienteMaximo = ref(1)
    const indMaximo = ref(false)
    const AbrirIngredienteExtra = tipo => {
      ModalIngredientesVueTipo.value = tipo
      var lista = []
      if (tipo == 1) {
        lista = JSON.parse(JSON.stringify(ingredientes.value))
        indMaximo.value = false
      } else if (tipo == 2) {
        lista = JSON.parse(JSON.stringify(extras.value))
        indMaximo.value = false
      } else {
        lista = JSON.parse(JSON.stringify(ingredientesVariable.value))
        indMaximo.value = true
      }

      ModalIngredientesVueRef.value.abrir(true, lista)
    }
    const changeIngredientes = () => {
      console.log(ingredientes)
    }
    const changeExtras = () => {
      let sum = 0
      extras.value.forEach(item => {
        if (item.checkbox) {
          sum += item.precio_venta * item.cantidad
        }
      })
      totalExtra.value = sum
      CalcularTotal()
    }

    const ModalIngredientesAceptar = dato => {
      if (ModalIngredientesVueTipo.value == 1) {
        ingredientes.value = dato
      }
      if (ModalIngredientesVueTipo.value == 2) {
        extras.value = dato
        changeExtras()
      }
      if (ModalIngredientesVueTipo.value == 3) {
        ingredientesVariable.value = dato
        var cant = 0
        ingredientesVariable.value.forEach(item => {
          if (item.checkbox) {
            cant += item.cantidad
          }
        })
        ingredientesVariableCantSelect.value = cant
      }
    }

    const CalcularTotal = () => {
      console.log(props.producto.producto)

      Total.value = totalExtra.value * cantidad.value + props.producto.producto.precio_venta * cantidad.value
    }

    const ingredientesVariableCant = ref(0)
    const ingredientesVariableCantSelect = ref(0)

    const inicial = () => {
      mesonero.value = props.producto.id_empleado_responsable
      cantidad.value = props.producto.cantidad
      ingredientes.value = []
      ingredientesVariable.value = []
      let extrasar = []
      props.producto.producto.extras?.forEach(item => {
        extrasar.push({ ...item })
      })

      extras.value = JSON.parse(JSON.stringify(extrasar))

      ingredientes.value = JSON.parse(
        JSON.stringify(props.producto.producto?.ingredientes == undefined ? [] : props.producto.producto?.ingredientes),
      )

      ingredientesVariable.value = JSON.parse(
        JSON.stringify(
          props.producto.producto?.ingredientes_variables == undefined
            ? []
            : props.producto.producto?.ingredientes_variables,
        ),
      )

      ingredientesVariableCant.value = ingredientesVariable.value.length
      ingredientesVariableCantSelect.value = 0
      IngredienteMinimo.value = props.producto.producto.receta.variable_minimo
      IngredienteMaximo.value = props.producto.producto.receta.variable_maximo
      totalExtra.value = 0
      CalcularTotal()
      observacion.value = props.producto.observacion
    }

    onBeforeMount(() => {
      inicial()
    })

    watch(cantidad, () => {
      CalcularTotal()
    })

    watch(props, () => {
      inicial()
    })
    /*
    watch(ingredientes, () => {
      console.log(ingredientes.value)
       
    })*/

    const EliminarIngrediente = item => {
      const ing = ingredientes.value.filter(val => item.id != val.id)
      ingredientes.value = [...ing]
      console.log(ingredientes.value)
    }

    const EliminarExtras = item => {
      extras.value.splice(extras.value.indexOf(item), 1)
    }

    const IncrementarCantidad = valor => {
      console.log(cantidad.value)
      console.log(valor)
      if (valor > 0) {
        cantidad.value = Number(cantidad.value) + 1
      } else {
        if (cantidad.value - 1 !== 0) {
          cantidad.value = Number(cantidad.value) - 1
        }
      }
    }

    const ClickAtras = () => {
      console.log('Atras')
      context.emit('GetAtrasProductoDetalle', {})
    }
    const AgregarProducto = () => {
      if (cantidad.value > 0) {
        if (
          (ingredientesVariableCant.value > 0 && ingredientesVariableCantSelect.value >= IngredienteMinimo.value) ||
          ingredientesVariableCant.value == 0
        ) {
          if (props.producto.producto.nivel_seguridad > 0) {
            let accion = 143

            if (props.producto.producto.nivel_seguridad == 1) {
              accion = 141
            }else  if (props.producto.producto.nivel_seguridad == 2) {
              accion = 142
            }else  if (props.producto.producto.nivel_seguridad == 3) {
              accion = 143
            }



            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: accion },
              (_dato) => {
                  
                 const ingre = ingredientes.value
                 console.log('ingredientes')
                 console.log(ingre !== undefined ? ingre : [])
                 console.log('precio_real: ' + totalExtra.value + props.producto.producto.precio_venta)
                 console.log(props.producto.id)
 
                 context.emit('GetAgregarProducto', {
                   ingredientes: ingre !== undefined ? ingre : [],
                   extras: extras.value.filter(item => item.checkbox),
                   ingredientes_variables: ingredientesVariable.value,
                   cantidad: cantidad.value,
                   observacion: observacion.value,
                   producto: props.producto,
                   total_extra: totalExtra.value,
                   precio_total: Total.value,
                   precio_real: totalExtra.value + props.producto.producto.precio_venta,
                   precio_venta: props.producto.producto.precio_venta,
                   iva: round(Total.value * (props.producto.producto.impuesto_tasa_valor / 100)),
                   id: props.producto.id,
                   id_empleado_responsable: _dato.id_empleado_autoriza,
                 })
               },
              {},
              ()=>{}
             ,
            )
          } else {
            const ingre = ingredientes.value
            console.log('ingredientes')
            console.log(ingre !== undefined ? ingre : [])
            console.log('precio_real: ' + totalExtra.value + props.producto.producto.precio_venta)
            console.log(props.producto.id)

            context.emit('GetAgregarProducto', {
              ingredientes: ingre !== undefined ? ingre : [],
              extras: extras.value.filter(item => item.checkbox),
              ingredientes_variables: ingredientesVariable.value,
              cantidad: cantidad.value,
              observacion: observacion.value,
              producto: props.producto,
              total_extra: totalExtra.value,
              precio_total: Total.value,
              precio_real: totalExtra.value + props.producto.producto.precio_venta,
              precio_venta: props.producto.producto.precio_venta,
              iva: round(Total.value * (props.producto.producto.impuesto_tasa_valor / 100)),
              id: props.producto.id,
              id_empleado_responsable: mesonero.value,
            })
          }
        } else {
          store.commit('setAlert', {
            message: `Debe seleccionar por lo menos ${IngredienteMinimo.value} acompañante`,
            type: 'warning',
          })
        }
      } else {
        store.commit('setAlert', {
          message: `Debe insertar una cantidad mayor que 0`,
          type: 'warning',
        })
      }
    }

    return {
      IngredienteMinimo,
      IngredienteMaximo,
      indMaximo,
      mdiInformationOutline,
      mdiFolderOutline,
      mdiArrowLeft,
      mdiPlus,
      mdiMinus,
      cantidad,
      observacion,
      ingredientes,
      ingredientesVariable,
      extras,
      Total,
      ClickAtras,
      IncrementarCantidad,
      EliminarIngrediente,
      EliminarExtras,
      changeIngredientes,
      AgregarProducto,
      changeExtras,
      config,
      round,
      validarAcciones,
      acciones,
      ModalIngredientesVueList,
      ModalIngredientesVueTipo,
      ModalIngredientesVueRef,
      AbrirIngredienteExtra,
      ModalIngredientesAceptar,
      ingredientesVariableCant,
      ingredientesVariableCantSelect,
      mesonero,
      ConfirmacionUsuarioRef,
      GetConfirmacionUsuario
    }
  },
}
</script>


<style lang="scss"  >
.lista-productos {
  height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>