<template>
    <div>
        <v-dialog v-model="dialog" scrollable max-width="800px">

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Transferir Comandas Masivo
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col  cols="12" md="6">
                                <v-autocomplete dense outlined :items="mesonerosComanda" v-model="mesonero_origen"
                                    item-text="nombre_completo" item-value="id_empleado" label="Mesonero Origen"
                                    hide-details="auto">

                                </v-autocomplete>

                            </v-col>
                            <v-col cols="12" md="6">
                                <v-autocomplete dense outlined :items="mesoneros" v-model="mesonero_destino"
                                    item-text="nombre_completo" item-value="id_empleado" label="Mesonero Destino"
                                    hide-details="auto">

                                </v-autocomplete>

                            </v-col>
                            <v-col   lg="12" class="text-right">
                                <v-btn color="primary" small @click="agrarLista()">Agregar</v-btn>

                            </v-col>

                            <v-col cols="12">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Origen
                                                </th>
                                                <th class="text-left">
                                                    Destino
                                                </th>
                                                <th class="text-left">
                                                    Accion
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, i) in mesonerosList" :key="i">
                                                <td>{{ item.mesonero_origen_json.nombre_completo }}</td>
                                                <td>{{ item.mesonero_destino_json.nombre_completo }}</td>
                                                <td>

                                                    <v-btn icon color="error" @click="eliminarItem(item.mesonero_origen)">
                                                        <v-icon>{{ icons.mdiClose }}</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" small  append @click="dialog = false">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" small  @click="Transferir()" :disabled="mesonerosList.length == 0">
                        Transferir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <modal-validar-login-vue :datos="ValidarLoginDatos" :abrirModal="ValidarLogin"
            @GetAbrirModal="GetAbrirModalValidarLogin" @GetResultado="GetResultadoValidarLogin"
            @GetCancelar="GetCancelarModalValidarLogin">
        </modal-validar-login-vue>
    </div>
</template>
<script>

import {
    mdiCallSplit,

} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import SeguridadService from '@/api/servicios/SeguridadService'
import store from '@/store'
import ModalValidarLoginVue from '@/views/sistema/seguridad/ModalValidarLogin.vue'
import ComandaService from '@/api/servicios/ComandaService'
import Vue from 'vue'
import { mdiClose } from '@mdi/js'
export default {
    components: {
        ModalValidarLoginVue
    },
    props: {
        modalTransferir: Boolean
    },
    setup(props, context) {
        const settings = ref(null)
        const detalles = ref([])
        const total = ref(0)
        const dialog = ref(false)
        const mesoneros = ref([])
        const mesonero = ref(null)
        const ValidarLogin = ref(false)
        const ValidarLoginDatos = ref({})
        const transfiriendo = ref(false)
        const mesonerosComanda = ref([])
        const mesonerosList = ref([])

        const mesonero_origen = ref(null)
        const mesonero_destino = ref(null)
        const GetAbrirModalValidarLogin = (datos) => {
            ValidarLogin.value = datos

        }
        const GetCancelarModalValidarLogin = () => {
            transfiriendo.value = false

        }

        const agrarLista = () => {
            if (mesonero_origen.value != mesonero_destino.value) {
                if (!mesonerosList.value.find(item => item.mesonero_origen == mesonero_origen.value)) {
                    mesonerosList.value.push({
                        mesonero_origen_json: mesonerosComanda.value.find(item => item.id_empleado == mesonero_origen.value),
                        mesonero_origen: mesonero_origen.value,
                        mesonero_destino: mesonero_destino.value,
                        mesonero_destino_json: mesoneros.value.find(item => item.id_empleado == mesonero_destino.value)
                    })
                    mesonero_origen.value = null
                    mesonero_destino.value = null
                } else {

                    store.commit('setAlert', {
                        message: "El mesonero ya esta en la lista",
                        type: 'warning',
                    })
                }
            } else {

                store.commit('setAlert', {
                    message: "El mesonero debe ser diferente",
                    type: 'warning',
                })

            }



        }

        const eliminarItem = (item) => {

            mesonerosList.value = mesonerosList.value.filter(it => it.mesonero_origen != item)

        }


        const abrir = () => {
            dialog.value = true
            mesonero.value = null
            transfiriendo.value = false
            mesonerosList.value = []
            SeguridadService.listarMesonerosLogin()
                .then(response => {
                    console.log(response)
                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                        //  mesoneros.value =response.data.datos
                        mesoneros.value = []
                        mesoneros.value = response.data.datos
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            SeguridadService.listarMesonerosComanda()
                .then(response => {
                    console.log(response)
                    if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                        //  mesoneros.value =response.data.datos 
                        mesonerosComanda.value = []
                        mesonerosComanda.value = response.data.datos
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
        const GetResultadoValidarLogin = (datos) => {
            ValidarLogin.value = false

            ComandaService.transferirComandaMasiva({ datos: mesonerosList.value, id_empleado : store.state.user.id_empleado  })
                .then(response => {

                    if (response.data.mensaje == 'transferido con exito') {

                        store.commit('setAlert', {
                            message: "transferido con exito",
                            type: 'success',
                        })
                        dialog.value = false

                    } else {

                        store.commit('setAlert', {
                            message: "hubo un error al momento de transferir",
                            type: 'info',
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                }).finally(datos => {
                    transfiriendo.value = false
                })

        }

        const Transferir = () => {
            transfiriendo.value = true
            const mesoneroSelect = mesoneros.value.filter(item => (item.id_empleado == mesonero.value))[0]
            console.log(mesoneroSelect)
            ValidarLogin.value = true
            GetResultadoValidarLogin()

        }



        watch(dialog, () => {

            context.emit('GetModalTransferir', dialog.value)
            if (dialog.value == true) {
                settings.value = null
            }
        })
        const filter = ref("")
        const mesonerosComandaFilter = () => {

            return mesonerosComanda.value.filter(item =>
                item.mesonero_origen_json.nombre_completo.toUpperCase().includes(filter.value.toUpperCase())

            )
        }
        function round(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15))
            return (Math.round(m) / 100) * Math.sign(num)
        }
        return {
            icons: {
                mdiCallSplit
            },
            dialog,
            settings,
            detalles,
            config,
            round,
            total,
            mesonero,
            mesoneros,
            store,
            Transferir,
            ValidarLogin,
            GetAbrirModalValidarLogin,
            GetResultadoValidarLogin,
            ValidarLoginDatos,
            transfiriendo,
            GetCancelarModalValidarLogin,
            abrir,
            mesonerosComanda,
            filter,
            mesonerosComandaFilter,
            agrarLista,
            mesonero_origen,
            mesonero_destino,
            mesonerosList,
            icons: {
                mdiClose
            },
            eliminarItem,

        }
    },
}
</script>