<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="700px" scrollable>
      <v-card>
        <v-card-text>
          <h4>
            Puntos de venta
            <v-btn :loading="cargando" icon x-small color="secondary" @click="puntoVentaCajaListar()">
              <v-icon small>{{ icons.mdiRefresh }}</v-icon>
            </v-btn>
          </h4>
          <div class="text-center" v-if="cargando"> 
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <p>Cargando...</p>
          </div>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody v-for="(pv, index) in punto_venta" :key="index">
                <tr>
                  <td class="text-left py-3" colspan="3">
                    <v-row>
                      <v-col>
                        <v-avatar
                          class="mx-2"
                          :color="pv.banco_img_icon ? '' : 'primary'"
                          :class="pv.banco_img_icon ? '' : 'v-avatar-light-bg white--text'"
                          size="32"
                        >
                          <v-img :src="config.api_imagenes + '/banco/img/' + pv.banco_img_icon"> </v-img>
                        </v-avatar>

                        <span class=" ">{{ pv.banco_nombre }} | {{ pv.codigo }} </span>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn @click="abrirTransacciones(pv.id)" x-small class="info">
                          <v-icon small left>{{ icons.mdiEye }}</v-icon> Transacciones</v-btn
                        >
                        <v-btn x-small class="primary ml-2"
                          ><v-icon small left>{{ icons.mdiCloseBox }}</v-icon> Cerrar Punto</v-btn
                        >
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Tipo Pago</th>
                  <th class="text-right">Monto</th>
                  <th class="text-right">Lote</th>
                </tr>
                <tr v-for="(tp, index) in pv.tipo_pago" :key="index">
                  <td>
                    {{ tp.moneda_nombre }} <br />
                    {{ tp.tipo_pago_nombre }}
                  </td>

                  <td class="text-right">
                    <v-text-field
                      v-if="false"
                      class="my-1"
                      :prefix="tp.moneda_simbolo"
                      v-model="tp.monto_moneda"
                      outlined
                      clearable
                      dense
                      type="number"
                      :hint="'Sistema:' + tp.moneda_simbolo + ' ' + tp.monto_moneda_sistema"
                      persistent-hint
                    />
                    {{ tp.moneda_simbolo + ' ' + tp.monto_moneda_sistema }}
                  </td>
                  <td class="text-right">
                    <v-text-field label="Lote" class="my-1" v-model="tp.lote" outlined clearable dense hide-details />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Salir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirmacion ref="ModalConfirmacionRef" />
    <PuntoVentaTransaccionesActual ref="PuntoVentaTransaccionesActualRef" @GetActualizarPuntos="GetActualizarPuntos" />
  </v-row>
</template>

 
<script>
import { ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import ComandaService from '@/api/servicios/ComandaService'
import CuentasService from '@/api/servicios/CuentasService'

import config from '@/api/config'
import store from '@/store'
import funciones from '@/funciones/funciones'
import {
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiSwapVerticalCircleOutline,
  mdiInformation,
  mdiEye,
  mdiCloseBox,
  mdiRefresh,
} from '@mdi/js'
import ModalConfirmacion from '../../../../../components/ModalConfirmacion.vue'
import PuntoVentaTransaccionesActual from '../../../administracion/banco-caja/punto-venta/components/PuntoVentaTransaccionesActual.vue'
import BtnPdf from '../../../puesto/inventario/ajuste/componentes/BtnPdf.vue'
export default {
  components: { ModalConfirmacion, PuntoVentaTransaccionesActual },

  setup() {
    const PuntoVentaTransaccionesActualRef = ref(null)
    const ModalConfirmacionRef = ref(null)

    const abrirTransacciones = id_punto_venta => {
      PuntoVentaTransaccionesActualRef.value.abrir(id_punto_venta)
    }
    const GetActualizarPuntos = () => {
      puntoVentaCajaListar()
    }

    const ind_cerrar = ref(false)
    const cerrar = (_valor = 0) => {
      if (punto_venta.value.length > 0) {
        ind_cerrar.value = true
      } else {
        ind_cerrar.value = false
        _valor = 1
      }

      if (_valor == 1) {
        let _punto_venta = []

        punto_venta.value.forEach(it => {
          it.tipo_pago.forEach(tp => {
            if (tp.monto_moneda > 0) {
              _punto_venta.push({
                id_punto_venta: it.id,
                id_tipo_pago: tp.id_tipo_pago,
                lote: tp.lote,
                monto_moneda: tp.monto_moneda,
              })
            }
          })
        })

        const _datos = {
          id_empleado: datos.value.id_empleado_responsable,
          id_log_arqueo_caja: arqueoFinal.value.id,
          puntos_ventas: _punto_venta,
        }

        console.log(_datos)

        ModalConfirmacionRef.value.abrir({
          subTitulo: '¿Esta seguro que quiere cerra la caja?',
          icon: mdiInformation,

          btnConfirmar: {
            esperarRespuesta: true,
            texto: 'Si',
            color: 'primary',
            funcion: async () => {
              ind_cerrar.value = true
              await ComandaService.ArqueoCajaCerrarActualizar(impresora)
                .then(response => {
                  try {
                    Vue.swal({
                      icon: 'success',
                      title: `Exito.`,
                      text: `Se envio el reporte X a la impresora. ${response.data}`,
                    })
                  } catch (error) {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `hubo un error: ${error}`,
                    })
                  }
                })
                .catch(error => {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                })
              ModalConfirmacionRef.value.cerrar()
            },
          },
          btnCancelar: {
            texto: 'No',
            color: 'secondary',
            funcion: () => {
              ModalConfirmacionRef.value.cerrar()
            },
          },
        })
      }
    }

    const form = ref(null)

    const punto_venta = ref([])
    const dialog = ref(false)
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    const id_empleado_responsable = ref(null)
    const abrir = _dato => {
      dialog.value = true
      puntoVentaCajaListar()
      id_empleado_responsable.value = _dato.id_empleado_autoriza
    }
    const cargando = ref(false)

    const puntoVentaCajaListar = () => {
      cargando.value = true
      punto_venta.value = []
      CuentasService.puntoVentaActualListar({ fecha: '' })
        .then(response => {
          punto_venta.value = JSON.parse(JSON.stringify(response.data.datos))
          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    return {
      funciones,

      dialog,
      abrir,

      validarForm,
      form,
      cargando,
      icons: {
        mdiPlusCircleOutline,
        mdiMinusCircleOutline,
        mdiSwapVerticalCircleOutline,
        mdiInformation,
        mdiEye,
        mdiCloseBox,
        mdiRefresh,
      },
      GetActualizarPuntos,
      punto_venta,
      puntoVentaCajaListar,
      ModalConfirmacionRef,
      PuntoVentaTransaccionesActualRef,
      abrirTransacciones,
      config,
    }
  },
}
</script>