<template>
  <span>
    <v-btn block color="warning" x-small @click="dialog = true"> Propina </v-btn>
    <v-dialog v-model="dialog" width="500" persistent>
      <template> </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Propinas </v-card-title>

        <v-card-text>
          <formas-pagos
            ref="formaPagoRef"
            @GetPagos="agregarPropinas"
            :monedas="monedas"
            :pagosInt="propinas"
            :dialog="dialog"
          ></formas-pagos>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="primary" text @click="GuardarPropinas()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import FormasPagos from './FormasPagosGeneral.vue'
export default {
  props: {
    comanda: Object,
    monedas: Array,
  },
  components: {
    FormasPagos,
  },
  setup(props) {
    const formaPagoRef = ref(null)
    const dialog = ref(false)
    const propinas = ref([])

    const pagoINIT = {
      id: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      tipo_pago: '',
      monto_moneda: '',
      monto: 0,
      referencia: '',
      monto_real: '',
      nuevo: true,
      igtf: 0,
    }
    const agregarPropinas = datos => {
      props.comanda.propinas = datos
    }

    const GuardarPropinas = () => {
      console.log(formaPagoRef.value.pagos)
      props.comanda.propinas = formaPagoRef.value.pagos
      dialog.value = false
      console.log(props.comanda)
    }

    watch(dialog, () => {
      if (dialog.value == true) {
        if (props.comanda.propinas != undefined) {
          console.log(props.comanda.propinas)
          propinas.value = props.comanda.propinas
        } else {
          propinas.value = []
        }
      }
    })

    return {
      dialog,
      agregarPropinas,
      formaPagoRef,
      GuardarPropinas,
      propinas,
    }
  },
}
</script>
  