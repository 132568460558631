<template>
  <div class="my-2 px-2">
    
      <v-row>
        <v-col :cols="tipoVisual == 1 ? 12 : 6"  >
     
          <v-row>
            <v-col cols="12" >
              <div  >
                <v-row>
                  <v-bottom-navigation
                    v-model="pago.moneda"
                    class="pt-2"
                    color="success"
                    background-color="transparent"
                    light
                  >
                    <v-btn
                      @click="MonedaSelect(item)"
                      v-for="(item, index) in monedas"
                      :key="index"
                      :value="item"
                      color="white"
                      class="mx-2"
                    >
                      <span>{{ item.nombre }}</span>
                      {{ item.simbolo }}
                    </v-btn>
                  </v-bottom-navigation>
                </v-row>
                <div class="text-center mt-2" v-if="pago.moneda.tipo_pago != null">
                  <v-btn
                    class="ma-2"
                    :color="item.id == pago.tipo_pago ? 'primary' : 'white'"
                    small
                    v-for="(item, index) in pago.moneda.tipo_pago.filter(item => item.ind_pago == true)"
                    :key="'btn-' + index"
                    @click="SelectFormaPago(item)"
                  >
                    {{ item.nombre }}
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col :cols="tasaIgualDolar() ? '12' : '6'" class="my-0 py-0">
              <v-text-field
                ref="montoMonedaRef"
                label="Monto moneda"
                placeholder="0.00"
                :prefix="`${pago.moneda.simbolo}.`"
                :hint="descripcionTasa()"
                @keyup="calcularMontoDolar(1)"
                persistent-hint
                outlined
                v-model="pago.monto_moneda"
                dense
                type="number"
                v-on:keyup.enter="AgregarPago()"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="my-0 py-0" v-if="!tasaIgualDolar()">
              <v-text-field
                class="my-0 py-0"
                ref="montoDolarRef"
                label="Monto $"
                outlined
                @keyup="calcularMontoDolar(2)"
                v-model="pago.monto_real"
                placeholder="0"
                prefix="$."
                dense
                type="number"
                v-on:keyup.enter="AgregarPago()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-text-field
                v-if="tipoPagoSelect().ind_referencia && !tipoPagoSelect().ind_punto_venta"
                ref="ReferenciaRef"
                label="Referencia"
                placeholder="ref"
                outlined
                v-model="pago.referencia"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6"  >
              <v-btn class="ml-2" color="primary" @click="AgregarPago()" block> agregar pago </v-btn>
            </v-col>
            <v-col cols="6" >
              <v-btn :disabled="!tipoPagoSelect().ind_propina" color="secondary" @click="AgregarPropina()" block>
                Propina
              </v-btn>
              <!--
            <modal-propina-vue :monedas="monedas" :comanda="comanda"></modal-propina-vue>
            -->
            </v-col>

            <v-col cols="12" v-if="tipoVisual == 2">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr  >
                      <td><strong>Total:</strong></td>
                      <td class="text-right">$ {{ round(
                              totalesPropina().totalMoneda +
                                comanda.total 
                            ).toFixed(2) }}</td>
                    </tr> 
                    <tr>
                      <td><strong>Pagos:</strong></td>
                      <td class="text-right">$ {{ round(totalesPropina().totalMoneda + comanda.totalPagos).toFixed(2)  }}</td>
                    </tr>
                    <tr  >
                      <td>
                        <strong >{{ comanda.resta > 0 ? 'Resta' : 'Vuelto' }}:</strong> 
                      </td>
                      <td class="text-right">
                        <span  >
                          $.{{ round(comanda.resta * -1).toFixed(2) }}</span
                        > 
                      </td>
                    </tr>
                    <tr v-if="pago.moneda.tasa != 1" >
                      <td>
                        <strong class="primary--text"
                          >Tasa {{pago.moneda.simbolo  }}:</strong
                        >
                      </td>
                      <td class="text-right">
                        <span > $ {{round(pago.moneda.tasa).toFixed(2)}} </span > 
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
       
        </v-col>
        <v-divider vertical></v-divider>
        <v-col :cols="tipoVisual == 1 ? 12 : 6">

          <v-container>
          <v-row>
            <v-list width="100%">
              <div class="text-center">
                <small>Pagos</small>
              </div>

              <v-divider></v-divider>
              <template v-for="(item, index) in comanda.pagos">
                <v-list-item :key="`PAGO${index}`">
                  <v-list-item-avatar size="30">
                    <v-avatar
                      :color="item.punto_venta ? '' : 'primary'"
                      :class="item.punto_venta ? '' : 'v-avatar-light-bg white--text'"
                      size="32"
                    >
                      <v-img
                        v-if="item.punto_venta"
                        :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                      >
                      </v-img>
                      <span v-else> {{ item.moneda.simbolo }}</span>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content class="p-0 m-0">
                    <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0"
                      >{{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                      {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                      ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                      ><small
                        >Monto {{ MonedaPais.simbolo }}: {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                      >
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                      <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                      ><small> Ref: {{ item.referencia }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0 success--text" v-if="item.vuelto_info.monto_moneda > 0">
                      <small
                        >Vuelto
                        {{
                          item.vuelto_info.moneda.tipo_pago.filter(it => it.id == item.vuelto_info.tipo_pago)[0].nombre
                        }}
                      </small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0 success--text" v-if="item.vuelto_info.monto_moneda > 0">
                      <small>
                        {{ item.vuelto_info.moneda.simbolo }} {{ item.vuelto_info.monto_moneda.toFixed(2) }}</small
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-row>
                      <v-btn
                        icon
                        v-if="item.vuelto_info.monto_moneda > 0 && item.tipo_pago == item.vuelto_info.tipo_pago"
                        @click="AgregarPropinaVuelto(item)"
                      >
                        <v-icon color="grey lighten-1">{{ mdiAccountTie }}</v-icon>
                      </v-btn>
                      <v-btn icon v-if="item.vuelto_info.monto_moneda > 0" @click="vueltoSelect(item)">
                        <v-icon color="grey lighten-1">{{ mdiCashSync }}</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="comanda.estado_pago == 7"
                        icon
                        @click="EliminarPago({ ...item, elimina_propina: true })"
                      >
                        <v-icon color="grey lighten-1">{{ mdiCloseCircle }}</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`PAGO${index}`"></v-divider>
              </template>
              <v-divider></v-divider>
              <div class="text-center" v-if="comanda.propinas && comanda.propinas.length > 0">
                <small> Propinas</small>
              </div>
              <v-divider></v-divider>
              <template v-for="(item, index) in comanda.propinas">
                <v-list-item :key="`propina${index}`" class="grey4">
                  <v-list-item-avatar size="30">
                    <v-avatar
                      :color="item.punto_venta ? '' : 'primary'"
                      :class="item.punto_venta ? '' : 'v-avatar-light-bg primary--text'"
                      size="32"
                    >
                      <v-img
                        v-if="item.punto_venta"
                        :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                      >
                      </v-img>
                      <span v-else class="white--text"> {{ item.moneda.simbolo }}</span>
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content class="p-0 m-0">
                    <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0">
                      <small v-if="item.id_pago_propina_vuelto"> PROPINA VUELTO <br /></small>
                      {{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                      {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                      ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                      ><small
                        >Monto {{ MonedaPais.simbolo }}: {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                      >
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                      <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                      ><small> Ref: {{ item.referencia }}</small>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle class="py-0 my-0">
                      <strong>
                        <small> {{ item.empleado_propina }}</small></strong
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row v-if="item.id < 0">
                      <v-btn icon @click="abrirDialogo2(item)" v-if="funciones.configuracionSelect(15) == 'true'">
                        <v-icon color="grey lighten-1">{{ mdiAccountConvert }}</v-icon>
                      </v-btn>
                      <v-btn v-if="comanda.estado_pago == 7" icon @click="EliminarPropina(item)">
                        <v-icon color="grey lighten-1">{{ mdiCloseCircle }}</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="`propina${index}`"></v-divider>
              </template>
            </v-list>
          </v-row>
        </v-container>
        </v-col>
      </v-row>
 
    <FormaPagoPuntoVenta ref="FormaPagoPuntoVentaRef" />
    <v-dialog v-model="dialog2" scrollable max-width="400px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Seleccione un empleado </v-card-title>
        <v-card-text>
          <BuscarEmpleado @Getdatos="getEmpleado" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small append @click="dialog2 = false"> Cerrar </v-btn>
          <v-btn color="primary" small append @click="empleadoPropinaAceptar()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVuelto" persistent max-width="600">
      <v-card v-if="vuelto_select != null">
        <v-card-title class="text-h5"> vuelto </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-bottom-navigation
                v-model="vuelto_select.vuelto_info.monedaSelect"
                class="pt-2"
                color="success"
                background-color="transparent"
                light
              >
                <v-btn v-for="(moneda, index) in monedas" :key="index" :value="moneda" color="white" class="mx-2">
                  <span>{{ moneda.nombre }}</span>
                  {{ moneda.simbolo }}
                </v-btn>
              </v-bottom-navigation>
            </v-row>

            <v-row>
              <div
                class="text-center"
                v-if="vuelto_select.vuelto_info.tipo_pago != null && vuelto_select.vuelto_info.monedaSelect != null"
              >
                <v-btn
                  class="ma-2"
                  small
                  v-for="(item, index) in vuelto_select.vuelto_info.monedaSelect.tipo_pago.filter(
                    it => it.ind_vuelto == true || it.id.toString() == vuelto_select.tipo_pago.toString(),
                  )"
                  :key="'btn-' + index"
                  @click="vuelto_select_cambiar(item)"
                >
                  {{ item.nombre }}
                </v-btn>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogVuelto = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import { mdiCash, mdiCloseCircle, mdiAccountTie, mdiAccountConvert } from '@mdi/js'
import FormaPagoPuntoVenta from './FormaPagoPuntoVenta.vue'
import store from '@/store'
import moment from 'moment'
import config from '@/api/config'
import BuscarEmpleado from '@/views/sistema/administracion/archivo/empleado/components/BuscarEmpleado.vue'
import funciones from '@/funciones/funciones'
import ModalPropinaVue from './ModalPropina.vue'
export default {
  props: {
    monedas: Array,
    comanda: Object,
    configuraciones: Array,
    tipoVisual: {
      type: Number,
      default: 1,
    },
  },
  components: {
    FormaPagoPuntoVenta,
    ModalPropinaVue,
    BuscarEmpleado,
  },
  setup(props, context) {
    const dialog2 = ref(false)
    const FormaPagoPuntoVentaRef = ref(false)
    const dialogVuelto = ref(false)
    const MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    const pagoPropinaTemp = ref({})
    const mdiCashSync =
      'M3 6V18H13.32C13.1 17.33 13 16.66 13 16H7C7 14.9 6.11 14 5 14V10C6.11 10 7 9.11 7 8H17C17 9.11 17.9 10 19 10V10.06C19.67 10.06 20.34 10.18 21 10.4V6H3M12 9C10.3 9.03 9 10.3 9 12S10.3 14.94 12 15C12.38 15 12.77 14.92 13.14 14.77C13.41 13.67 13.86 12.63 14.97 11.61C14.85 10.28 13.59 8.97 12 9M19 11L21.25 13.25L19 15.5V14C17.15 14 15.94 15.96 16.76 17.62L15.67 18.71C13.91 16.05 15.81 12.5 19 12.5V11M19 22L16.75 19.75L19 17.5V19C20.85 19 22.06 17.04 21.24 15.38L22.33 14.29C24.09 16.95 22.19 20.5 19 20.5V22'
    const comanda_pagoINIT = {
      id: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      tipo_pago: '',
      monto_moneda: '',
      monto: 0,
      referencia: '',
      monto_real: '',
      nuevo: true,
      igtf: 0,
      vuelto_info: {
        moneda: { simbolo: '' },
        monto_moneda: 0,
        monto: 0,
        tipo_pago: '',
        mostrar: false,
      },
      id_empleado_propina: 0,
      empleado_propina: '',
    }

    const comanda_pagoINITF = ()=>{
      pago.value = { ...comanda_pagoINIT, id_empleado_propina: props.comanda.id_mesonero }
    }

    const getEmpleado = dato => {
      empleadoPropinaSelect.value = dato
    }
    const empleadoPropinaSelect = ref({})
    const empleadoPropinaAceptar = () => {
      pagoPropinaTemp.value.id_empleado_propina = empleadoPropinaSelect.value.id
      pagoPropinaTemp.value.empleado_propina =
        empleadoPropinaSelect.value.nombre + ' ' + empleadoPropinaSelect.value.apellido

      dialog2.value = false
    }
    const vuelto_select = ref(null)
    const vueltoSelect = item => {
      dialogVuelto.value = true
      vuelto_select.value = item
    }
    const vuelto_select_cambiar = _tipo => {
      vuelto_select.value.vuelto_info.tipo_pago = _tipo.id
      vuelto_select.value.vuelto_info.moneda = vuelto_select.value.vuelto_info.monedaSelect
      vuelto_select.value.vuelto_info.monto_moneda =
        vuelto_select.value.vuelto_info.monto_real * vuelto_select.value.vuelto_info.monedaSelect.tasa
      dialogVuelto.value = false
    }
    const monedaSelect = ref(null)
    const formasPagos = ref([])
    const formaPagoSelect = ref()
    const montoDolar = ref()
    const montoMoneda = ref()
    const montoDolarRef = ref()
    const montoMonedaRef = ref()
    const pago = ref({ ...comanda_pagoINIT })
    const pagos = ref([])
    const configuracion = ref({
      igtf: 0,
    })
    watch(props, () => {
      comanda_pagoINITF()
      configuracion.value.igtf = Number(props.configuraciones.find(item => item.id == 2).valor)
    })

    const configuracionesFun = () => {
      return {
        igtf: !store.state.user.caja.ind_igtf ? 0 : Number(props.configuraciones.find(item => item.id == 2).valor),
      }
    }
    const totalesPropina = () => {
      let sumas = {
        totalMonedaPais: 0,
        totalMoneda: 0,
      }

      props.comanda?.propinas.forEach(element => {
        sumas.totalMonedaPais += Number(element.monto_real) * MonedaPais.tasa
        sumas.totalMoneda += Number(element.monto_real)
      })

      return sumas
    }
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    onBeforeMount(() => {
      monedaSelect.value = props.monedas[0]
    })

    const tasaIgualDolar = () => {
      if (!pago.value.moneda.tasa) {
        return true
      } else {
        if (pago.value.moneda.tasa == 1) {
          return true
        } else {
          return false
        }
      }
    }

    const calcularMontoDolar = valor => {
      console.log(valor)
      if (valor == 1) {
        pago.value.monto_real = (pago.value.monto_moneda / pago.value.moneda.tasa).toFixed(2)
      } else {
        pago.value.monto_moneda = (pago.value.monto_real * pago.value.moneda.tasa).toFixed(2)
      }
    }

    const MonedaSelect = item => {
      let distintos = false

      pago.value.moneda = item
      pago.value.monto_moneda = ''
      pago.value.monto_real = ''
      pago.value.referencia = ''
      pago.value.tipo_pago = item.tipo_pago[0].id
      console.log(item.tipo_pago[0].id)
      montoMonedaRef.value.focus()
      if (Number(props.comanda.resta) > 0) {
        if (tipoPagoSelect().ind_igtf) {
          pago.value.monto_real = (props.comanda.resta * (1 + configuracionesFun().igtf / 100)).toFixed(2)
        } else {
          pago.value.monto_real = props.comanda.resta.toFixed(2)
        }

        calcularMontoDolar(2)
      }
    }

    const abrirDialogo2 = dato => {
      dialog2.value = true
      pagoPropinaTemp.value = dato
    }

    const tipoPagoSelect = () => {
      let valor = {
        ind_punto_venta: false,
        ind_referencia: false,
      }
      if (pago.value.moneda && pago.value.tipo_pago) {
        valor = pago.value.moneda.tipo_pago.find(it => it.id == pago.value.tipo_pago)
      }
      console.log(valor)
      return valor
    }

    const AgregarPropinaVuelto = item => {
      const _item = JSON.parse(JSON.stringify(item))

      const _pago = {
        id_pago_propina_vuelto: item.id,
        id: item.id - 10000,
        moneda: item.moneda,
        tipo_pago: item.tipo_pago,
        monto_moneda: item.vuelto_info.monto_moneda,
        monto: item.vuelto_info.monto_moneda,
        referencia: item.referencia,
        monto_real: item.vuelto_info.monto_real,
        nuevo: true,
        fecha: moment(),
        id_punto_venta: item.punto_venta?.id,
        punto_venta: item.punto_venta,
      }
      console.log('AgregarPropinaVuelto', _pago)
      context.emit('AgregarPropinaVuelto', _pago)

      _item.monto_moneda = item.monto_moneda - item.vuelto_info.monto_moneda
      _item.monto_real = item.monto_real - item.vuelto_info.monto_real
      _item.monto = item.monto_moneda - item.vuelto_info.monto_moneda

      _item.vuelto_info = {
        moneda: { simbolo: '' },
        monto_moneda: 0,
        monto: 0,
        tipo_pago: '',
        mostrar: false,
      }
      context.emit('GetEliminarPagos', item)
      context.emit('GetPagos', { ..._item })
    }

    const EliminarPropina = item => {
      console.log('EliminarPropina', item)
      context.emit('EliminarPropina', item)
    }
    const AgregarPropina = () => {
      if (tipoPagoSelect().ind_punto_venta) {
        FormaPagoPuntoVentaRef.value.abrir(pago.value, _dato => {
          context.emit('AgregarPropina', { ..._dato })
          //pago.value = { ...comanda_pagoINIT }
          comanda_pagoINITF()
        })
      } else {
        console.log(pago.value)
        context.emit('AgregarPropina', pago.value)
        //pago.value = { ...comanda_pagoINIT }
        comanda_pagoINITF()
      }
    }

    const AgregarPago = () => {
      if (props.comanda.resta > 0) {
        if (pago.value.tipo_pago > 0) {
          if (pago.value.monto_moneda >= 0.01) {
            if (pago.value.monto_moneda < props.comanda.resta * pago.value.moneda.tasa * 100) {
              if (tipoPagoSelect().ind_punto_venta) {
                FormaPagoPuntoVentaRef.value.abrir(pago.value, _dato => {
                  context.emit('GetPagos', _dato)
                 // pago.value = { ...comanda_pagoINIT }
                 comanda_pagoINITF()
                })
              } else {
                console.log(pago.value)
                context.emit('GetPagos', pago.value)
                //pago.value = { ...comanda_pagoINIT }
                comanda_pagoINITF()
              }
            } else {
              store.commit('setAlert', {
                message: 'valor no permitido pago es 100 veces mayor al restante',
                type: 'warning',
              })
            }
          } else {
            store.commit('setAlert', {
              message: 'El pago debe ser mayor a cero',
              type: 'warning',
            })
          }
        } else {
          store.commit('setAlert', {
            message: 'Debe seleccionar una forma de pago',
            type: 'warning',
          })
        }
      } else {
        store.commit('setAlert', {
          message: 'Ya completo el total del pago',
          type: 'warning',
        })
      }
    }

    const EliminarPago = item => {
      console.log('GetEliminarPagos')
      context.emit('GetEliminarPagos', item)
    }

    const descripcionTasa = () => {
      if (!pago.value.moneda) {
        return ''
      } else {
        if (pago.value.moneda.tasa == 1) {
          if (!pago.value.moneda.meneda_pais) {
            return `Resta+Igtf: ${(props.comanda.resta * (1 + configuracionesFun().igtf / 100) > 0
              ? props.comanda.resta * (tipoPagoSelect().ind_igtf ? 1 + configuracionesFun().igtf / 100 : 1)
              : 0
            ).toFixed(2)}`
          } else {
            return ``
          }
        } else {
          if (!pago.value.moneda.meneda_pais) {
            return `Tasa $: ${pago.value.moneda.tasa} ${pago.value.moneda.simbolo} Resta+Igtf: ${(props.comanda.resta *
              1.03 >
            0
              ? props.comanda.resta * 1.03
              : 0
            ).toFixed(2)}`
          } else {
            return `Tasa $: ${pago.value.moneda.tasa} ${pago.value.moneda.simbolo}`
          }
        }
      }
    }

    watch(monedaSelect, () => {
      console.log(monedaSelect.value)
      formaPagoSelect.value = null
      formasPagos.value = monedaSelect.value.tipo_pago
    })

    const SelectFormaPago = item => {
      pago.value.tipo_pago = item.id
      montoMonedaRef.value.focus()
      //pago.value.monto_real = props.comanda.resta.toFixed(2)
      if (Number(props.comanda.resta) > 0) {
        if (tipoPagoSelect().ind_igtf) {
          pago.value.monto_real = (props.comanda.resta * (1 + configuracionesFun().igtf / 100)).toFixed(2)
        } else {
          pago.value.monto_real = props.comanda.resta.toFixed(2)
        }

        calcularMontoDolar(2)
      }
    }

    return {
      mdiAccountTie,
      monedaSelect,
      mdiCash,
      mdiCloseCircle,
      formasPagos,
      SelectFormaPago,
      formaPagoSelect,
      montoDolar,
      montoMoneda,
      descripcionTasa,
      tasaIgualDolar,
      montoDolarRef,
      montoMonedaRef,
      pagos,
      pago,
      calcularMontoDolar,
      MonedaSelect,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 0,
      },
      AgregarPago,
      EliminarPago,
      MonedaPais,
      mdiCashSync,
      dialogVuelto,
      vueltoSelect,
      vuelto_select,
      vuelto_select_cambiar,
      configuracion,
      FormaPagoPuntoVentaRef,
      tipoPagoSelect,
      AgregarPropinaVuelto,
      EliminarPropina,
      configuracionesFun,
      AgregarPropina,
      config,
      dialog2,
      pagoPropinaTemp,
      abrirDialogo2,
      getEmpleado,
      empleadoPropinaAceptar,
      mdiAccountConvert,
      funciones,
      totalesPropina,
      round
    }
  },
}
</script>