<template>
  <v-hover :value="true">
    <v-card @click="clickFalse()" class="pb-6">
      <v-card-title :class="ColorEstadoPago() + ' ' + 'py-1'" @click="VerComanda(comanda)"> </v-card-title>
      <v-card-text class="py-0 pt-2 justify-space-between" @click="VerComanda(comanda)">
        <v-row>
          <v-col>
            <div>
              <small>
                <strong> {{ comanda.cliente }} </strong>
                <v-chip color="secondary" x-small> {{ comanda.numero_comensales }}</v-chip>
              </small>
              <br />
              <small> {{ nombreCliente(comanda) }} <span v-if="comanda.ind_bloqueado">(Bloqueada)</span></small>
            </div>
          </v-col>
          <v-col>
            <div class="text-right">
              <span>
                <v-icon v-if="comanda.por_moneda_pais > 80 && comanda.por_moneda_pais <= 90" :color="'warning'">{{
                  icon.mdiStarFourPointsSmall
                }}</v-icon>
                <v-icon v-if="comanda.por_moneda_pais > 90" :color="'success'">{{
                  icon.mdiStarFourPointsSmall
                }}</v-icon>
                #{{ comanda.id }}
              </span>
              <br />
              <small> {{ comanda.estado_forma_pago_nombre }} </small>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title class="pb-3" @click="VerComanda(comanda)">
        <div>
          <h5 class="ms-2">{{ comanda.mesonero_nombre }}</h5>
        </div>
      </v-card-title>
      <v-card-text class="" @click="VerComanda(comanda)">
        <v-container class="py-0 px-0">
          <v-row>
            <v-col cols="8">
              <div class="py-0">
                <v-icon size="1.5rem" class="me-2" :color="comanda.comanda_candidata ? 'success' : ''">
                  {{ icon.mdiMapMarker }}
                </v-icon>
                <span class="">{{ comanda.mesa_nombre }}</span>
              </div>
              <div class="py-0">
                <v-icon size="1.5rem" class="me-2">
                  {{ icon.mdiAccount }}
                </v-icon>
                <span class="">{{ comanda.comandero_nombre }}</span>
              </div>
            </v-col>
            <v-col cols="4" class="text-right">
              <div v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES)">
                $ {{ comanda.total.toFixed(2) }}
                <br />

                <span
                  v-if="
                    validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES) &&
                    configuraciones.find(item => item.id == 2).valor > 0
                  "
                  class="primary--text"
                  ><small>+igtf </small>{{ (comanda.total * 1.03).toFixed(2) }}</span
                >
                <br
                  v-if="
                    validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES) &&
                    configuraciones.find(item => item.id == 2).valor > 0
                  "
                />
                Bs {{ comanda.total_bs.toFixed(2) }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="justify-end" v-if="mostarAcciones">
        <div>
          <v-tooltip bottom v-if="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="modalTransferir = true" icon :color="ColorEstadoPago()" v-bind="attrs" v-on="on">
                <v-icon>{{ icon.mdiFileEye }}</v-icon>
              </v-btn>
            </template>
            <span>Administrar</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="validarAcciones(acciones.DASHBOARD_COMANDA_TRANSFERIR_COMANDA) && comanda.estado_pago == 7"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="bloquearDesbloquearComanda()"
                icon
                :color="comanda.ind_bloqueado ? 'error' : ColorEstadoPago()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>{{ icon.mdiBlockHelper }}</v-icon>
              </v-btn>
            </template>
            <span>{{ !comanda.ind_bloqueado ? 'Bloquear' : 'Desbloquear' }}</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="validarAcciones(acciones.DASHBOARD_COMANDA_TRANSFERIR_COMANDA) && comanda.estado_pago == 7"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="comanda.ind_bloqueado"
                @click="modalTransferir = true"
                icon
                :color="ColorEstadoPago()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icon.mdiSwapHorizontal }}</v-icon>
              </v-btn>
            </template>
            <span>Transferir</span>
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="validarAcciones(acciones.DASHBOARD_COMANDA_SEPARAR_COMANDA) && comanda.estado_pago == 7"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="comanda.ind_bloqueado"
                @click="modalSeparar = true"
                icon
                :color="ColorEstadoPago()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icon.mdiCallSplit }}</v-icon>
              </v-btn>
            </template>
            <span>Separar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="VerComanda(comanda)" icon :color="ColorEstadoPago()" v-bind="attrs" v-on="on">
                <v-icon>{{ icon.mdiEye }}</v-icon>
              </v-btn>
            </template>
            <span>Ver</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="
                  validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR_COMANDA) &&
                  (comanda.estado_pago == 7 || validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA))
                "
                icon
                :color="ColorEstadoPago()"
                @click="ImprimirGrupoPreguntar(comanda)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icon.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>

          <v-chip
            v-if="comanda.estado_fiscalizado >= 40"
            class="ma-2"
            small
            :color="ColorEstadoPago()"
            text-color="white"
          >
            Fac: {{ comanda.factura.numero_documento }}
            <span v-if="comanda.estado_fiscalizado == 44"> /Dev:{{ comanda.devolucion.numero_documento }}</span>
          </v-chip>

          <v-btn
            :disabled="
              !validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA) ||
              fiscalizando ||
              comanda.cola_fiscal.estado == 27
            "
            :loading="comanda.id == comandaFiscalizada.id || comanda.cola_fiscal.estado == 27"
            x-small
            :color="ColorEstadoPago()"
            v-if="
              comanda.estado_fiscalizado == 39 &&
              comanda.estado_pago != 14 &&
              validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA) &&
              comanda.ind_exonerar_iva == false
            "
            @click="Fiscalizar(comanda)"
          >
            FISCALIZAR

            <v-icon class="pl-1">{{ icon.mdiPrinterPosOutline }}</v-icon>
          </v-btn>

          <v-tooltip
            bottom
            v-if="comanda.cola_fiscal.estado == 96 && validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="error" v-bind="attrs" v-on="on">
                <v-icon>{{ icon.mdiPrinterPosRemoveOutline }}</v-icon>
              </v-btn>
            </template>
            <span> {{ comanda.cola_fiscal.error }} </span>
          </v-tooltip>

          <v-btn
            :disabled="!validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA) || fiscalizando"
            :loading="comanda.id == comandaFiscalizada.id || comanda.cola_fiscal.estado == 27"
            x-small
            :color="ColorEstadoPago()"
            v-if="comanda.estado_fiscalizado == 43 && validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)"
            @click="Fiscalizar(comanda)"
          >
            DEVOLUCION
            <v-icon class="pl-1">{{ icon.mdiPrinter }}</v-icon>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="show = !show" v-bind="attrs" v-on="on">
                <v-icon>{{ show ? icon.mdiChevronUp : icon.mdiChevronDown }}</v-icon>
              </v-btn>
            </template>
            <span>{{ !show ? 'Expandir' : 'Contraer' }}</span>
          </v-tooltip>
        </div>
      </v-card-actions>
      <v-card-text
        class="mx-0 px-0"
        v-if="
          mostarAcciones &&
          funciones.configuracionSelect(17) == 'true' &&
          validarAcciones(acciones.DASHBOARD_COMANDA_CERRAR_CUENTA_FORMA_PAGO) &&
          comanda.estado_pago == 7
        "
      >
        <v-btn block class="secondary" @click="cerrarCuenta(comanda)">
          <v-icon size="1.5rem" class="me-2">
            {{ icon.mdiCheckAll }}
          </v-icon>
          Cerrar Cuenta</v-btn
        >
      </v-card-text>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <v-list subheader flat v-if="!cargandoAgrupado">
              <template v-for="(item, index) in comanda_agrupada.productos">
                <v-divider :key="'divider' + index" inset></v-divider>
                <v-skeleton-loader
                  :key="'skeleton' + index"
                  i
                  v-bind="attrs"
                  type="list-item-avatar, divider"
                  v-if="cargandoAgrupado"
                ></v-skeleton-loader>
                <v-list-item :key="'item' + index" v-else>
                  <v-list-item-icon>
                    <v-badge bordered top color="primary" offset-x="10" offset-y="10" :content="item.cantidad">
                      <v-avatar size="30">
                        <v-img
                          :src="`${config.api_imagenes}/producto/img/s-${item.producto.img}`"
                          :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                        ></v-img>
                      </v-avatar>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><small>{{ item.producto.nombre }}</small></v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      <strong v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)"
                        >{{ round(item.total + item.iva).toFixed(2) }}$</strong
                      >
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div class="text-center" v-if="cargandoAgrupado && !comanda_agrupada.productos">
              <v-skeleton-loader
                :key="'skeleton' + index"
                i
                v-bind="attrs"
                type="list-item-avatar, divider"
                v-if="cargandoAgrupado"
              ></v-skeleton-loader>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES)">
            <v-simple-table dark dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>SubTotal:</td>
                    <td class="text-right">{{ comanda.subtotal.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Descuento</td>
                    <td class="text-right">-{{ comanda.descuento.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Servicio {{ comanda.por_comision }}%:</td>
                    <td class="text-right">+{{ comanda.comision.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Iva:</td>
                    <td class="text-right">+{{ comanda.iva.toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td>Total:</td>
                    <td class="text-right">{{ comanda.total.toFixed(2) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </div>
      </v-expand-transition>
      <ImpresoraSelect ref="ImpresoraSelectRef" @getImpresoraFuncion="getImpresora" />
      <modal-separar-comanda-vue :comanda="comanda" :modalSeparar="modalSeparar" @GetModalSeparar="GetModalSeparar">
      </modal-separar-comanda-vue>

      <modal-transferir-comanda-vue
        :comanda="comanda"
        :modalTransferir="modalTransferir"
        @GetModalTransferir="GetModalTransferir"
      >
      </modal-transferir-comanda-vue>
      <ModalConfirmacion ref="ModalConfirmacionRef" />
    </v-card>
  </v-hover>
</template>
<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import ComandaService from '@/api/servicios/ComandaService'
import ImpresoraService from '@/api/servicios/ImpresoraService'
import MaestroService from '@/api/servicios/MaestroService'
import ModalSepararComandaVue from './ModalSepararComanda.vue'
import ModalTransferirComandaVue from './ModalTransferirComanda.vue'
import ImpresoraSelect from '@/views/sistema/comanda/dashboard/componentes/ImpresoraSelect.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
import Vue from 'vue'
import config from '@/api/config'
import funciones from '@/funciones/funciones'
import fiscalizar  from '@/funciones/fiscalizar'
import {
  mdiArrowLeft,
  mdiMagnify,
  mdiAccount,
  mdiHeart,
  mdiShareVariant,
  mdiMapMarker,
  mdiEye,
  mdiPrinter,
  mdiChevronDown,
  mdiChevronUp,
  mdiCallSplit,
  mdiSwapHorizontal,
  mdiBlockHelper,
  mdiFileEye,
  mdiCheckAll,
  mdiPrinterPosOutline,
  mdiPrinterPosRemoveOutline,
} from '@mdi/js'
import store from '@/store'
import { acciones } from '@/modulos'
import { consoleError } from 'vuetify/lib/util/console'
export default {
  components: {
    ModalSepararComandaVue,
    ModalTransferirComandaVue,
    ImpresoraSelect,
    ModalConfirmacion,
  },
  props: {
    comanda: Object,
    mesas: Array,
    formasPagos: Array,
    mesoneros: Array,
    monedas: Array,
    configuraciones: Array,
    fiscalizando: Boolean,
    showAll: Boolean,
    serialFiscal: String,
    mostarAcciones: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    comanda: function (news, olds) {
      if (this.show) {
        this.cargarComandaAgrupado(news.id)
      }
    },
  },
  setup(props, context) {
    const mdiStarFourPointsSmall = 'M10.74 10.75L12 8L13.25 10.75L16 12L13.25 13.26L12 16L10.74 13.26L8 12L10.74 10.75Z'
    const modalSeparar = ref(false)
    const modalTransferir = ref(false)
    const show = ref(false)
    const logoI = process.env.VUE_APP_IMG_LOGO
    const mensaje = ref({
      snackbar: false,
      text: 'alerta',
      color: 'success',
      timeout: 2000,
    })
    const ModalConfirmacionRef = ref(null)

    const getImpresora = (_impresora, _funcion, _datos) => {
      _funcion({ ..._datos, id_grupo_impresora_pc: _impresora })
    }
    const cerrarCuenta = async datos => {
      ModalConfirmacionRef.value.abrir({
        titulo: '<h4>Cierre Comanda #' + datos.id + ' </h4>',
        subTitulo: '<h3>¿Seguro que quiere cerrar la comanda? </h3>',
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'error',
          funcionDatos: datos,
          funcion: async _dat => {
            await ComandaService.comandaCerrar(_dat)
              .then(response => {
                if (response.data.mensaje == 'COMANDA_CERRADA_CON_EXITO') {
                  store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'success',
                  })
                } else {
                  store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'info',
                  })
                }
              })
              .catch(error => {
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'cerrarCuenta',
                })
              })
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })
    }
    const configuracion = ref({
      igtf: 0,
    })
    watch(props, () => {
      configuracion.value.igtf = Number(props.configuraciones.find(item => item.id == 2).valor)
    })

    const bloquearDesbloquearComanda = () => {
      let item = props.comanda
      item.cargandobloquearDesbloquearComanda = true
      ComandaService.BloquearDesbloquearComanda({ id_comanda: item.id })
        .then(response => {
          if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
            item.ind_bloqueado = !item.ind_bloqueado
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'bloquearDesbloquearComanda',
          })
        })
        .finally(() => {
          item.cargandobloquearDesbloquearComanda = false
        })
    }
    const GetModalSeparar = datos => {
      modalSeparar.value = datos
    }

    const GetModalTransferir = datos => {
      modalTransferir.value = datos
    }
    const nombreCliente = item => {
      let nombre = ''

      if (item.documento_cliente != undefined) {
        if ([3, 4, 5].includes(item.id_tipo_cliente)) {
          nombre = item.nombre_cliente + ' ' + item.apellido_cliente
        } else {
          nombre = item.nombre_cliente
        }
      } else {
        nombre = 'Sin Cliente'
      }

      return nombre
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    let showAll_ = false
    let MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]

    onBeforeMount(() => {
      showAll_ = props.showAll
      show.value = showAll_
    })

    watch(props, () => {
      if (showAll_ != props.showAll) {
        showAll_ = props.showAll
        show.value = showAll_
      }
    })

    watch(props, () => {
      MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    })
    const VerComanda = item => {
      context.emit('GetVerComanda', item)
    }

    const GetFiscalizando = item => {
      context.emit('GetFiscalizando', item)
      if (item == false) {
        comandaFiscalizada.value = {}
      }
    }

    const comandaFiscalizada = ref({})

    const Totalizar = item => {
      comandaFiscalizada.value = item
      let descuento = 0.0
      let servicio = 0.0
      let iva = 0.0
      let igtf = 0.0
      let subtotal = 0.0
      let total = 0.0
      let resta = 0.0
      let totalPagos = 0.0

      let descuentoMonedaPais = 0.0
      let servicioMonedaPais = 0.0
      let ivaMonedaPais = 0.0
      let igtfMonedaPais = 0.0
      let subtotalMonedaPais = 0.0
      let totalMonedaPais = 0.0
      let restaMonedaPais = 0.0
      let totalPagosMonedaPais = 0.0
      let totalPagosMoneda = 0.0
      let RecargoivaMonedaPais = 0.0
      let RecargoIva = 0.0
      let RecargoMonedaPais = 0.0
      let Recargo = 0.0

      console.log('MonedaPais')
      console.log(MonedaPais)
      console.log('MonedaPais')
      comandaFiscalizada.value.productos.forEach(element => {
        const PrecioRealMonedaPais_ = round(element.precio_real * MonedaPais.tasa)
        const PrecioTotalMonedaPais_ = round(PrecioRealMonedaPais_ * element.cantidad)
        const descuentoMonedaPais_ = round(PrecioTotalMonedaPais_ * (element.por_descuento / 100))
        let recargoMonedaPais_ = 0

        if (element.producto.ind_servicio) {
          recargoMonedaPais_ =
            (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (comandaFiscalizada.value.por_comision / 100)
        }

        const ivaMonedaPais_ =
          (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (element.producto.impuesto_tasa_valor / 100)

        subtotalMonedaPais += PrecioTotalMonedaPais_
        descuentoMonedaPais += descuentoMonedaPais_
        ivaMonedaPais += ivaMonedaPais_
        RecargoMonedaPais += recargoMonedaPais_

        const PrecioReal_ = round(element.precio_real)
        const PrecioTotal_ = round(PrecioReal_ * element.cantidad)
        const descuento_ = round(PrecioTotal_ * (element.por_descuento / 100))

        let recargo_ = 0

        if (element.producto.ind_servicio) {
          recargo_ = round((PrecioTotal_ - descuento_) * (comandaFiscalizada.value.por_comision / 100))
        }
        const iva_ = round((PrecioTotal_ - descuento_) * (element.producto.impuesto_tasa_valor / 100))
        descuento += descuento_
        iva += iva_
        subtotal += PrecioTotal_
        Recargo += recargo_
      })

      comandaFiscalizada.value.pagos.forEach(element => {
        if (element.monto > 0) {
          const totalPagosMonedaPais_ = Number((Number(element.monto_real) * MonedaPais.tasa).toFixed(2))
          const totalPagos_ = Number(Number(element.monto_real).toFixed(2))
          const totalPagosMoneda_ = Number(Number(element.monto_moneda).toFixed(2))

          const igtfMonedaPais_ = Number((Number(Number(element.igtf)) * MonedaPais.tasa).toFixed(2))
          const igtf_ = Number(Number(element.igtf).toFixed(2))

          totalPagosMonedaPais += totalPagosMonedaPais_
          totalPagos += totalPagos_
          totalPagosMoneda += totalPagosMoneda_
          igtfMonedaPais += igtfMonedaPais_
          igtf += igtf_
        }
      })

      servicioMonedaPais = round(RecargoMonedaPais)
      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais, // + servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)

      servicio = round(Recargo)
      total = round(subtotal - descuento + iva + igtf + servicio)
      resta = total - totalPagos
      console.log('servicio aquii')
      console.log(round((subtotal - descuento) * (comandaFiscalizada.value.por_comision / 100)))

      let diferencia = round(total * MonedaPais.tasa - totalMonedaPais)

      //  diferencia)
      /*
      servicioMonedaPais = round(
        (subtotalMonedaPais - descuentoMonedaPais) * (comandaFiscalizada.value.por_comision / 100),
      )*/

      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais, //+ servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)

      console.log('fiscalizando')
      comandaFiscalizada.value = {
        ...comandaFiscalizada.value,
        descuento,
        servicio,
        iva,
        igtf,
        total,
        totalPagos,
        resta,
        subtotal,
        descuentoMonedaPais,
        servicioMonedaPais,
        ivaMonedaPais,
        igtfMonedaPais,
        subtotalMonedaPais,
        totalMonedaPais,
        restaMonedaPais,
        totalPagosMonedaPais,
        diferencia,
        RecargoMonedaPais,
        Recargo,
      }
      let devolucion = {}
      if (comandaFiscalizada.value.factura != null) {
        devolucion = {
          nFacturaAfectada: comandaFiscalizada.value.id.toString(),
          numControlContraDev: comandaFiscalizada.value.factura.numero_control,
          fechaFactContDev: new Date(),
          serialFactContDev: '',
        }
      }

      let ctdProductos = 0

      comandaFiscalizada.value.productos.forEach(item => {
        ctdProductos += item.cantidad
      })
      let factura = {
        nFactura: comandaFiscalizada.value.id.toString(),
        cajero: comandaFiscalizada.value.comandero_nombre,
        ctdProductos: Number(ctdProductos.toFixed(2)),
        sub_total: comandaFiscalizada.value.subtotalMonedaPais, //+ comandaFiscalizada.value.servicioMonedaPais + diferencia,
        recargo: '0', //comandaFiscalizada.value.servicioMonedaPais,
        descuento: comandaFiscalizada.value.descuentoMonedaPais,
        igtf: Number(comandaFiscalizada.value.igtfMonedaPais),
        total: Number(comandaFiscalizada.value.totalMonedaPais), //+ diferencia,
        total_pago: comandaFiscalizada.value.totalPagosMonedaPais,
        vuelto: comandaFiscalizada.value.totalPagosMonedaPais - comandaFiscalizada.value.totalMonedaPais,
        iva: comandaFiscalizada.value.ivaMonedaPais,
        montoRecDesc: '0', //Number(comandaFiscalizada.value.servicioMonedaPais).toFixed(2).toString(),
        isPorcentajeRecDesc: false,
        isRecDesc: true,
        cliente: {
          id_cliente: comandaFiscalizada.value.id_cliente,
          direccion: comandaFiscalizada.value.direccion_cliente,
          nombre:
            comandaFiscalizada.value.id_cliente == null
              ? comandaFiscalizada.value.cliente
              : [3, 4, 5].includes(comandaFiscalizada.value.id_tipo_cliente)
              ? comandaFiscalizada.value.nombre_cliente + ' ' + comandaFiscalizada.value.apellido_cliente
              : comandaFiscalizada.value.nombre_cliente,
          cedula: comandaFiscalizada.value.id_cliente == null ? '12345679' : comandaFiscalizada.value.documento,
        },
        impresora: { ...store.state.impresoraFiscal },
        /*impresora: {
          modeloImpresora: 1,
          puerto: 99,
          bAudios: 9600,
          paridad: 2,
          datos: 8,
          stopBits: 1,
          handshake: 0,
          maxTiempoEspera: 5000,
          tiempoAntesLectura: 100,
        },*/
      }

      let producto = []

      comandaFiscalizada.value.productos.forEach(item => {
        producto.push({
          id_producto: item.producto.id,
          barra: item.producto.id.toString(),
          descripcion: item.producto.nombre,
          pvp: Number((item.precio_real * MonedaPais.tasa).toFixed(2)),
          cantidad: Number(item.cantidad.toFixed(2)),
          sub_total: Number((item.sub_total * MonedaPais.tasa).toFixed(2)),
          descuento: (
            round(item.precio_real * item.cantidad * MonedaPais.tasa, 2) *
            (item.por_descuento / 100)
          ).toFixed(2),
          iva_total: (item.iva * MonedaPais.tasa).toFixed(2),
          total: (item.total * MonedaPais.tasa).toFixed(2),
          IVA: Number(item.producto.impuesto_tasa_valor.toFixed(2)),
          //descuento: (item.descuento * MonedaPais.tasa).toFixed(2),
          serial: '',
        })
      })

      if (comandaFiscalizada.value.por_comision / 100 > 0) {
        factura = {
          ...factura,
          _servicio: {
            id_producto: 99999,
            barra: '99999',
            descripcion: 'SERVICIO',
            pvp: servicioMonedaPais.toFixed(2),
            cantidad: '1',
            sub_total: servicioMonedaPais.toFixed(2),
            descuento: '0',
            iva_total: '0',
            total: servicioMonedaPais,
            IVA: '0',
            descuento: '0',
            serial: '',
          },
        }
      }
      console.log('comandaFiscalizada aquiiii', comandaFiscalizada.value.pagos)
      let pagosFacturas = []
      let sum = 0
      comandaFiscalizada.value.pagos.forEach((item, i) => {
        if (item.monto > 0) {
          if (sum < Number(comandaFiscalizada.value.totalMonedaPais)) {
            sum +=
              item.moneda.id != MonedaPais.id
                ? Number(MonedaPais.tasa) * Number(item.monto_real)
                : Number(item.monto_moneda)
            0
            pagosFacturas.push({
              id_moneda: item.moneda.id,
              descripcion: item.moneda.nombre,
              igtf: Number((item.igtf * MonedaPais.tasa).toFixed(2)),
              monto: Number(
                item.moneda.id != MonedaPais.id
                  ? (MonedaPais.tasa * item.monto_real).toFixed(2)
                  : item.monto_moneda.toFixed(2),
              ),
              tasa: Number(item.moneda.tipo_pago[0].ind_igtf == true ? MonedaPais.tasa.toFixed(2) : '1.00'),
              formaPago: item.moneda.tipo_pago[0].id_fiscal,
            })
          }
        }
      })

      if (diferencia < 0) {
        //caso de saldar la diferencia pago respecto al dolar
        const pag = {
          id_moneda: 2,
          descripcion: 'BOLIVAR',
          igtf: '0.00',
          monto: (diferencia * -1).toFixed(2),
          tasa: '1.00',
        }
        pagosFacturas.push(pag)
      }

      let facturas = { ...factura, producto, pagosFacturas, devolucion_value: false }

      if (comandaFiscalizada.value.factura?.numero_control != undefined) {
        console.log('factura')
        console.log(comandaFiscalizada.value.factura)

        facturas = { ...facturas, devolucion: { ...devolucion }, devolucion_value: true }
      }
      console.log('factura')
      console.log(JSON.stringify(facturas))

      console.log(item)
      // facturaNuevo({ ...facturas, numero_control: getRandomInt(1, 500) })
      if (!(store.state.impresoraFiscalSelect.id > 0 && store.state.impresoraFiscalTipo == "REMOTO" )) {
        ImpresoraService.imprimirFactura({
          factura: facturas,
          pIsDevolucion: false,
          pAbrirGaveta: false,
          indIgtf: configuracion.value.igtf > 0 ? true : false,
        })
          .then(response => {
            try {
              if ((response.data.estatus = true)) {
                if (Number(response.data.datos.lastDocument) > 0) {
                  facturaNuevo({
                    ...facturas,
                    numero_control: response.data.datos.lastDocument,
                    serial_impresora: props.serialFiscal,
                  })

                  if (facturas._servicio) {
                    if (Number(facturas._servicio.pvp) > 0) {
                      imprimirDocumentoNoFiscal(
                        'Total Venta:           Bs ' +
                          Number(facturas.total - facturas.descuento).toFixed(2) +
                          ';Total Servicio (10%)  Bs ' +
                          Number(facturas._servicio.pvp).toFixed(2) +
                          ';Total:                Bs ' +
                          (Number(facturas.total - facturas.descuento) + Number(facturas._servicio.pvp)).toFixed(2),
                        store.state.impresoraFiscal,
                      )
                    }
                  }
                } else {
                  errorFiscal({
                    ...facturas,
                    serial_impresora: props.serialFiscal,
                  })
                }
              } else {
                errorFiscal({
                  ...facturas,
                  serial_impresora: props.serialFiscal,
                })
              }
            } catch (error) {
              errorFiscal({
                ...facturas,
                serial_impresora: props.serialFiscal,
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: `Disculpe hubo un error: ${error}`,
              type: 'error',

              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'Totalizar',
            })

            console.log(error)
            GetFiscalizando(false)
          })
      } else {
        //mandar a impresora remota
        ComandaService.ImprimirFiscal({
          factura: facturas,
          id_comanda: facturas.nFactura,
          devolucion: false,
          abrir_gaveta: false,
          ind_igtf: configuracion.value.igtf > 0 ? true : false,
          id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
          id_caja: store.state.user.caja.id,
        })
          .then(response => {
            if (response.data.mensaje == 'guardado con exito') {
              props.comanda.cola_fiscal.estado = 27

              store.commit('setAlert', {
                message: 'Se envio a la impresora fiscal ',
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                type: 'error',
                error: {
                  response: response.data,
                },
                funcion: 'ImprimirFiscal',
              })
            }

            GetFiscalizando(false)
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'Error al enviar a fiscalizar ' + error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'cerrarCuenta',
            })
            GetFiscalizando(false)
          })
      }
    }

    const errorFiscal = facturas => {
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>¿Hubo un error, la factura se emitio? </h3>' +
          ` ` +
          ` 
                  <input  id="swal-motivo-anulacion"   class="swal2-input" placeholder="Numero Factura">  `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: async () => {
          const numero = document.getElementById('swal-motivo-anulacion').value

          if (numero != undefined && numero != '' && numero != null && !isNaN(numero)) {
            await facturaNuevo({
              ...facturas,
              numero_control: numero,
            })

            if (facturas._servicio) {
              if (Number(facturas._servicio.pvp) > 0) {
                imprimirDocumentoNoFiscal(
                  'Total Venta:           Bs ' +
                    Number(facturas.total).toFixed(2) +
                    ';Total Servicio (10%)  Bs ' +
                    Number(facturas._servicio.pvp).toFixed(2) +
                    ';Total:                Bs ' +
                    (Number(facturas.total) + Number(facturas._servicio.pvp)).toFixed(2),
                  store.state.impresoraFiscal,
                )
              }
            }
            return true
          } else {
            Vue.swal.showValidationMessage(`Debe proporcionar un numero valido`)
          }
        },
        allowOutsideClick: () => {
          !Vue.swal.isLoading()

          GetFiscalizando(false)
        },
      }).then(result => {
        GetFiscalizando(false)
      })
    }

    function getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min) + min)
    }
    const facturaNuevo = async facturas => {
      await ComandaService.nuevaFactura(facturas)
        .then(response => {
          try {
            if (response.data.mensaje == 'FACTURA GUARDADA CON EXITO') {
              store.commit('setAlert', {
                message: `factura registrada con exito: ${facturas.numero_control}`,
                type: 'success',
              })

              context.emit('GetActualizarEstatusComanda', { facturas })
            } else {
              store.commit('setAlert', {
                message: `Verificar si se realizo la factura: ${facturas.numero_control}`,
                type: 'warning',
              })
            }
            console.log(response)
            GetFiscalizando(false)
          } catch (error) {
            store.commit('setAlert', {
              message: `hubo un error: ${error}`,
              type: 'error',

              error: error,
              funcion: 'facturaNuevo',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Disculpe hubo un error: ${error}`,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'facturaNuevo',
          })

          console.log(error)
          GetFiscalizando(false)
        })
    }
    const cargandoAgrupado = ref(false)
    const comanda_agrupada = ref({})
    watch(show, () => {
      if (show.value) {
        cargarComandaAgrupado()
      }
    })
    const cargarComandaAgrupado = _id => {
      cargandoAgrupado.value = true
      comanda_agrupada.value = {}
      ComandaService.listarComandaAgrupado({ id: props.comanda.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            comanda_agrupada.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
          GetFiscalizando(false)
        })
        .finally(() => {
          cargandoAgrupado.value = false
        })
    }

    const Fiscalizar = async item => {
      alert("Fiscalizar")
      
      await fiscalizar.Fiscalizar({
        item: item,
        _MonedaPais: { ...MonedaPais, tasa: item.tasa_modeda_pais },
        _serialFiscal: props.serialFiscal,
        _ind_devolucion: (item.factura == null ? false : true),
        _imprimir_tikera: true,
      })
       
      /*

      if (!(props.serialFiscal == null || props.serialFiscal == undefined || props.serialFiscal == '')) {
        if (!props.fiscalizando) {
          if (item.factura == null) {
            console.log('Fiscalizar.....')
            GetFiscalizando(true)
            comandaFiscalizada.value = item
            console.log(item)
            ComandaService.listarComandaAgrupado({ id: item.id })
              .then(response => {
                console.log(response)
                if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                  Totalizar(response.data.datos)
                }
              })
              .catch(error => {
                console.log(error)
                GetFiscalizando(false)
              })
          } else {
            console.log('Fiscalizar devolucion .....')
            GetFiscalizando(true)
            comandaFiscalizada.value = item
            console.log(item)
            ComandaService.listarFacturaLmpresora({ id: item.id })
              .then(response => {
                console.log('listarFacturaLmpresora')
                console.log(response)
                if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                  DevolverFactura(response.data.datos)
                }
              })
              .catch(error => {
                console.log(error)
                GetFiscalizando(false)
              })
          }
        }
      } else {
        store.commit('setAlert', {
          message: `Verifique la conexion con la maquina fiscal`,
          type: 'warning',
        })
      }*/
    }

    const DevolverFactura = datos => {
      const impresora = { ...store.state.impresoraFiscal }
      const factura = {
        ...datos,
        nFactura: datos.nFactura.toString(),
        montoRecDesc: datos.montoRecDesc.toString(),
        impresora: { ...impresora },
      }
      // facturaNuevo({ ...factura, numero_control: getRandomInt(1, 500) })
      console.log('aqui')
      console.log(JSON.stringify(factura))

      if (!(store.state.impresoraFiscalSelect.id > 0 && store.state.impresoraFiscalTipo == "REMOTO" )) {
        ImpresoraService.imprimirFactura({
          factura: factura,
          pIsDevolucion: true,
          pAbrirGaveta: false,
          indIgtf: configuracion.value.igtf > 0 ? true : false,
        })
          .then(response => {
            try {
              if ((response.data.estatus = true)) {
                if (Number(response.data.datos.lastDocument) > 0) {
                  facturaNuevo({
                    ...factura,
                    numero_control: response.data.datos.lastDocument,
                    serial_impresora: props.serialFiscal,
                  })
                } else {
                  store.commit('setAlert', {
                    message: `Verificar si se realizo la factura: ${response.data.datos.lastDocument}`,
                    type: 'info',
                  })
                }
              } else {
                store.commit('setAlert', {
                  message: `Hubo un error ${response.data.mensaje}`,
                  type: 'error',

                  error: response,
                  funcion: 'devolverFactura',
                })
              }

              console.log(response)
              GetFiscalizando(false)
            } catch (error) {
              GetFiscalizando(false)
              store.commit('setAlert', {
                message: `hubo un error: ${error}`,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: `Disculpe hubo un error: ${error}`,
              type: 'error',

              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'devolverFactura',
            })
            console.log(error)
            GetFiscalizando(false)
          })
      } else {
        //mandar a impresora remota
        ComandaService.ImprimirFiscal({
          factura: factura,
          id_comanda: factura.nFactura,
          devolucion: true,
          abrir_gaveta: false,
          ind_igtf: configuracion.value.igtf > 0 ? true : false,
          id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
          id_caja: store.state.user.caja.id,
        })
          .then(response => {
            if (response.data.mensaje == 'guardado con exito') {
              props.comanda.cola_fiscal.estado = 27

              store.commit('setAlert', {
                message: 'Se envio a la impresora fiscal ',
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                type: 'error',
                error: {
                  response: response.data,
                },
                funcion: 'ImprimirFiscal',
              })
            }

            GetFiscalizando(false)
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'Error al enviar a fiscalizar ' + error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'cerrarCuenta',
            })
            GetFiscalizando(false)
          })
      }
    }

    const imprimirDocumentoNoFiscal = (pLineas, impresora) => {
      ImpresoraService.imprimirNoFiscal(pLineas, impresora)
        .then(response => {
          store.commit('setAlert', {
            message: `Impresion tiket no fiscal`,
            type: 'success',
          })
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `error Impresion tiket no fiscal`,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'imprimirDocumentoNoFiscal',
          })
        })
    }
    const ImpresoraSelectRef = ref(null)
    const ImprimirGrupoPreguntar = comanda => {
      ImpresoraSelectRef.value.abrirFuncion(ImprimirGrupo, comanda)
    }

    const ImprimirGrupo = comanda => {
      MaestroService.imprimirGrupoTikera({
        id_comanda: comanda.id,
        id_grupo_impresora_pc: comanda.id_grupo_impresora_pc ? comanda.id_grupo_impresora_pc : store.state.tikera.id,
        id_comanda_detalle: 0,
      })
        .then(response => {
          console.log(response.data)
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: `Se envio a la impresora.`,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: `Ups, ${response.data.mensaje}`,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Ups, ${error}`,
            type: 'error',

            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'imprimirGrupo',
          })
        })
    }

    const clickFalse = () => {
      console.log('clickFalse')
    }
    const round = num => {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }

    const ColorEstadoPago = () => {
      if (props.comanda.estado_pago == 7) {
        if (props.comanda.ind_bloqueado == true) {
          return 'primary5'
        } else {
          return 'primary'
        }
      } else if (props.comanda.estado_pago == 8 || props.comanda.estado_pago == 14) {
        return 'error'
      } else if (props.comanda.estado_pago == 9 && props.comanda.estado_fiscalizado == 39) {
        return 'warning'
      } else if (props.comanda.estado_pago == 9 && props.comanda.estado_fiscalizado == 40) {
        return 'success'
      } else {
        return 'info'
      }
    }

    const imprimirNoFiscalGet = () => {
      imprimirDocumentoNoFiscal(
        'Total Venta:                   Bs 100,00;Total Servicio (10%)            Bs 10,00;Total                          Bs 110,00;',
        store.state.impresoraFiscal,
      )
    }

    return {
      icon: {
        mdiArrowLeft,
        mdiMagnify,
        mdiAccount,
        mdiHeart,
        mdiShareVariant,
        mdiMapMarker,
        mdiEye,
        mdiPrinter,
        mdiPrinterPosOutline:
          'M18 10H17V4H7V10H6C4.89 10 4 10.9 4 12V19H20V12C20 10.9 19.11 10 18 10M9 6H15V10H9V6M18 17H6V12H18V17M17 15H13V13H17V15Z',
        mdiPrinterPosRemoveOutline:
          'M7 15V13H11V15H7M6 17H13.35C13.13 17.63 13 18.3 13 19H4V12C4 10.9 4.89 10 6 10H7V4H17V10H18C19.11 10 20 10.9 20 12V13.09C19.67 13.04 19.34 13 19 13C18.66 13 18.33 13.04 18 13.09V12H6V17M9 10H15V6H9V10M22.54 16.88L21.12 15.47L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88Z',
        mdiStarFourPointsSmall,
        mdiCheckAll,
        mdiChevronDown,
        mdiChevronUp,
        mdiCallSplit,
        mdiSwapHorizontal,
        mdiBlockHelper,
        mdiFileEye,
      },
      VerComanda,
      Fiscalizar,
      comandaFiscalizada,
      mensaje,
      validarAcciones,
      acciones,
      clickFalse,
      ColorEstadoPago,
      round,
      ImprimirGrupo,
      show,
      config,
      modalSeparar,
      GetModalSeparar,
      modalTransferir,
      GetModalTransferir,
      store,
      nombreCliente,
      imprimirNoFiscalGet,
      bloquearDesbloquearComanda,
      logoI,
      getImpresora,
      ImprimirGrupoPreguntar,
      ImpresoraSelectRef,
      configuracion,
      cargandoAgrupado,
      comanda_agrupada,
      cargarComandaAgrupado,
      ModalConfirmacionRef,
      cerrarCuenta,
      funciones,
    }
  },
}
</script>