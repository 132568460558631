<template>
  <div>
    <div v-if="configuraciones && configuraciones.length > 0">
      <template>
        <v-row class="match-height">
          <v-col class="mx-0 px-1">
            <v-hover v-slot="{ hover }">
              <v-card class="error" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="CerrarSesion()">
                <v-card-text class="text-center py-1 white--text">
                  <v-icon color="white">{{ mdiExitToApp }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Cerrar sesion</strong>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_CREAR_COMANDA)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="cargarZonas()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiDotsSquare }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Asignar Zona</strong>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="ActualizarForzado()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiRefresh }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Actualizar</strong>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>

          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_ASIGNAR_CAJA)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="abrirModalAsignarCaja()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiAccountLock }} </v-icon>

                  <small v-if="!contraerBtn"> <br /><strong>Asignar Cajas</strong> </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>

          <v-col class="mx-0 px-1" v-if=" funciones.configuracionSelect(20) == '1' && validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="ReporteX()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiAlphaXCircle }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Reporte X</strong> <br />
                    <small>{{ serialFiscal }}</small>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if=" funciones.configuracionSelect(20) == '1' && validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="ReportePreZ()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiAlphaZCircle }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Reporte Z</strong> <br />
                    <small>{{ serialFiscal }}</small>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_TRANSFERIR_COMANDA)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="abrirModalTransferirMasiva()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiTransitTransfer }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Transferir comanda</strong>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_UNIR_COMANDA)">
            <modal-unir-comanda-vue :indLabel="!contraerBtn" :comandas="comandas.filter(item => item.estado_pago == 7)">
            </modal-unir-comanda-vue>
          </v-col>

          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)">
            <v-hover v-slot="{ hover }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" v-bind="attrs" v-on="on">
                    <v-card-text class="text-center py-1">
                      <v-icon color="primary">{{ mdiFilterVariant }} </v-icon>

                      <small v-if="!contraerBtn">
                        <br />
                        <strong>{{
                          filtroEstadoPuesto == 1 ? 'PENDIENTES' : filtroEstadoPuesto == 2 ? 'PROCESADOS' : 'ANULADOS'
                        }}</strong>
                      </small>
                    </v-card-text>
                  </v-card>
                </template>
                <v-list>
                  <v-list-item link @click="filtroEstadoPuesto = 1">
                    <v-list-item-title>PENDIENTES</v-list-item-title>
                  </v-list-item>

                  <v-list-item link @click="filtroEstadoPuesto = 2">
                    <v-list-item-title>PROCESADOS</v-list-item-title>
                  </v-list-item>

                  <v-list-item link @click="filtroEstadoPuesto = 3">
                    <v-list-item-title>ANULADOS</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="abrirProductos()">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ mdiFood }} </v-icon>

                  <small v-if="!contraerBtn">
                    <br />
                    <strong>Productos</strong>
                  </small>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
          <v-col class="mx-0 px-1" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_CREAR_COMANDA)">
            <modal-comanda
              :indLabel="!contraerBtn"
              :mesas="mesas"
              :formasPagos="formasPagos"
              :mesoneros="mesoneros"
              :monedas="monedas"
              :configuraciones="configuraciones"
              :estadoFormaPago="estadoFormaPago"
              :serialFiscal="serialFiscal"
              @getNumeroComanda="getNumeroComanda"
              @GetisDialogVisible="GetisDialogVisible"
              ref="RefModalComanda"
            ></modal-comanda>
          </v-col>
          <v-col class="mx-0 px-0" cols="auto" v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="contraerBtn = !contraerBtn">
                <v-card-text class="text-center py-1">
                  <v-icon color="primary">{{ contraerBtn ? mdiChevronDown : mdiChevronUp }} </v-icon>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>

        <v-row>
          <v-col

            cols="12"
            v-if="  funciones.configuracionSelect(20) == '1'   && validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA) && serialFiscal == ''"
            class="text-center"
          >
            <v-chip @click="obtenerSerialImpresoraFiscal()" link small label class="mx-1" color="error">
              <span v-if="!serialFiscalCargando && serialFiscal == ''">Impresora fiscal sin conexión (Reintentar)</span>
              <v-progress-circular
                v-if="serialFiscalCargando"
                :size="10"
                :width="3"
                color="red"
                indeterminate
              ></v-progress-circular>
            </v-chip>
          </v-col>
          <v-col lg="8" md="12" sm="12" cols="12">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="filtroComandaTemp"
                  hide-details
                  :prepend-icon="mdiMagnify"
                  single-line
                  placeholder="Buscar Comanda..."
                  append-icon=""
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="
                  validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER) &&
                  validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)
                    ? tabs == 1
                    : validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER) &&
                      !validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)
                    ? true
                    : false
                "
              >
                <SelectPuesto2
                  v-model="filtroComandaPuesto"
                  :add="false"
                  :todos="true"
                  :filtros="{ modulos: '104' }"
                ></SelectPuesto2>
              </v-col>
              <v-col
                cols="auto"
                v-if="
                  validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER) &&
                  validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)
                    ? tabs == 1
                    : validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER) &&
                      !validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)
                    ? true
                    : false
                "
              >
                <v-btn @click="modalReporteCierreVentasPuesto = true" icon>
                  <v-icon color="primary"> {{ mdiFileChart }} </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="text-right" lg="4" md="12" sm="12" cols="12">
            <v-tabs v-model="tabs" right background-color="transparent">
              <v-tab v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)">
                <span>
                  <v-switch
                    v-model="switch1"
                    v-if="validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)"
                  ></v-switch
                ></span>
                <v-badge color="primary" :content="no_comanda"> Comandas </v-badge>
              </v-tab>
              <v-tab
                v-if="store.state.user.puestos.length > 0 && validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)"
              >
                <v-btn small icon @click="notifySonido()">
                  <v-icon color="primary">{{ mdiMusicCircleOutline }}</v-icon>
                </v-btn>
                <v-badge class="ml-2" color="primary" :content="no_comanda_puesto"> Cola Areas </v-badge>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tabs" class="transparent" fixed-tabs>
          <v-tab-item class="transparent" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_COMANDA)">
            <v-row class="mt-4">
              <v-col
                md="6"
                xl="3"
                cols="12"
                sm="6"
                lg="4"
                v-for="item in FiltrarComanda()"
                :key="item.id + 'modal-comanda'"
              >
                <card-comanda
                  :serialFiscal="serialFiscal"
                  :mesas="mesas"
                  :formasPagos="formasPagos"
                  :mesoneros="mesoneros"
                  :monedas="monedas"
                  :configuraciones="configuraciones"
                  @GetVerComanda="GetVerComanda"
                  :comanda="item"
                  :fiscalizando="fiscalizando"
                  @GetFiscalizando="GetFiscalizando"
                  @GetActualizarEstatusComanda="GetActualizarEstatusComanda"
                  :showAll="showAll"
                >
                </card-comanda>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-pagination
                  v-model="page_number"
                  :length="Number(no_of_pages)"
                  circle
                  :total-visible="'7'"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-if="store.state.user.puestos.length > 0 && validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)"
          >
            <div class="row mt-4">
              <v-col
                md="6"
                xl="2"
                cols="12"
                sm="6"
                lg="3"
                v-for="item in FiltrarComandaPuesto()"
                :key="item.id + item.fecha + item.id_puesto"
              >
                <card-comanda-puesto :comanda="item" :showAll="true"> </card-comanda-puesto>
              </v-col>
            </div>

            <v-row>
              <v-col>
                <v-pagination
                  v-model="page_number_puesto"
                  :length="Number(no_of_pages_puesto)"
                  circle
                  :total-visible="'7'"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </template>
      <modal-transferir-comanda-masiva ref="modalTransferirMasiva"></modal-transferir-comanda-masiva>
      <modal-asignar-caja ref="modalAsignarCaja"></modal-asignar-caja>
      <ModalVerComanda
        :comanda="comandaSelect"
        :soloVer="true"
        :indFiscal="true"
        :openModal="openModalComanda"
        @GetOpenModal="GetOpenModalComanda"
        :serialFiscal="serialFiscal"
        :monedas="monedas"
      />
      <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
      <ConfirmacionUsuario @GetConfirmacionUsuario="GetConfirmacionUsuario" ref="ConfirmacionUsuarioRef" />
      <ModalConfirmacion @GetModalConfirmacion="GetModalConfirmacion" ref="ModalConfirmacionRef" />
      <ModalEntraSalidaCaja ref="ModalEntraSalidaCajaRef" />
      <ModalArqueoCaja ref="ModalArqueoCajaRef" />
      <ModalPuntosVentasActivos ref="ModalPuntosVentasActivosRef" />
    </div>
    <div v-else>
      <v-dialog v-model="dialogCargandoDatos" persistent width="300">
        <div>
          <v-card color="primary" dark v-if="configuraciones_error == false">
            <v-card-text class="text-center">
              Cargado datos generales de comanda...
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
          <v-card color="error" dark v-else>
            <v-card-text class="text-center white--text">
              <h3>Disculpe</h3>
              <div class="my-4 text-center">
                <v-icon class="white--text" size="100">{{ mdiEmoticonCryOutline }}</v-icon>
              </div>
              <div class="text-center">
                Hubo un error al intentar descargar los datos generales de comandas. <br />
                Verifique
              </div>

              <v-btn small class="mt-2 secondary" @click="refreshPage()">Recargar</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </div>
    <v-dialog v-model="dialogZona" persistent width="500">
      <v-card>
        <v-card-title> Zonas asignadas </v-card-title>
        <v-card-text>
          <v-item-group multiple v-model="zonasSelect">
            <v-container>
              <v-row>
                <v-col v-for="n in zonas" :key="n.id">
                  <v-item v-slot="{ active, toggle }" :value="n.descripcion">
                    <v-card
                      :color="active ? 'primary' : ''"
                      class="d-flex align-center"
                      dark
                      height="80"
                      @click="toggle"
                    >
                      <v-scroll-y-transition>
                        <div class="white--text flex-grow-1 text-center">{{ n.descripcion }}</div>
                        <div class="white--text flex-grow-1 text-center">{{ n.descripcion }}</div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialogZona = false"> Cerrar </v-btn>
          <v-btn color="primary" small @click="asignarZona()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalProducto" scrollable persistent width="900">
      <v-card>
        <v-card-title>
          <div>
            <v-row>
              <v-col cols="12" class="my-0 py-0 pt-2">Productos</v-col>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <v-text-field v-model="filtroProducto" label="Buscar"></v-text-field>
                  </v-col>
                  <v-col>
                    <SelectPuesto2
                      v-model="puestoSelect"
                      @change="cargarProductos()"
                      :add="false"
                      :filtros="{ modulos: '104' }"
                    ></SelectPuesto2>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in productosListaFiltro()" :key="key">
              <v-card @click="cambiarEstadoProducto(item)">
                <v-img
                  :src="`${config.api_imagenes}/producto/img/m-${item.img}`"
                  :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="160px"
                >
                  <small class="text-img"> {{ item.nombre }} </small>

                  <small v-if="item.estado == 1" class="text-img2">
                    {{ item.precio_venta.toFixed(2) }}$ <br /><small v-if="item.impuesto_tasa_valor > 0">
                      +iva {{ (item.precio_venta * (item.impuesto_tasa_valor / 100 + 1)).toFixed(2) }}$
                    </small></small
                  >
                  <small v-if="item.estado == 2" class="text-img3"> AGOTADO</small>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="modalProducto = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalReporteCierreVentasPuesto" scrollable width="900">
      <div class="my-6 mx-6">
        <reporteCierreVentasPuesto />
      </div>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiMagnify,
  mdiChevronDown,
  mdiCallMerge,
  mdiCallSplit,
  mdiSwapHorizontal,
  mdiAlphaZCircle,
  mdiAlphaXCircle,
  mdiInformation,
  mdiCashRegister,
  mdiChevronUp,
  mdiTransitTransfer,
  mdiFood,
  mdiAccountLock,
  mdiRefresh,
  mdiExitToApp,
  mdiMusicCircleOutline,
  mdiFilterVariant,
  mdiDotsSquare,
  mdiEmoticonCryOutline,
  mdiArrowCollapseAll,
  mdiArrowExpandAll,
  mdiFileChart,
} from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import ModalComanda from './componentes/ModalComanda.vue'
import CardComanda from './componentes/CardComanda.vue'
import ComandaService from '@/api/servicios/ComandaService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { tr } from 'vuetify/lib/locale'
import store from '@/store'
import router from '@/router'
import { acciones } from '@/modulos'
import Vue from 'vue'
import SeguridadService from '@/api/servicios/SeguridadService'
import ImpresoraService from '@/api/servicios/ImpresoraService'
import MaestroService from '@/api/servicios/MaestroService'
import ModalUnirComandaVue from './componentes/ModalUnirComanda.vue'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
import ProductosService from '@/api/servicios/ProductosService'
import config from '@/api/config'
// demos
import CambioClave from '../../administracion/archivo/empleado/CambioClave.vue'
import ModalTransferirComandaMasiva from './componentes/ModalTransferirComandaMasiva.vue'
import ModalAsignarCaja from './componentes/ModalAsignarCaja.vue'
import Ping from 'ping.js'
import ModalVerComanda from '../../administracion/dashboard/componentes/ModalVerComanda.vue'
import CardComandaPuesto from './componentes/CardComandaPuesto.vue'

import ConfirmacionUsuario from '@/components/ConfirmacionUsuario.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
import { socket, state as stateSocket } from '@/plugins/sockets'
import ModalEntraSalidaCaja from './componentes/ModalEntraSalidaCaja.vue'
import ModalArqueoCaja from './componentes/ModalArqueoCaja.vue'
import ModalPuntosVentasActivos from './componentes/ModalPuntosVentasActivos.vue'

import SelectPuesto2 from '../../administracion/archivo/puesto/components/SelectPuesto2.vue'
import reporteCierreVentasPuesto from '../reportes/reporteCierreVentasPuesto.vue'
import funciones from '@/funciones/funciones'
export default {
  components: {
    StatisticsCardVertical,
    ModalComanda,
    CardComanda,
    ModalUnirComandaVue,
    CambioClave,
    ModalTransferirComandaMasiva,
    ModalAsignarCaja,
    ModalVerComanda,
    CardComandaPuesto,
    KeyBoardEvents,
    ConfirmacionUsuario,
    ModalConfirmacion,
    ModalEntraSalidaCaja,
    ModalArqueoCaja,
    ModalPuntosVentasActivos,
    SelectPuesto2,
    reporteCierreVentasPuesto,
  },
  watch: {
    'stateSocket.connected': function (news, olds) {
      if (news) {
        this.suscribirSockets()
        this.CargarDatosGenerales()
      }
    },
    'stateSocket.modificarComandaAll': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.ListarComandaCola()
        }, this.random(1, 5) * 1000)
      }
    },
    'stateSocket.modificarComanda': function (news, olds) {
      if (olds < news) {
        setTimeout(() => {
          //alert("modificarComandaAll")
          this.ListarComanda()
        }, this.random(1, 5) * 1000)
      }
    },
    '$store.state.infoCargaDatos.finalizado': function (nuew, old) {
      if (this.store.state.infoCargaDatos.finalizado == true) {
        this.CargarDatosGenerales()
      }
    },
  },
  methods: {
    refreshPage() {
      location.reload()
    },
  },
  setup() {
    const modalReporteCierreVentasPuesto = ref(false)
    const filtroComandaPuesto = ref(-1)
    const modalProducto = ref(false)
    const contraerBtn = ref(false)
    const comandaSelect = ref(null)
    const openModalComanda = ref(false)
    const modalTransferirMasiva = ref(null)
    const ModalArqueoCajaRef = ref(null)
    const tabs = ref(0)
    const mesas = ref([])
    const showAll = ref(true)
    const formasPagos = ref([])
    const mesoneros = ref([])
    const monedas = ref([])
    const configuraciones = ref([])
    const fiscalizando = ref(false)
    const filtroEstadoPuesto = ref(1)
    const dialogCargandoDatos = ref(false)

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const zonasSelect = ref([])
    const dialogZona = ref(false)
    const zonas = ref([])
    const productosLista = ref([])
    const filtroProducto = ref('')

    const productosListaFiltro = () => {
      let valor = []
      valor = productosLista.value?.filter(
        item =>
          item.nombre.includes(filtroProducto.value.toUpperCase()) ||
          item.referencia.includes(filtroProducto.value.toUpperCase()) ||
          filtroProducto.value == '',
      )
      if (valor) {
        return valor
      }
      return []
    }
    const puestoSelect = ref(null)
    const cambiarEstadoProducto = item => {
      if (!item.cargando) {
        item.cargando = true
        ProductosService.productoModificarEestado({
          id_producto: item.id,
          puesto: puestoSelect.value,
          estado: item.estado == 1 ? 2 : 1,
        })
          .then(response => {
            if (response.data.mensaje == 'GUARDADO CON EXITO') {
              if (item.estado == 1) {
                item.estado = 2
              } else {
                item.estado = 1
              }

              store.commit('setAlert', {
                message: 'producto modificado con exito',
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'cambiarEstadoProducto',
            })
          })
          .finally(() => {
            item.cargando = false
          })
      } else {
        store.commit('setAlert', {
          message: 'Espere un momento miestras cambia el estado',
          type: 'warning',
        })
      }
    }

    const abrirProductos = () => {
      modalProducto.value = true
      cargarProductos()
    }

    const asignarZona = () => {
      console.log('asignarZona', zonasSelect.value.join(','))
      store.commit('setUsuarioZona', zonasSelect.value.join(','))
      console.log(store.state.user.zonas)
      dialogZona.value = false

      socket.emit('join-zonas', {
        id_empleado: store.state.user.id_empleado,
        usuario: store.state.user.usuario,
        zonas: zonasSelect.value.join(','),
      })
    }

    const cargarProductos = () => {
      ProductosService.obtenerProductosPuestos({ id_puesto: puestoSelect.value })
        .then(response => {
          productosLista.value = response.data.datos[0].productos
        })
        .catch(error => {})
        .finally(() => {})
    }

    const cargarZonas = () => {
      dialogZona.value = true
      MaestroService.GruposMesasListar()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            zonas.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const ModalEntraSalidaCajaRef = ref(null)
    const isDialogVisible = ref(false)

    const GetisDialogVisible = dato => {
      isDialogVisible.value = dato
    }

    onBeforeMount(() => {
      /* socket.on('modificar-comanda', (...args) => {
        ListarComanda()
      })*/
      suscribirSockets()
      puestoSelect.value = store.state.puestoSelect.id_puesto
      /*
      socket.on('modificar-comanda-all', (...args) => {
        setTimeout(() => {
          ListarComandaCola()
        }, random(1, 10))
      })*/
    })
    function random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
    const ModalConfirmacionRef = ref(null)

    const GetModalConfirmacion = (_funcion, _datos) => {
      _funcion(_datos)
    }

    const GetConfirmacionUsuario = (_funcion, _datos, _funcionCancel, _cancel) => {
      if (_cancel) {
        _funcionCancel()
      } else {
        _funcion(_datos)
      }
    }

    const filtroComandaTemp = ref('')
    let myTimeout = null

    const filtroComandaTriger = () => {
      clearTimeout(myTimeout)
      myTimeout = setTimeout(() => {
        filtoComanda.value = filtroComandaTemp.value
        filtroComandaCargando.value = false
      }, 1000)
    }
    const filtroComandaCargando = ref(false)

    watch(filtroComandaTemp, () => {
      if (filtroComandaTemp.value.length >= 1) {
        filtroComandaCargando.value = true
        filtroComandaTriger()
      } else {
        filtroComandaCargando.value = false
        filtoComanda.value = ''
        clearTimeout(myTimeout)
      }

      console.log('filtoComanda', filtroComandaTemp.value)
    })

    const ConfirmacionUsuarioRef = ref(null)
    const suscribirSockets = () => {
      socket.emit('suscribe-comanda-mesonero', {
        suscripcion: 'comanda-mesonero',
        criterio: '',
        valor: validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA) ? '-1' : store.state.user.id_empleado,
        identificador: store.state.user.usuario,
      })
    }

    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const CerrarSesion = () => {
      store.commit('setUserData', {
        userName: '',

        IsAutenticated: false,
        accion_modulo: '[ ]',
        age: 25,
        apellido: '',
        arbol: [],
        autenticate: true,
        caja: {},
        foto: '',
        id: 0,
        id_empleado: 0,
        modulo: '[]',
        nombre: '',
        puestos: [],
        token: '',
        usuario: '',
      })
      router.push({ path: '/pages/login' }).catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      })
    }
    onBeforeMount(() => {
      if (store.state.user.puestos.length > 1) {
        filtroComandaPuesto.value = -1
      } else {
        filtroComandaPuesto.value = store.state.user.puestos[0].id
      }

      if (validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)) {
        showAll.value = false
      }
    })
    const abrirModalTransferirMasiva = () => {
      modalTransferirMasiva.value.abrir()
    }

    const modalAsignarCaja = ref()

    const abrirModalAsignarCaja = () => {
      modalAsignarCaja.value.abrir()
    }

    const switch1 = ref(false)

    const totalesGeneral = ref({
      subTotal: 0,
      servicio: 0,
      iva: 0,
      total: 0,
      cant_total: 0,
      cant_pagados: 0,
      cant_pendiente: 0,
      cant_anulados: 0,
    })
    const sumTotales = () => {
      let totales = {
        subTotal: 0,
        servicio: 0,
        iva: 0,
        total: 0,
        cant_total: 0,
        cant_pagados: 0,
        cant_pendiente: 0,
        cant_anulados: 0,
      }

      comandas.value.forEach(element => {
        totales.cant_total += 1
        if (element.estado_pago != 14 && element.estado_pago != 8) {
          totales.subTotal += element.subtotal
          totales.servicio += element.comision
          totales.iva += element.iva
          totales.total += element.total

          if (element.estado_pago == 7) {
            totales.cant_pendiente += 1
          }
          if (element.estado_pago == 9) {
            totales.cant_pagados += 1
          }
        } else {
          totales.cant_anulados += 1
        }
      })

      totalesGeneral.value = totales
    }

    let ultimaActualizacion = '20000101'
    const comandas = ref([])
    const filtoComanda = ref('')
    const RefModalComanda = ref(null)
    const filtoComandaEstadoPago = ref({ id: 7, descripcion: 'POR COBRAR' })

    const EstadosPago = [
      {
        id: 7,
        descripcion: 'POR COBRAR',
        accion: acciones.DASHBOARD_COMANDA_FILTRAR_COMANDA,
      },
      {
        id: 9,
        descripcion: 'PAGADAS',
        accion: acciones.DASHBOARD_COMANDA_FILTRAR_COMANDA,
      },
      {
        id: 39,
        descripcion: 'POR FISCALIZAR',
        accion: acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA,
      },
      {
        id: 40,
        descripcion: 'FISCALIZADA',
        accion: acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA,
      },
      {
        id: 14,
        descripcion: 'ANULADAS',
        accion: acciones.DASHBOARD_COMANDA_FILTRAR_COMANDA,
      },
    ]

    const EstadosPagoFiltro = () => {
      return EstadosPago.filter(item => validarAcciones(item.accion))
    }

    let IntervalListarComanda = null
    let counterConsultas = ref(0)
    let counterConsultasCola = ref(0)
    /*watch(counterConsultasCola, () => {
      setTimeout(() => {
        ListarComandaCola()
      }, 5000)
    })*/

    onBeforeMount(() => {
      setTimeout(() => {
        if (store.state.infoCargaDatos.finalizado) {
          CargarDatosGenerales()
        }
      }, 0)
    })
    const actualizandoForzado = ref(false)
    const ActualizarForzado = () => {
      actualizandoForzado.value = true
      ultimaActualizacion = '20000101'
      comandas.value = []
      ListarComanda(false)
      setTimeout(() => {
        actualizandoForzado.value = false
      }, 5000)
    }
    const configuraciones_error = ref(false)
    const estadoFormaPago = ref([])
    const CargarDatosGenerales = () => {
      try {
        dialogCargandoDatos.value = true
        configuraciones_error.value = false
        ComandaService.obtenerDatosGeneralesComanda()
          .then(response => {
            console.log(response)
            mesas.value = response.data.datos.mesas
            formasPagos.value = response.data.datos.forma_pago
            mesoneros.value = response.data.datos.mesoneros
            monedas.value = response.data.datos.monedas
            configuraciones.value = response.data.datos.configuraciones
            estadoFormaPago.value = response.data.datos.estado_forma_pago
            //MonedaPais.value = monedas.value.filter(item => item.meneda_pais == true)[0]
            if (  funciones.configuracionSelect(20) == '1'  &&    funciones.configuracionSelect(21) != '0' &&  validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
              obtenerSerialImpresoraFiscal()
            }
            ListarComanda()
            ListarComandaCola()
            if (
              store.state.user.id_empleado_tipo == 6 &&
              (store.state.user.zonas == '' || store.state.user.zonas == null)
            ) {
              dialogCargandoDatos.value = false

              cargarZonas()
            } else if (!(store.state.user.zonas == '' || store.state.user.zonas == null)) {
              zonasSelect.value = store.state.user.zonas.split(',')

              socket.emit('join-zonas', {
                id_empleado: store.state.user.id_empleado,
                usuario: store.state.user.usuario,
                zonas: store.state.user.zonas,
              })
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'CargarDatosGenerales',
            })
            configuraciones_error.value = true
          })
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
          funcion: 'CargarDatosGenerales',
        })
        configuraciones_error.value = true
      }
    }
    const GetActualizarEstatusComanda = datos => {
      store.commit('setAlert', {
        message: 'Factura fiscalizada con exito',
        type: 'success',
      })

      comandas.value.forEach(element => {
        if (datos.nFactura == element.id) {
          element.factura = { numero_control: datos.numero_control }
          element.estado_fiscalizado = 40
        }
      })
    }
    const GetFiscalizando = datos => {
      fiscalizando.value = datos
    }

    const GetVerComanda = datos => {
      console.log(datos)
      ComandaService.listarComanda({ id: datos.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            RefModalComanda.value.CargaInicial(response.data.datos)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const page_number = ref(1)
    const no_of_pages = ref(0)
    const no_comanda = ref(0)

    const page_number_puesto = ref(1)
    const no_of_pages_puesto = ref(0)
    const no_comanda_puesto = ref(0)
    watch(filtoComanda, () => {
      page_number.value = 1
    })
    const FiltrarComanda = () => {
      const valor = comandas.value?.filter(
        item =>
          (filtoComanda.value == '' &&
            item.estado_pago == 7 &&
            ((switch1.value == true && item.id_empleado_mesonero == store.state.user.id_empleado) ||
              switch1.value == false)) ||
          (filtoComanda.value != '' &&
            (item.id.toString().includes(filtoComanda.value) ||
              item.cliente.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.mesonero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.mesa_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.nombre_cliente?.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.apellido_cliente?.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              `${item.nombre_cliente} ${item.apellido_cliente}`
                .toUpperCase()
                .includes(filtoComanda.value.toUpperCase()) ||
              item.comandero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()))) /*&&
          (
            item.estado_pago == filtoComandaEstadoPago.value.id ||
            (item.estado_fiscalizado == filtoComandaEstadoPago.value.id && item.estado_pago != 14) ||
            (filtoComandaEstadoPago.value.id == 39 && ((item.estado_fiscalizado == 39 && item.estado_pago != 14) || item.estado_fiscalizado == 43)) ||
            (filtoComandaEstadoPago.value.id == 40 && ((item.estado_fiscalizado == 40 || item.estado_fiscalizado == 44)))


          )*/,
      )
      if (validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)) {
        no_comanda.value = valor.length
        const total_no_of_items = valor.length
        const items_per_page = 8
        no_of_pages.value =
          total_no_of_items / items_per_page > Number((total_no_of_items / items_per_page).toFixed())
            ? Number((total_no_of_items / items_per_page).toFixed()) + 1
            : (total_no_of_items / items_per_page).toFixed()
        const items_to_skip = (page_number.value - 1) * items_per_page
        const items = valor.slice(items_to_skip, items_per_page + items_to_skip)

        return items
      } else {
        no_comanda.value = valor?.filter(item => item.id_empleado_mesonero == store.state.user.id_empleado).length
        const total_no_of_items = valor?.filter(
          item => item.id_empleado_mesonero == store.state.user.id_empleado,
        ).length
        const items_per_page = 8
        no_of_pages.value =
          total_no_of_items / items_per_page > Number((total_no_of_items / items_per_page).toFixed())
            ? Number((total_no_of_items / items_per_page).toFixed()) + 1
            : (total_no_of_items / items_per_page).toFixed()
        const items_to_skip = (page_number.value - 1) * items_per_page
        const items = valor
          ?.filter(item => item.id_empleado_mesonero == store.state.user.id_empleado)
          .slice(items_to_skip, items_per_page + items_to_skip)

        return items
      }
    }

    const FiltrarComandaPuesto = () => {
      let valor = comandasPuesto.value?.filter(
        item =>
          ((filtoComanda.value == '' &&
            ((item.estado_despacho < 13 && filtroEstadoPuesto.value == 1) ||
              (item.estado_despacho == 13 && filtroEstadoPuesto.value == 2) ||
              ((item.estado_despacho == 14 || item.detalles.filter(item2 => item2.estado == 14).length > 0) &&
                filtroEstadoPuesto.value == 3))) ||
            (filtoComanda.value != '' &&
              (item.comanda.id.toString().includes(filtoComanda.value) ||
                item.comanda.cliente.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
                item.comanda.mesonero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
                //item.comanda.nombre_cliente?.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
                //item.comanda.apellido_cliente.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
                //(`${item.comanda.nombre_cliente.toUpperCase()} ${item.comanda.apellido_cliente.toUpperCase()}`).includes(filtoComanda.value.toUpperCase()) ||
                item.comanda.mesa_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase())))) &&
          (filtroComandaPuesto.value == -1 || filtroComandaPuesto.value == item.id_puesto),
      )
      valor = valor.filter(item => item.items != item.ind_anulado_separa_unido_count)
      no_comanda_puesto.value = valor.length
      const total_no_of_items = valor.length
      const items_per_page = 20
      no_of_pages_puesto.value =
        total_no_of_items / items_per_page > Number((total_no_of_items / items_per_page).toFixed())
          ? Number((total_no_of_items / items_per_page).toFixed()) + 1
          : (total_no_of_items / items_per_page).toFixed()
      const items_to_skip = (page_number_puesto.value - 1) * items_per_page
      const items = valor.slice(items_to_skip, items_per_page + items_to_skip)

      return items
    }

    const ListarComanda = (incrementar = true) => {
      try {
        let comand = []
        let comanda_old = [...comandas.value]
        const id_comandero = validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)
          ? -1
          : store.state.user.id_empleado
        ComandaService.listarComandas({ ultima_actualizacion: ultimaActualizacion, id_comandero: id_comandero })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (ultimaActualizacion == '20000101') {
                if (response.data?.datos && response.data?.datos?.length > 0) {
                  comandas.value = response.data?.datos
                }
              } else {
                if (response.data.datos.length > 0) {
                  comandas.value = [...comandas.value, response.data.datos]
                  //OPTENER SiN MODICIACION
                  let comandasSinActualizacion = comanda_old.filter(
                    it => !response.data.datos.find(it2 => it.id === it2.id),
                  )
                  /// optener modificaciones
                  let comandasActualizacion = response.data.datos.filter(it =>
                    comanda_old.find(it2 => it.id === it2.id),
                  )
                  //OPTENER NUEVOS
                  let comandasNuevas = response.data.datos.filter(it => !comanda_old.find(it2 => it.id === it2.id))

                  comand = comandasSinActualizacion.concat(comandasActualizacion, comandasNuevas)

                  comand = comand.sort(function (a, b) {
                    if (a.id > b.id) {
                      return 1
                    }
                    if (a.id < b.id) {
                      return -1
                    }
                    return 0
                  })

                  comandas.value = comand
                }
              }
              if (response.data.ultima_actualizacion != null) {
                ultimaActualizacion = response.data.ultima_actualizacion
              }
            }
            if (incrementar == true) {
              counterConsultas.value += 1
            }
          })
          .catch(error => {
            console.error(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'ListarComanda',
            })
            if (incrementar == true) {
              counterConsultas.value += 1
            }
          })
          .finally(() => {
            sumTotales()
          })
      } catch (error) {
        console.error(error)
        if (incrementar == true) {
          counterConsultas.value += 1
        }

        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
          funcion: 'ListarComanda',
        })
      }
    }

    var ultimaActualizacionCola = '20000101'
    const comandasPuesto = ref([])
    const ListarComandaCola = (incrementar = true) => {
      try {
        let comand = []
        let comanda_old = [...comandasPuesto.value]

        ComandaService.listarComandasPuesto({ ultima_actualizacion: ultimaActualizacionCola, id_puesto: '53,54' })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (ultimaActualizacionCola == '20000101') {
                if (response.data?.datos && response.data?.datos?.length > 0) {
                  comandasPuesto.value = response.data?.datos
                  if (comandasPuesto.value.length > 0) {
                    notifySonido()
                  }
                }
              } else {
                if (response.data.datos.length > 0) {
                  comandasPuesto.value = [...comandasPuesto.value, ...response.data.datos]
                  //OPTENER SiN MODICIACION
                  let comandasSinActualizacion = comanda_old.filter(
                    it => !response.data.datos.find(it2 => it.id === it2.id && it.fecha === it2.fecha),
                  )
                  /// optener modificaciones
                  let comandasActualizacion = response.data.datos.filter(it =>
                    comanda_old.find(it2 => it.id === it2.id && it.fecha === it2.fecha),
                  )
                  //OPTENER NUEVOS
                  let comandasNuevas = response.data.datos.filter(
                    it => !comanda_old.find(it2 => it.id === it2.id && it.fecha === it2.fecha),
                  )

                  if (comandasNuevas.length > 0) {
                    notifySonido()
                  }

                  comand = comandasSinActualizacion.concat(comandasActualizacion, comandasNuevas)

                  comand = comand.sort(function (a, b) {
                    if (a.fecha > b.fecha) {
                      return 1
                    }
                    if (a.fecha < b.fecha) {
                      return -1
                    }
                    return 0
                  })

                  comandasPuesto.value = comand
                }
              }
              if (response.data.ultima_actualizacion != null) {
                ultimaActualizacionCola = response.data.ultima_actualizacion
              }
            }
          })
          .catch(error => {
            console.error(error)

            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'ListarComandaCola',
            })
          })
          .finally(() => {})
      } catch (error) {
        console.error(error)

        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
          funcion: 'ListarComandaCola',
        })
      }
    }
    const impresora = { ...store.state.impresoraFiscal }
    /*const impresora =
 
     {
       modeloImpresora: 1,
       puerto: 99,
       bAudios: 9600,
       paridad: 2,
       datos: 8,
       stopBits: 1,
       handshake: 0,
       maxTiempoEspera: 5000,
       tiempoAntesLectura: 100,
     }*/
    const reporteZLoad = ref(false)

    const ReportePreZ = () => {
      reporteZLoad.value = true

      ComandaService.validarReporteZ()
        .then(response => {
          try {
            if (response.data.estatus == 'true') {
              ConfirmacionUsuarioRef.value.abrir(
                { id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z },
                ReporteZ,
                '',
                () => {
                  reporteZLoad.value = false
                },
              )
            } else {
              Vue.swal({
                icon: 'error',
                title: `Disculpa...`,
                text: `${response.data.mensaje}`,
              })
              reporteZLoad.value = false
            }
          } catch (error) {
            Vue.swal({
              icon: 'error',
              title: `Oops...`,
              text: `hubo un error: ${error}`,
            })
            reporteZLoad.value = false
          }
        })
        .catch(error => {
          Vue.swal({
            icon: 'error',
            title: `Oops...`,
            text: `hubo un error: ${error}`,
          })
          reporteZLoad.value = false
        })
    }
    const ReporteZ = () => {
      reporteZLoad.value = true

      if (validarAcciones(acciones.DASHBOARD_COMANDA_REPORTE_Z)) {
        imprimirZ()
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',

          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  throw new Error('Usuario o clave inavalida, o el usuario no tiene permiso')
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'ReporteZ',
                })
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              imprimirZ()
            } else if (result.dismiss === 'cancel') {
              reporteZLoad.value = false
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              reporteZLoad.value = false
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
            }
            reporteZLoad.value = false
          }
        })
      }
    }

    const imprimirResumenPago = () => {
      ImpresoraService.ImprimirConfiguracion(impresora)
        .then(response => {
          try {
            Vue.swal({
              icon: 'success',
              title: `ok`,
              text: response,
            })
          } catch (error) {
            Vue.swal({
              icon: 'error',
              title: `Oops...`,
              text: `hubo un error: ${error}`,
            })
            reporteZLoad.value = false
          }
        })
        .catch(error => {
          Vue.swal({
            icon: 'error',
            title: `Oops...`,
            text: `hubo un error: ${error}`,
          })
        })
    }
    const imprimirInfoImpresora = () => {
      ImpresoraService.ImprimirConfiguracion(impresora)
        .then(response => {
          try {
            Vue.swal({
              icon: 'success',
              title: `ok`,
              text: response,
            })
          } catch (error) {
            Vue.swal({
              icon: 'error',
              title: `Oops...`,
              text: `hubo un error: ${error}`,
            })
            reporteZLoad.value = false
          }
        })
        .catch(error => {
          Vue.swal({
            icon: 'error',
            title: `Oops...`,
            text: `hubo un error: ${error}`,
          })
        })
    }
    const reverseString = str => {
      // Step 1. Use the split() method to return a new array
      var splitString = str.split('') // var splitString = "hello".split("");
      // ["h", "e", "l", "l", "o"]

      // Step 2. Use the reverse() method to reverse the new created array
      var reverseArray = splitString.reverse() // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
      // ["o", "l", "l", "e", "h"]

      // Step 3. Use the join() method to join all elements of the array into a string
      var joinArray = reverseArray.join('') // var joinArray = ["o", "l", "l", "e", "h"].join("");
      // "olleh"

      //Step 4. Return the reversed string
      return joinArray.toString() // "olleh"
    }
    const stringToNumber = dato => {
      let revers = reverseString(dato.toString())
      let decimal = reverseString(revers.substring(0, 2))
      let entero = revers.substring(2)
      entero = reverseString(entero)

      let valor = parseInt(entero).toString() + '.' + decimal

      return Number(valor).toFixed(2)
    }
    const imprimirZ = () => {
      Vue.swal({
        icon: 'info',
        text: 'Esta seguro que quiere realizar el reporte z',
        showCancelButton: true,
        confirmButtonText: 'Si',
        showLoaderOnConfirm: true,
        cancelButtonText: 'no',
        preConfirm: login => {
          if (!(store.state.impresoraFiscalSelect.id > 0 && store.state.impresoraFiscalTipo == 'REMOTO')) {
            return ImpresoraService.reporteZ(impresora)
              .then(response => {
                console.log('response', response.data)
                try {
                  if (response !== '') {
                    const resultSplit = response.data.split(/\r\n|\r|\n/)
                    console.log('resultSplit', resultSplit)
                    //const resultSplit = result.split(/\r\n|\r|\n/)

                    let jsonZetas = {
                      numero_reporte_z: Number(resultSplit[0]),
                      fecha_reporte_z: '20' + resultSplit[1],
                      hora_reporte_z: resultSplit[2],
                      numero_ultima_factura_emitida: Number(resultSplit[3]),
                      fecha_ultima_factura: '20' + resultSplit[4],
                      hora_ultima_factura: resultSplit[5],
                      numero_ultima_nota_de_credito: Number(resultSplit[6]),
                      numero_ultima_nota_de_debito: Number(resultSplit[7]),
                      numero_ultimo_documento_no_fiscal: Number(resultSplit[8]),
                      exento_venta: stringToNumber(resultSplit[9]),
                      bi_general_venta: stringToNumber(resultSplit[10]),
                      iva_general_venta: stringToNumber(resultSplit[11]),
                      bi_reducido_venta: stringToNumber(resultSplit[12]),
                      iva_reducido_venta: stringToNumber(resultSplit[13]),
                      bi_adicional_venta: stringToNumber(resultSplit[14]),
                      iva_adicional_venta: stringToNumber(resultSplit[15]),
                      exento_nota_de_debito: stringToNumber(resultSplit[16]),
                      bi_general_nota_de_debito: stringToNumber(resultSplit[17]),
                      iva_general_nota_de_debito: stringToNumber(resultSplit[18]),
                      bi_reducido_nota_de_debito: stringToNumber(resultSplit[19]),
                      iva_reducido_nota_de_debito: stringToNumber(resultSplit[20]),
                      bi_adicional_nota_de_debito: stringToNumber(resultSplit[21]),
                      iva_adicional_nota_de_debito: stringToNumber(resultSplit[22]),
                      exento_nota_de_credito: stringToNumber(resultSplit[23]),
                      bi_general_nota_de_credito: stringToNumber(resultSplit[24]),
                      iva_general_nota_de_credito: stringToNumber(resultSplit[25]),
                      bi_reducido_nota_de_credito: stringToNumber(resultSplit[26]),
                      iva_reducido_nota_de_credito: stringToNumber(resultSplit[27]),
                      bi_adicional_nota_de_credito: stringToNumber(resultSplit[28]),
                      iva_adicional_nota_de_credito: stringToNumber(resultSplit[29]),
                      bi_igtf: stringToNumber(0),
                      impuesto_percibido_ventas: stringToNumber(0),
                      impuesto_percibido_debito: stringToNumber(0),
                      impuesto_percibido_credito: stringToNumber(0),
                      valor_igtf: stringToNumber(0),
                      bi_igtf_nota_de_credito: stringToNumber(0),
                      valor_igtf_nota_de_credito: stringToNumber(0),
                      bi_igtf_nota_de_debito: stringToNumber(0),
                      valor_igtf_nota_de_debito: stringToNumber(0),
                      serial_impresora: 'Z7C7022063',
                      datos_impresora: response.data,
                      id_caja: store.state.user.caja.id,
                      id_usuario: store.state.user.id,
                      id_usuario_autorizacion: store.state.user.id,
                    }
                    if (resultSplit.length > 31) {
                      jsonZetas = {
                        ...jsonZetas,
                        bi_igtf: stringToNumber(resultSplit[30]),
                        impuesto_percibido_ventas: stringToNumber(resultSplit[31]),
                        impuesto_percibido_debito: stringToNumber(resultSplit[32]),
                        impuesto_percibido_credito: stringToNumber(resultSplit[33]),
                        valor_igtf: stringToNumber(resultSplit[34]),
                        bi_igtf_nota_de_credito: stringToNumber(resultSplit[35]),
                        valor_igtf_nota_de_credito: stringToNumber(resultSplit[36]),
                        bi_igtf_nota_de_debito: stringToNumber(resultSplit[37]),
                        valor_igtf_nota_de_debito: stringToNumber(resultSplit[38]),
                      }
                    }
                    console.log('jsonZetas', jsonZetas)

                    guardarZ(jsonZetas)
                  } else {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `no huvo respuesta de la impresora`,
                    })
                  }
                } catch (error) {
                  console.log('error zeta', error)
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                  reporteZLoad.value = false
                }

                return true
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                reporteZLoad.value = false
                return false
              })
          } else {
            return ComandaService.ImprimirFiscal({
              factura: null,
              devolucion: false,
              abrir_gaveta: false,
              ind_igtf: false,
              id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
              id_caja: store.state.user.caja.id,
              id_comanda: -2,
            })
              .then(response => {
                if (response.data.mensaje == 'guardado con exito') {
                  store.commit('setAlert', {
                    message: 'Se envio a la impresora fiscal ',
                    type: 'success',
                  })
                  return true
                } else {
                  store.commit('setAlert', {
                    message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                    type: 'error',
                    error: {
                      response: response.data,
                    },
                    funcion: 'ImprimirFiscal',
                  })
                  return false
                }
              })
              .catch(error => {
                store.commit('setAlert', {
                  message: 'Error al enviar a fiscalizar ' + error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'cerrarCuenta',
                })
                return false
              })
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        console.log(result)
        if (result.isConfirmed) {
          reporteZLoad.value = false
        } else if (result.dismiss === 'cancel') {
          reporteZLoad.value = false
        }
      })
    }

    const guardarZ = datos => {
      ComandaService.nuevaZeta({ ...datos, serial_impresora: serialFiscal.value })
        .then(response => {
          try {
            if (response.data.mensaje == 'ZETA GUARDADA CON EXITO') {
              Vue.swal({
                icon: 'success',
                title: `Exito.`,
                text: `${response.data.mensaje}`,
              })
            } else {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error al guardar en bases de datos, :  ${response.data.mensaje}`,
              })
            }
            reporteZLoad.value = false
          } catch (error) {
            Vue.swal({
              icon: 'error',
              title: `Oops...`,
              text: `hubo un error: ${error}`,
            })
            reporteZLoad.value = false
          }
        })
        .catch(error => {
          Vue.swal({
            icon: 'error',
            title: `Oops...`,
            text: `hubo un error: ${error}`,
          })
          reporteZLoad.value = false
        })
    }

    const reporteXLoad = ref(false)

    const ReporteX = () => {
      reporteXLoad.value = true
      ConfirmacionUsuarioRef.value.abrir(
        { id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_X },
        imprimirX,
        '',
        () => {
          reporteXLoad.value = false
        },
      )
    }
    const serialFiscal = ref('')
    const serialFiscalCargando = ref(false)

    const obtenerSerialImpresoraFiscal = () => {
      serialFiscal.value = ''
      serialFiscalCargando.value = true

      if (store.state.impresoraFiscalTipo == 'LOCAL') {
        ImpresoraService.obtenerInfo(impresora)
          .then(response => {
            console.log('obtenerSerialImpresoraFiscal', response)
            serialFiscal.value = response.data.split('|')[0]
          })
          .catch(error => {
            serialFiscal.value = ''
            store.commit('setAlert', {
              message: 'Error al conectar con el servicio de la impresora fiscal',
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'obtenerSerialImpresoraFiscal',
            })
          })
          .finally(() => {
            serialFiscalCargando.value = false
          })
      } else {
        serialFiscal.value = store.state.impresoraFiscalSelect.serial
      }
    }

    const ArqueoCaja = () => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA)) {
        imprimirArqueoCaja()
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',

          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  throw new Error('Usuario o clave inavalida, o el usuario no tiene permiso')
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'ArqueoCaja',
                })
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              imprimirArqueoCaja()
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
            }
            reporteXLoad.value = false
          }
        })
      }
    }
    const ArqueoCajaDetallado = () => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA)) {
        imprimirArqueoCajaDetallado()
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',

          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  throw new Error('Usuario o clave inavalida, o el usuario no tiene permiso')
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'ArqueoCaja',
                })
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              imprimirArqueoCaja()
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
            }
            reporteXLoad.value = false
          }
        })
      }
    }

    const abrirModalComandaAntigua = () => {
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>Ingresa el numero de comanda a buscar  </h3>' +
          ` ` +
          ` <input  id="swal-motivo-anulacion"   class="swal2-input" placeholder="Numero Comanda">  `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: async () => {
          const numero = document.getElementById('swal-motivo-anulacion').value

          if (numero != undefined && numero != '' && numero != null && !isNaN(numero)) {
            comandaSelect.value = { id: numero }
            openModalComanda.value = true
            return true
          } else {
            Vue.swal.showValidationMessage(`Debe proporcionar un numero valido`)
          }
        },
        allowOutsideClick: () => {
          !Vue.swal.isLoading()

          GetFiscalizando(false)
        },
      }).then(result => {
        GetFiscalizando(false)
      })
    }

    const AbrirComandaAntigua = () => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
        abrirModalComandaAntigua()
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',

          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  throw new Error('Usuario o clave inavalida, o el usuario no tiene permiso')
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'ArqueoCaja',
                })
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              abrirModalComandaAntigua()
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
            }
            reporteXLoad.value = false
          }
        })
      }
    }

    const getNumeroComanda = datos => {
      console.log(datos)
      if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
        filtroComandaTemp.value = datos.id
      }
    }

    const ModalPuntosVentasActivosRef = ref()

    const AbrirArqueoPuntosVentas = _dato => {
      console.log(_dato)
      ModalPuntosVentasActivosRef.value.abrir(_dato)
    }

    const imprimirArqueoCaja = _dato => {
      ModalArqueoCajaRef.value.abrir(_dato)
      /*
      Vue.swal({
        icon: 'info',
        text: 'Esta seguro que quiere realizar el arqueo de caja',
        showCancelButton: true,
        confirmButtonText: 'Si',
        showLoaderOnConfirm: true,
        cancelButtonText: 'no',

        preConfirm: login => {
          return MaestroService.imprimirArqueoCajaTikera({
            id_grupo_impresora_pc: store.state.tikera.id,
            id_caja: store.state.user.caja.id,
          })
            .then(response => {
              try {
                Vue.swal({
                  icon: 'success',
                  title: `Exito.`,
                  text: `Se envio el aqueo de caja a la impresora.`,
                })
              } catch (error) {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
              }

              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })

              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        console.log(result)

        if (result.isConfirmed) {
          reporteXLoad.value = false
        } else if (result.dismiss === 'cancel') {
          reporteXLoad.value = false
        }
      })*/
    }
    const imprimirArqueoCajaDetallado = () => {
      Vue.swal({
        icon: 'info',
        text: 'Esta seguro que quiere realizar el arqueo de caja',
        showCancelButton: true,
        confirmButtonText: 'Si',
        showLoaderOnConfirm: true,
        cancelButtonText: 'no',

        preConfirm: login => {
          return MaestroService.imprimirArqueoCajaTikeraDetallado({
            id_grupo_impresora_pc: store.state.tikera.id,
            id_caja: store.state.user.caja.id,
          })
            .then(response => {
              try {
                Vue.swal({
                  icon: 'success',
                  title: `Exito.`,
                  text: `Se envio el aqueo de caja a la impresora.`,
                })
              } catch (error) {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
              }

              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })

              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        console.log(result)

        if (result.isConfirmed) {
          reporteXLoad.value = false
        } else if (result.dismiss === 'cancel') {
          reporteXLoad.value = false
        }
      })
    }

    const notifySonido = (title, body) => {
      let url = null
      const _valor = configuraciones.value.find(item => item.id == 6)
      if (_valor?.valor == '1') {
        url = require('@/assets/sonidos/notificacion-1.mp3')
      } else if (_valor?.valor == '2') {
        url = require('@/assets/sonidos/notificacion-2.mp3')
      } else if (_valor?.valor == '3') {
        url = require('@/assets/sonidos/notificacion-karting.mp3')
      } else {
        url = require('@/assets/sonidos/notificacion-1.mp3')
      }

      var audio = new Audio(url)
      audio.play()
    }

    const ModalEntraSalidaCajaAbrir = _dato => {
      ModalEntraSalidaCajaRef.value.abrir(_dato)
    }

    const imprimirX = () => {
      ModalConfirmacionRef.value.abrir({
        subTitulo: 'Esta seguro que quiere realizar el arqueo de caja',
        icon: mdiInformation,

        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcion: async () => {
            if (!(store.state.impresoraFiscalSelect.id > 0 && store.state.impresoraFiscalTipo == 'REMOTO')) {
              await ImpresoraService.reporteX(impresora)
                .then(response => {
                  try {
                    Vue.swal({
                      icon: 'success',
                      title: `Exito.`,
                      text: `Se envio el reporte X a la impresora. ${response.data}`,
                    })
                    reporteXLoad.value = false
                  } catch (error) {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `hubo un error: ${error}`,
                    })
                    reporteXLoad.value = false
                  }
                })
                .catch(error => {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                  reporteXLoad.value = false
                })
            } else {
              await ComandaService.ImprimirFiscal({
                factura: null,
                devolucion: false,
                abrir_gaveta: false,
                ind_igtf: false,
                id_impresora_fiscal: store.state.impresoraFiscalSelect.id,
                id_caja: store.state.user.caja.id,
                id_comanda: -1,
              })
                .then(response => {
                  if (response.data.mensaje == 'guardado con exito') {
                    store.commit('setAlert', {
                      message: 'Se envio a la impresora fiscal ',
                      type: 'success',
                    })
                  } else {
                    store.commit('setAlert', {
                      message: 'Error al enviar a fiscalizar ' + response.data.mensaje,
                      type: 'error',
                      error: {
                        response: response.data,
                      },
                      funcion: 'ImprimirFiscal',
                    })
                  }
                })
                .catch(error => {
                  store.commit('setAlert', {
                    message: 'Error al enviar a fiscalizar ' + error,
                    type: 'error',
                    error: {
                      ...error,
                      response: error?.response?.data,
                    },
                    funcion: 'cerrarCuenta',
                  })
                })
            }
            ModalConfirmacionRef.value.cerrar()
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {
            reporteXLoad.value = false
          },
        },
      })

      /*
      Vue.swal({
        icon: 'info',
        text: 'Esta seguro que quiere realizar el reporte x',
        showCancelButton: true,
        confirmButtonText: 'Si',
        showLoaderOnConfirm: true,
        cancelButtonText: 'no',

        preConfirm: login => {
          return ImpresoraService.reporteX(impresora)
            .then(response => {
              try {
                Vue.swal({
                  icon: 'success',
                  title: `Exito.`,
                  text: `Se envio el reporte X a la impresora. ${response.data}`,
                })
                reporteXLoad.value = false
              } catch (error) {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
                reporteXLoad.value = false
              }

              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })
              reporteXLoad.value = false
              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        console.log(result)

        if (result.isConfirmed) {
          reporteXLoad.value = false
        } else if (result.dismiss === 'cancel') {
          reporteXLoad.value = false
        }
      })*/
    }

    const keyboardEvent = e => {
      let tecla = e.which
      if (e.altKey) {
        // Evitar el comportamiento por defecto del nevagador:
        e.preventDefault()
        e.stopPropagation()
        console.log(e.which)
        // Mostrar el resultado de la combinación de las teclas:
        if (tecla === 70) {
          if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA) && !isDialogVisible.value) {
            AbrirComandaAntigua()
            return false
          }
        }
        if (tecla === 65) {
          if (validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) && !isDialogVisible.value) {
            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA },
              imprimirArqueoCaja,
              {},
            )
            return false
          }
        }
        if (tecla === 84) {
          if (!isDialogVisible.value) {
            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_ENTRADA_SALIDA_CAJA },
              ModalEntraSalidaCajaAbrir,
              {},
            )
            return false
          }
        }
        if (tecla === 73) {
          if (!isDialogVisible.value) {
            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA },
              imprimirInfoImpresora,
              {},
            )
            return false
          }
        }

        if (tecla === 68) {
          if (validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) && !isDialogVisible.value) {
            // ArqueoCajaDetallado()

            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA },
              imprimirArqueoCajaDetallado,
              '',
            )
            return false
          }
        }

        if (tecla === 80) {
          if (validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) && !isDialogVisible.value) {
            // ArqueoCajaDetallado()

            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_IMPRIMIR_ARQUEO_CAJA },
              AbrirArqueoPuntosVentas,
              '',
            )
            return false
          }
        }
      }
    }
    const logoI = process.env.VUE_APP_IMG_LOGO
    return {
      mdiInformation,
      mdiRefresh,
      mdiMagnify,
      mdiChevronDown,
      mdiCallMerge,
      mdiCallSplit,
      mdiAlphaZCircle,
      mdiSwapHorizontal,
      mdiAlphaXCircle,
      mdiCashRegister,
      mdiTransitTransfer,
      mdiFood,
      mdiChevronUp,
      mdiExitToApp,
      mdiChevronDown,
      mdiAccountLock,
      mdiMusicCircleOutline,
      mdiFilterVariant,
      mdiDotsSquare,
      mdiEmoticonCryOutline,
      ListarComanda,
      comandas,
      FiltrarComanda,
      filtoComanda,
      filtoComandaEstadoPago,
      EstadosPago,
      GetVerComanda,
      RefModalComanda,
      mesas,
      formasPagos,
      mesoneros,
      monedas,
      configuraciones,
      fiscalizando,
      GetFiscalizando,
      GetActualizarEstatusComanda,
      validarAcciones,
      acciones,
      reporteXLoad,
      ReporteX,
      reporteZLoad,
      ReporteZ,
      ReportePreZ,
      imprimirInfoImpresora,
      ArqueoCaja,
      EstadosPagoFiltro,
      showAll,
      tabs,
      store,
      totalesGeneral,
      modalTransferirMasiva,
      abrirModalTransferirMasiva,
      page_number,
      no_of_pages,
      no_comanda,
      abrirModalAsignarCaja,
      modalAsignarCaja,
      ActualizarForzado,
      actualizandoForzado,
      getNumeroComanda,
      switch1,
      serialFiscal,
      serialFiscalCargando,
      obtenerSerialImpresoraFiscal,
      CerrarSesion,
      GetOpenModalComanda,
      openModalComanda,
      comandaSelect,
      stateSocket,
      suscribirSockets,
      CargarDatosGenerales,
      comandasPuesto,
      FiltrarComandaPuesto,
      page_number_puesto,
      no_of_pages_puesto,
      no_comanda_puesto,
      filtroEstadoPuesto,
      AbrirComandaAntigua,
      GetisDialogVisible,
      isDialogVisible,
      ArqueoCajaDetallado,
      GetConfirmacionUsuario,
      ConfirmacionUsuarioRef,
      keyboardEvent,
      filtroComandaTemp,
      filtroComandaCargando,
      ModalConfirmacionRef,
      GetModalConfirmacion,
      notifySonido,
      ModalEntraSalidaCajaRef,
      ModalEntraSalidaCajaAbrir,
      ModalArqueoCajaRef,
      ModalPuntosVentasActivosRef,
      random,
      ListarComandaCola,
      estadoFormaPago,
      dialogZona,
      cargarZonas,
      zonas,
      zonasSelect,
      asignarZona,
      configuraciones_error,
      dialogCargandoDatos,

      mdiArrowCollapseAll,
      mdiArrowExpandAll,
      contraerBtn,
      cargarProductos,
      modalProducto,
      abrirProductos,
      productosLista,
      config,
      logoI,
      productosListaFiltro,
      filtroProducto,
      cambiarEstadoProducto,
      puestoSelect,
      filtroComandaPuesto,
      mdiFileChart,
      modalReporteCierreVentasPuesto,
      funciones
    }
  },
}
</script>
<style scoped>
.swal2-html-container {
  z-index: 10000;
}

.ir-arriba {
  z-index: 100;
  position: absolute;
  top: 20px;
  right: 0%;
}

.img-producto {
  height: 140px;
  object-fit: cover;
}

.text-img-catgoria {
  top: 0rem;
  padding-top: 35px;
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}

.text-img {
  top: 0rem;
  padding: 3px;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}

.text-img2 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.text-img3 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.8);
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

.memberpricing-bg {
  position: relative;
}
</style>
