
<template >
  <span>
    <v-hover v-slot="{ hover }">
      <v-card class="primary" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="CargaInicial(null)">
        <v-card-text class="text-center py-1 white--text">
          <v-icon color="white">{{ icons.mdiPlus }} </v-icon>

          <small v-if="indLabel">
            <br />
            <strong>Nueva Comanda</strong>
          </small>
        </v-card-text>
      </v-card>
    </v-hover>
    <v-dialog
      persistent
      v-model="isDialogVisible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialogo"
    >
      <div>
        <v-dialog v-model="loadGuardandoComanda" persistent width="300">
          <div class="text-center">
            <v-card color="primary" v-if="!loadGuardandoComandaSuccess">
              <v-card-text class="text-center">
                <span class="white--text" v-if="!loadGuardandoComandaSuccess">Guardando, Espere un momento...</span>
                <v-progress-linear
                  v-if="!loadGuardandoComandaSuccess"
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>

            <div class="success-icon" v-if="loadGuardandoComandaSuccess">
              <div class="success-icon__tip"></div>
              <div class="success-icon__long"></div>
            </div>
          </div>
        </v-dialog>
        <v-container fluid>
          <v-row>
            <v-col v-if="$vuetify.breakpoint.smAndUp" md="8" sm="7" cols="12" class="pa-0">
              <v-tabs-items v-model="tab2" touchless>
                <v-tab-item value="tab2-1">
                  <v-toolbar dark color="primary" class="pa-0">
                    <v-btn icon dark @click="isDialogVisible = false">
                      <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                    </v-btn>

                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="filtroProductoTemp"
                            hide-details
                            :prepend-icon="icons.mdiMagnify"
                            single-line
                            placeholder="Buscar producto..."
                            append-icon=""
                            ref="filtroProductoRef"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="auto" v-if="!filtroProductoCargando">
                          <v-checkbox hide-details v-model="checkboxFiltroProducto" label="Todos"></v-checkbox>
                        </v-col>
                        <v-col cols="auto" v-if="filtroProductoCargando">
                          <v-progress-circular :size="20" indeterminate color="white"> </v-progress-circular>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-toolbar>
                  <div class="contenedor-productos">
                    <grid-productos
                      :monedas="monedas"
                      :filtroProducto="filtroProducto"
                      :puesto="PuestoSelect"
                      :puestos="DatosPuestos"
                      :checkboxFiltroProducto="checkboxFiltroProducto"
                      @GetProducto="GetProducto"
                    >
                    </grid-productos>
                  </div>
                  <div class="contenedor-puesto">
                    <grid-puestos :puestos="DatosPuestos" @GetPuesto="GetPuesto"></grid-puestos>
                  </div>
                </v-tab-item>
                <v-tab-item
                  value="tab2-2"
                  :disabled="
                    !validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ||
                    comanda.productos.length == 0 ||
                    comanda.cliente == ''
                  "
                >
                  <v-toolbar dark color="primary" class="pa-0">
                    <v-btn icon dark @click="isDialogVisible = false">
                      <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <div class="contenedor-productos-pago">
                    <lista-producto-pago
                      v-if="isDialogVisible"
                      @GetTotales="GetTotales"
                      :comanda="comanda"
                      :monedas="monedas"
                      @GetCambiarComision="GetCambiarComision"
                      @GetModificacionDetalles="GetModificacionDetalles"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
            <v-col md="4" sm="5" cols="12" class="pa-0">
              <div class="contenedor-detalle text--center">
                <v-container class="header-producto4">
                  <v-row>
                    <v-col cols="2" v-if="!$vuetify.breakpoint.smAndUp">
                      <v-btn icon dark @click="isDialogVisible = false">
                        <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="10" sm="12">
                      <v-item-group v-model="tabSelect" mandatory>
                        <v-row>
                          <v-col class="px-0 mx-0">
                            <v-item v-slot="{ active, toggle }">
                              <v-card :color="active ? '' : 'primary'" class="d-flex align-center" @click="toggle">
                                <v-scroll-y-transition>
                                  <div
                                    class="flex-grow-1 text-center"
                                    :class="!active ? 'white--text' : 'primary--text'"
                                  >
                                    <span>Productos</span>
                                    <br />
                                    <v-icon :color="!active ? 'white' : 'primary'">{{ icons.mdiCartPlus }}</v-icon>
                                  </div>
                                </v-scroll-y-transition>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col class="px-0 mx-0">
                            <v-item v-slot="{ active, toggle }">
                              <v-card :color="active ? '' : 'primary'" class="d-flex align-center" @click="toggle">
                                <v-scroll-y-transition>
                                  <div
                                    class="flex-grow-1 text-center"
                                    :class="!active ? 'white--text' : 'primary--text'"
                                  >
                                    <span>Cliente</span>
                                    <br />
                                    <v-icon :color="!active ? 'white' : 'primary'">{{ icons.mdiAccount }}</v-icon>
                                  </div>
                                </v-scroll-y-transition>
                              </v-card>
                            </v-item>
                          </v-col>
                          <v-col class="px-0 mx-0" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA)">
                            <v-item v-slot="{ active, toggle }">
                              <v-card
                                :disabled="
                                  (comanda.cliente == '' && funciones.configuracionSelect(12) == '2') ||
                                  (comanda.id_cliente == -1 && funciones.configuracionSelect(10) == '2') ||
                                  (comanda.mesa == 0 &&
                                    store.state.user.caja.id == undefined &&
                                    funciones.configuracionSelect(9) == '2')
                                "
                                :color="active ? '' : 'primary'"
                                class="d-flex align-center"
                                @click="toggle"
                              >
                                <v-scroll-y-transition>
                                  <div
                                    class="flex-grow-1 text-center"
                                    :class="!active ? 'white--text' : 'primary--text'"
                                  >
                                    <span>Pago</span>
                                    <br />
                                    <v-icon :color="!active ? 'white' : 'primary'">{{ icons.mdiCashRegister }}</v-icon>
                                  </div>
                                </v-scroll-y-transition>
                              </v-card>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-col>
                  </v-row>
                </v-container>
                <div class="contenedor-detalle-lista">
                  <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                      <lista-detalles-productos
                        :tabSelect="tabSelect"
                        :comanda="comanda"
                        :mesoneros="mesoneros"
                        :loadGuardandoComanda="loadGuardandoComanda"
                        @GetTabSelect="GetTabSelect"
                        @GetModificarProducto="GetModificarProducto"
                        @GetGuardarComanda="GetGuardarComanda"
                        @GetEliminarProducto="GetEliminarProducto"
                        @GetCambiarComision="GetCambiarComision"
                        @GetCambiarAgregarProducto="GetCambiarAgregarProducto"
                      >
                      </lista-detalles-productos>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                      <lista-detalles-productos-detalle
                        @GetAtrasProductoDetalle="GetAtrasProductoDetalle"
                        @GetAgregarProducto="GetAgregarProducto"
                        :mesoneros="mesoneros"
                        :producto="ProductoSelect"
                        ref="listaDetallesProductosDetalleRef"
                      ></lista-detalles-productos-detalle>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                      <lista-detalles-cliente
                        :tabSelect="tabSelect"
                        :comanda="comanda"
                        :mesoneros="mesoneros"
                        :mesas="mesas"
                        :loadGuardandoComanda="loadGuardandoComanda"
                        :estadoFormaPago="estadoFormaPago"
                        @GetTabSelect="GetTabSelect"
                        @GetClienteFull="GetClienteFull"
                        @GetDatosCliente="GetDatosCliente"
                        @GetGuardarComanda="GetGuardarComanda"
                        @GetCambiarComision="GetCambiarComision"
                      >
                      </lista-detalles-cliente>
                    </v-tab-item>

                    <v-tab-item
                      value="tab-4"
                      :disabled="
                        !validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ||
                        comanda.productos.length == 0 ||
                        comanda.cliente == ''
                      "
                    >
                      <lista-detalles-pago
                        :tabSelect="tabSelect"
                        :comanda="comanda"
                        :monedas="monedas"
                        :configuraciones="configuraciones"
                        @GetTabSelect="GetTabSelect"
                        @GetDatosCliente="GetDatosCliente"
                        @GetPagos="GetPagos"
                        @AgregarPropinaVuelto="AgregarPropinaVuelto"
                        @AgregarPropina="AgregarPropina"
                        @EliminarPropina="EliminarPropina"
                        @GetEliminarPagos="GetEliminarPagos"
                        @GetGuardarComandaPago="GetGuardarComandaPago"
                        @GetCambiarComision="GetCambiarComision"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-dialog v-model="dialog2" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-tabs-items v-model="tab2" touchless>
          <v-tab-item value="tab2-1">
            <v-toolbar dark color="primary" class="pa-0">
              <v-btn icon dark @click="isDialogVisible = false">
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
              </v-btn>

              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="filtroProductoTemp"
                      hide-details
                      :prepend-icon="icons.mdiMagnify"
                      single-line
                      placeholder="Buscar producto..."
                      append-icon=""
                      ref="filtroProductoRef"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" v-if="filtroProductoCargando">
                    <v-progress-circular :size="20" indeterminate color="white"> </v-progress-circular>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
            <div class="contenedor-productos">
              <grid-productos
                :monedas="monedas"
                :filtroProducto="filtroProducto"
                :puesto="PuestoSelect"
                @GetProducto="GetProducto"
                :puestos="DatosPuestos"
              >
              </grid-productos>
            </div>
            <div class="contenedor-puesto">
              <grid-puestos :puestos="DatosPuestos" @GetPuesto="GetPuesto"></grid-puestos>
            </div>
          </v-tab-item>
          <v-tab-item
            value="tab2-2"
            :disabled="
              !validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ||
              comanda.productos.length == 0 ||
              comanda.cliente == ''
            "
          >
            <v-toolbar dark color="primary" class="pa-0">
              <v-btn icon dark @click="isDialogVisible = false">
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="contenedor-productos-pago">
              <lista-producto-pago
                @GetTotales="GetTotales"
                :comanda="comanda"
                :monedas="monedas"
                @GetCambiarComision="GetCambiarComision"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-dialog>

      <ConfirmacionUsuario @GetConfirmacionUsuario="GetConfirmacionUsuario" ref="ConfirmacionUsuarioRef" />
      <ModalConfirmacion ref="ModalConfirmacionRef" />
    </v-dialog>
  </span>
</template>

<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import { mdiArrowLeft, mdiMagnify, mdiAccount, mdiCartPlus, mdiCashRegister, mdiInformation, mdiPlus } from '@mdi/js'
import ListaDetallesProductos from './ListaDetallesProductos.vue'
import GridProductos from './GridProductos.vue'
import GridPuestos from './GridPuestos.vue'
import axios from '@/api'
import ListaDetallesProductosDetalle from './ListaDetallesProductosDetalle.vue'
import ListaDetallesCliente from './ListaDetallesCliente.vue'
import ListaDetallesPago from './ListaDetallesPago.vue'
import ListaProductoPago from './ListaProductoPago.vue'
import ProductosService from '@/api/servicios/ProductosService.js'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
import { acciones } from '@/modulos'
import Vue from 'vue'
import config from '@/api/config'
import SeguridadService from '@/api/servicios/SeguridadService'
import ConfirmacionUsuario from '@/components/ConfirmacionUsuario.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
import funciones from '@/funciones/funciones'
import MaestroService from '@/api/servicios/MaestroService'
import EstacionamientoServices from '@/api/servicios/EstacionamientoServices'
import Fiscalizar from '@/funciones/fiscalizar'
export default {
  watch: {
    isDialogVisible: function (nuew, old) {
      this.GetisDialogVisible(nuew)
    },
    '$store.state.puestoProductos': function (nuew, old) {
      this.CargarDatosProductos()
    },
  },
  components: {
    ListaDetallesProductos,
    GridProductos,
    GridPuestos,
    ListaDetallesProductosDetalle,
    ListaDetallesCliente,
    ListaDetallesPago,
    ListaProductoPago,
    ConfirmacionUsuario,
    ModalConfirmacion,
  },
  props: {
    mesas: Array,
    formasPagos: Array,
    mesoneros: Array,
    monedas: Array,
    configuraciones: Array,
    estadoFormaPago: Array,
    serialFiscal: String,
    indLabel: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    //inicializacion
    const checkboxFiltroProducto = ref(false)
    const dialog2 = ref(false)
    const tabSelectLocal = ref(0)
    const configuracion = ref({
      igtf: 0,
      iva_exento: false,
    })
    const ModalConfirmacionRef = ref(null)
    watch(props, () => {
      configuracion.value.igtf = funciones.configuracionSelect(2)
    })

    const configuracionesFun = () => {
      return {
        igtf: !store.state.user.caja.ind_igtf ? 0 : Number(funciones.configuracionSelect(2)),
      }
    }

    const ConfirmacionUsuarioRef = ref(null)

    const GetConfirmacionUsuario = (_funcion, _datos, _funcionCancel, _cancel) => {
      if (_cancel) {
        _funcionCancel()
      } else {
        _funcion(_datos)
      }
    }

    const GetisDialogVisible = nuew => {
      context.emit('GetisDialogVisible', nuew)
    }
    const facturaInit = {
      nFactura: '1-2',
      cajero: 'hola',
      ctdProductos: 2,
      montoRecDesc: '0',
      isPorcentajeRecDesc: true,
      isRecDesc: true,
      cliente: {
        nombre: 'Angel Castillo',
        cedula: '123456789',
      },
      producto: [
        {
          barra: '759103187465',
          descripcion: 'agua',
          pvp: 15,
          cantidad: 1,
          IVA: 8,
          descuento: '10.00',
          serial: '123',
        },
      ],
      pagosFacturas: [
        {
          descripcion: 'usd',
          monto: 15,
          tasa: 5.35,
        },
      ],
      devolucion: {
        nFacturaAfectada: '1-1',
        numControlContraDev: 13,
        fechaFactContDev: '2022-07-14T13:45:00.000Z',
        serialFactContDev: '13422',
      },
      impresora: {
        modeloImpresora: 1,
        puerto: 99,
        bAudios: 9600,
        paridad: 2,
        datos: 8,
        stopBits: 1,
        handshake: 0,
        maxTiempoEspera: 5000,
        tiempoAntesLectura: 100,
      },
    }

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }

    watch(tabSelectLocal, () => {
      if (tabSelectLocal.value != props.tabSelect) {
        console.log(tabSelectLocal.value)
        GetTabSelect(tabSelectLocal.value)
      }
    })
    const productoComandaINIT = {
      id: 0,
      cantidad: 1,
      estado_despacho: 0,
      extras: [],
      total_extra: 0,
      nuevo: true,
      observacion: '',
      precio_total: 0,
      precio_real: 0,
      descuento: 0,
      por_descuento: 0,
      ind_servicio_detalle: true,
      producto: {
        activo_venta: true,
        categoria_nombre: 'COMIDAS',
        checkbox: false,
        costo: 1,
        descripcion: 'PARRILLA DE POLLO',
        estado: 1,
        estado_descripcion: 'ACTIVO',
        extra: false,
        extras: null,
        id: 619,
        id_categoria: 1,
        id_producto_tipo: 1,
        id_puesto: 14,
        img: 'PRODUCTO_FAIB873SFG.JPEG',
        ingredientes: null,
        nombre: 'PARRILLA DE POLLO',
        precio_venta: 7.5,
        ptipo_descripcion: 'PRODUCTO PRINCIPAL',
        sub_categoria: 10,
        sub_categoria_nombre: 'PINCHOS',
        und_caja: 0,
        unidad_medida: 5,
        utilidad: 0.3,
      },
    }
    const comandaINIT = {
      id_cliente: -1,
      cliente: store.state.user.caja.id == undefined ? '' : 'cliente',
      descripcion_cliente: '',
      por_comision: !(funciones.configuracionSelect(8) == null || store.state.user.caja.ind_servicio == false)
        ? Number(funciones.configuracionSelect(8))
        : 0,
      estado_despacho: false,
      estado_pago: 7,
      forma_pago: 20,
      estado_forma_pago: 20,
      guardando: 0,
      id: 0,
      iniciando: 0,
      items: 0,
      mesa: 0,
      mesonero: validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ? 0 : store.state.user.id_empleado,
      nuevo: true,
      productos: [],
      total: 0,
      contador: 1,
      pagos: [],
      descuentoMonedaPais: 0,
      servicioMonedaPais: 0,
      ivaMonedaPais: 0,
      igtfMonedaPais: 0,
      subtotalMonedaPais: 0,
      totalMonedaPais: 0,
      restaMonedaPais: 0,
      totalPagosMonedaPais: 0,
      totalPagos: 0,
      diferencia: 0,
      servicio: 0,
      RecargoivaMonedaPais: 0,
      RecargoIva: 0,
      numero_comensales: 0,
      propinas: [],
      ind_exonerar_iva: false,
      por_descuento: 0,
    }
    ///////
    const filtroProductoTemp = ref('')
    let myTimeout = null

    const filtroProductoTriger = () => {
      clearTimeout(myTimeout)
      myTimeout = setTimeout(() => {
        filtroProducto.value = filtroProductoTemp.value
        filtroProductoCargando.value = false
      }, 800)
    }
    const filtroProductoCargando = ref(false)

    watch(filtroProductoTemp, () => {
      if (filtroProductoTemp.value.length > 2) {
        filtroProductoCargando.value = true
        filtroProductoTriger()
      } else {
        filtroProductoCargando.value = false
        filtroProducto.value = ''
        clearTimeout(myTimeout)
      }

      console.log('filtroProducto', filtroProductoTemp.value)
    })

    const fiscalizar = async datos => {
      if (funciones.configuracionSelect(21) == '2') {
        await ModalConfirmacionRef.value.abrir({
          subTitulo: '<h3>¿Generar factura fiscal? </h3>',
          btnConfirmar: {
            esperarRespuesta: true,
            texto: 'Si',
            color: 'primary',
            funcionDatos: {
              item: datos,
              _MonedaPais: MonedaPais,
              _serialFiscal: props.serialFiscal,
              _ind_devolucion: false,
              _imprimir_tikera: true,
            },
            funcion: async _dat => {
              await Fiscalizar.Fiscalizar(_dat)

              //context.emit('getNumeroComanda', _dat.item)
              comanda.value = JSON.parse(JSON.stringify(comandaINIT))
              GetTabSelect(0)
              loadGuardandoComandaSuccess.value = true
              await delay(800)
              loadGuardandoComanda.value = false 
              isDialogVisible.value = false
            },
          },
          btnCancelar: {
            texto: 'No',
            color: 'secondary',
            funcion:async ()  =>  {

              comanda.value = JSON.parse(JSON.stringify(comandaINIT))
              GetTabSelect(0)
              loadGuardandoComandaSuccess.value = true
              await delay(800)
              loadGuardandoComanda.value = false

             

              isDialogVisible.value = false
            },
          },
        })
      } else {
        await Fiscalizar.Fiscalizar({
          item: datos,
          _MonedaPais: MonedaPais,
          _serialFiscal: props.serialFiscal,
          _ind_devolucion: false,
          _imprimir_tikera: true,
        })

        comanda.value = JSON.parse(JSON.stringify(comandaINIT))
        GetTabSelect(0)
        loadGuardandoComandaSuccess.value = true
        await delay(800)
        loadGuardandoComanda.value = false
 
        isDialogVisible.value = false
      }
    }

    const filtroProducto = ref('')
    const filtroProductoRef = ref(null)

    let MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    const listaDetallesProductosDetalleRef = ref(null)
    const DatosPuestos = ref([])
    const PuestoSelect = ref({})
    const ProductoSelect = ref({})
    const isDialogVisible = ref(false)
    const comanda = ref(JSON.parse(JSON.stringify(comandaINIT)))
    const tabSelect = ref(0)
    const tab2 = ref('tab2-1')
    /*const mesas = ref([])
    const formasPagos = ref([])
    const mesoneros = ref([])
    const monedas = ref([])*/
    const loadGuardandoComanda = ref(false)
    const dialogLoading = ref(false)

    onBeforeMount(() => {
      MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    })
    watch(props, () => {
      console.log('watch monedas')
      MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    })

    const ImprimirGrupoPago = comanda => {
      MaestroService.imprimirGrupoTikeraPagos({
        id_comanda: comanda.id,
        id_grupo_impresora_pc: store.state.tikera.id,
      })
        .then(response => {
          console.log(response.data)
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: `Se envio a la impresora.`,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: `Ups, ${response.data.mensaje}`,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Ups, ${error}`,
            type: 'error',
            error: {
              ...error,
              response: error?.response?.data,
            },
            funcion: 'ImprimirGrupoPago',
          })
        })
    }

    onBeforeMount(() => {
      console.log('beforeMount hook!')
      // filtroProductoRef.value?.focus()
      filtroProducto.value = ''
    })
    // filtroProductoRef.value.focus()

    const tab = ref('tab-1')
    const GetPuesto = data => {
      console.log('GetPuesto')
      console.log(data)
      PuestoSelect.value = data
      filtroProducto.value = ''
      //filtroProductoRef.value?.focus()
    }

    watch(tabSelect, () => {
      GetTabSelect(tabSelect.value)
    })

    const GetTabSelect = dato => {
      tabSelect.value = dato
      if (tabSelect.value == 0) {
        tab.value = 'tab-1'
        tab2.value = 'tab2-1'
      } else if (tabSelect.value == 1) {
        tab.value = 'tab-3'
        tab2.value = 'tab2-1'
      } else if (tabSelect.value == 2) {
        tab.value = 'tab-4'
        tab2.value = 'tab2-2'
      }
    }
    const GetAtrasProductoDetalle = () => {
      tab.value = 'tab-1'
    }
    const GetEliminarPagos = datos => {
      console.log('GetEliminarPagos3', datos)
      if (datos.id < 0) {
        let pagos = comanda.value.pagos.filter(item => item.id != datos.id)
        comanda.value.pagos = [...pagos]
        if (datos.elimina_propina) {
          let pagosPropinas = comanda.value.propinas.filter(item => item.id_pago_propina_vuelto != datos.id)
          console.log('pagosPropinas', pagosPropinas)
          comanda.value.propinas = [...pagosPropinas]
        }
      } else {
        store.commit('setAlert', {
          message: 'No puede eliminar un pago ya registrado, si desea eliminarlo debe ser por un administrador',
          type: 'error',
          error: datos,
          funcion: 'ImprimirGrupoPago',
        })
      }
      Totales()
    }

    const GetVerComanda = datos => {
      console.log(datos)
      ComandaService.listarComanda({ id: datos.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            CargaInicial(response.data.datos)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const AgregarPropinaVuelto = datos => {
      if (!comanda.value.propinas.find(it => it.id_pago_propina_vuelto != null)) {
        comanda.value.propinas.push(datos)
      } else {
        store.commit('setAlert', {
          message: 'ya existe una pripina de vuelto agregada',
          type: 'info',
        })
      }
    }
    const AgregarPropina = datos => {
      comanda.value.propinas.push({ ...datos, id: idDefaultPago() })
    }
    const EliminarPropina = datos => {
      const arr = comanda.value.propinas.filter(ite => ite.id != datos.id)
      comanda.value.propinas = [...arr]
    }

    const GetPagos = datos => {
      console.log('Pago nuevo')
      console.log(datos)
      let igtf = 0
      let error = 0
      const tipo_pago_select = datos.moneda.tipo_pago.find(item => item.id == datos.tipo_pago)
      let vuelto_info = {}
      //calculo igtf
      if (tipo_pago_select.ind_igtf) {
        console.log('tipo_pago tipo_pago', tipo_pago_select)
        if (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos >= Number(datos.monto_real)) {
          igtf = Number(datos.monto_real) * (configuracionesFun().igtf / 100)
        } else {
          if (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos >= 0) {
            igtf =
              (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos) * (configuracionesFun().igtf / 100)
          } else {
            store.commit('setAlert', {
              message:
                'Ya completo el monto de la factura 1 total' +
                comanda.value.total +
                ' igtf' +
                comanda.value.igtf +
                ' totalPagos' +
                comanda.value.totalPagos,
              type: 'info',
            })
            error = 1
            console.log('error')
          }

          console.log('vuelto')
          const vuelto = datos.monto_real - (igtf + comanda.value.resta)
          vuelto_info = {
            moneda: datos.moneda,
            monto_real: round(vuelto),
            monto_moneda: round(vuelto * datos.moneda.tasa),
            mostrar: 1,
            tipo_pago: datos.tipo_pago,
          }
        }
        console.log('Pago nuevo igtf')
        console.log(vuelto_info)
      } else {
        if (comanda.value.total - comanda.value.totalPagos < 0) {
          store.commit('setAlert', {
            message: 'Ya completo el monto de la factura 2',
            type: 'info',
          })
          error = 1
          console.log('error')
        } else {
          console.log('vuelto')
          const vuelto = datos.monto_real - (igtf + comanda.value.resta)
          vuelto_info = {
            moneda: datos.moneda,
            monto_real: round(vuelto),
            monto_moneda: round(vuelto * datos.moneda.tasa),
            mostrar: 1,
            tipo_pago: datos.tipo_pago,
          }
        }
      }
      if (error == 0) {
        comanda.value.pagos = [
          ...comanda.value.pagos,
          { ...datos, id: !datos.id == 0 ? datos.id : idDefaultPago(), igtf, vuelto_info },
        ]
        Totales()
      }
    }

    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }

    const Totales = () => {
      let descuento = 0.0
      let servicio = 0.0
      let iva = 0.0
      let igtf = 0.0
      let subtotal = 0.0
      let total = 0.0
      let resta = 0.0
      let totalPagos = 0.0

      let descuentoMonedaPais = 0.0
      let servicioMonedaPais = 0.0
      let ivaMonedaPais = 0.0
      let igtfMonedaPais = 0.0
      let subtotalMonedaPais = 0.0
      let totalMonedaPais = 0.0
      let restaMonedaPais = 0.0
      let totalPagosMonedaPais = 0.0
      let totalPagosMoneda = 0.0
      let RecargoivaMonedaPais = 0.0
      let RecargoIva = 0.0
      let RecargoMonedaPais = 0.0
      let Recargo = 0.0

      console.log('MonedaPais')
      console.log(MonedaPais)
      console.log('MonedaPais')
      comanda.value.productos.forEach(element => {
        const PrecioRealMonedaPais_ = round(element.precio_real * MonedaPais.tasa)
        const PrecioTotalMonedaPais_ = round(PrecioRealMonedaPais_ * element.cantidad)

        const descuentoMonedaPais_ = round(PrecioTotalMonedaPais_ * (element.por_descuento / 100))
        let recargoMonedaPais_ = 0
        if (element.ind_servicio_detalle) {
          recargoMonedaPais_ = (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (comanda.value.por_comision / 100)
        }
        const ivaMonedaPais_ =
          (PrecioTotalMonedaPais_ - descuentoMonedaPais_) *
          (!comanda.value.ind_exonerar_iva ? element.producto.impuesto_tasa_valor / 100 : 0)

        subtotalMonedaPais += PrecioTotalMonedaPais_
        descuentoMonedaPais += descuentoMonedaPais_
        ivaMonedaPais += ivaMonedaPais_
        RecargoMonedaPais += recargoMonedaPais_

        const PrecioReal_ = round(element.precio_real)
        const PrecioTotal_ = round(PrecioReal_ * element.cantidad)
        const descuento_ = round(PrecioTotal_ * (element.por_descuento / 100))
        let recargo_ = 0
        if (element.ind_servicio_detalle) {
          recargo_ = (PrecioTotal_ - descuento_) * (comanda.value.por_comision / 100)
        }
        const iva_ =
          (PrecioTotal_ - descuento_) *
          (!comanda.value.ind_exonerar_iva ? element.producto.impuesto_tasa_valor / 100 : 0)
        descuento += descuento_
        iva += iva_
        subtotal += PrecioTotal_
        Recargo += recargo_
      })
      console.log('aqui es totalPagos')
      console.log(comanda.value)
      comanda.value.pagos.forEach(element => {
        const totalPagosMonedaPais_ = round(round(element.monto_real) * MonedaPais.tasa)
        const totalPagos_ = round(element.monto_real)
        const totalPagosMoneda_ = round(element.monto_moneda)

        const igtfMonedaPais_ = element.igtf * MonedaPais.tasa
        const igtf_ = element.igtf

        totalPagosMonedaPais += totalPagosMonedaPais_
        totalPagos += totalPagos_
        totalPagosMoneda += totalPagosMoneda_
        igtfMonedaPais += igtfMonedaPais_
        igtf += igtf_
      })

      servicioMonedaPais = round(RecargoMonedaPais)
      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais + servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)

      servicio = Recargo
      total = round(subtotal - descuento + iva + igtf + servicio)
      resta = total - totalPagos
      console.log('servicio aquii')
      console.log(round((subtotal - descuento) * (comanda.value.por_comision / 100)))
      const diferencia = round(total * MonedaPais.tasa - totalMonedaPais)

      servicioMonedaPais = round(RecargoMonedaPais + diferencia)
      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais + servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)
      console.log('totales')
      console.log({
        descuento,
        servicio,
        iva,
        igtf,
        total,
        totalPagos,
        resta,
        subtotal,
        descuentoMonedaPais,
        servicioMonedaPais,
        ivaMonedaPais,
        igtfMonedaPais,
        subtotalMonedaPais,
        totalMonedaPais,
        restaMonedaPais,
        totalPagosMonedaPais,
        diferencia,
        RecargoMonedaPais,
        Recargo,
      })
      comanda.value = {
        ...comanda.value,
        descuento,
        servicio,
        iva,
        igtf,
        total,
        totalPagos,
        resta,
        subtotal,
        descuentoMonedaPais,
        servicioMonedaPais,
        ivaMonedaPais,
        igtfMonedaPais,
        subtotalMonedaPais,
        totalMonedaPais,
        restaMonedaPais,
        totalPagosMonedaPais,
        diferencia,
        RecargoMonedaPais,
        Recargo,
      }
    }
    const GetCambiarAgregarProducto = () => {
      dialog2.value = true
    }
    const GetTotales = datos => {
      comanda.value = {
        ...datos,
      }
    }

    const GetProducto = data => {
      if (comanda.value.estado_pago !== 9) {
        if (data.id != config.VUE_APP_PRODUCTO_ESTACIONAMIENTO || process.env.VUE_APP_PRODUCTO_ESTACIONAMIENTO) {
          console.log('GetProducto')
          tab.value = 'tab-2'
          dialog2.value = false
          const producto = Object.assign({}, { ...productoComandaINIT, producto: data })

          ProductoSelect.value = producto
        } else {
          let validarTiket = true
          const vlue = comanda.value.productos.find(
            it => it.producto.id == data.id && it.observacion == data.tiket.codigo,
          )
          if (vlue !== undefined) {
            validarTiket = false
          }
          console.log('vlue')
          console.log(comanda.value.productos)
          console.log(vlue)

          if (validarTiket == true) {
            const producto = Object.assign({})
            ProductoSelect.value = producto

            GetAgregarProducto({
              ingredientes: [],
              ingredientes_variables: [],
              extras: [],
              cantidad: 1,
              observacion: data.tiket.codigo,
              producto: ProductoSelect.value,
              total_extra: 0,
              precio_total: data.precio_venta,
              precio_real: data.precio_venta,
              precio_venta: data.precio_venta,
              iva: round(data.precio_venta * (!comanda.value.ind_exonerar_iva ? data.impuesto_tasa_valor / 100 : 0)),
              id: data.id,
            })
          } else {
            store.commit('setAlert', {
              message: 'Ya el producto se encuentra agregado',
              type: 'warning',
            })
          }
        }
      } else {
        store.commit('setAlert', {
          message: 'La comanda esta cerrada',
          type: 'warning',
        })
      }
    }

    const GetModificarProducto = data => {
      if (data.id < 0) {
        if (
          data.producto.id != config.VUE_APP_PRODUCTO_ESTACIONAMIENTO ||
          process.env.VUE_APP_PRODUCTO_ESTACIONAMIENTO
        ) {
          console.log('GetModificarProducto')
          console.log(data)
          console.log('GetProducto')
          tab.value = 'tab-2'
          const producto = JSON.parse(JSON.stringify({ ...data }))
          ProductoSelect.value = producto
        } else {
          store.commit('setAlert', {
            message: 'El producto no puede ser modificado',
            type: 'info',
          })
        }
      } else {
        if (comanda.value.estado_pago !== 9) {
          const producto = JSON.parse(JSON.stringify({ ...data }))
          console.log(producto)
          console.log('GetProducto', producto.producto.img)
          Vue.swal({
            html:
              `<img style="border-radius: 50px;
              width:  auto;  
              height: 100px;   
    border-radius: 10px;
    margin: 10px auto;
    display: block;
    overflow:hidden;
              " src="${config.api_imagenes}/producto/img/m-${producto.producto.img}" height="100px" max-width="100px"/> <br>` +
              '<h3>¿Quiere realizar una copia del producto? </h3>',

            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: () => {
              console.log('.')
            },
            allowOutsideClick: () => {
              if (!Vue.swal.isLoading()) {
                Vue.swal.close()
              }
            },
          }).then(result => {
            if (result.isConfirmed) {
              console.log(
                'confirmardo',
                DatosPuestos.value
                  .find(item => data.id_puesto == item.id)
                  .productos.find(item => data.id_producto == item.id),
              )
              const producto = DatosPuestos.value
                .find(item => data.id_puesto == item.id)
                .productos.find(item => data.id_producto == item.id)
              GetProducto(JSON.parse(JSON.stringify(producto)))
            }
          })
        } else {
          store.commit('setAlert', {
            message: 'La comanda esta cerrada',
            type: 'warning',
          })
        }
      }
    }
    const elimiandoItem = ref(false)
    const GetEliminarProducto = data => {
      console.log('GetEliminarProducto', data)
      let item = data
      if (data.id < 0) {
        comanda.value.productos = comanda.value.productos.filter(item => item.id != data.id)
        CalcularTotal()
      } else {
        if (comanda.value.estado_pago !== 9) {
          // vaidarPermiso(acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE, eliminarItemGuardado, data)
          ConfirmacionUsuarioRef.value.abrir(
            { id_accion_modulo: acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE },
            eliminarItemGuardado,
            data,
            () => {},
          )
        } else {
          store.commit('setAlert', {
            message: 'La comanda esta cerrada',
            type: 'warning',
          })
        }
      }
    }

    const eliminarItemGuardado = item => {
      elimiandoItem.value = true

      ModalConfirmacionRef.value.abrir({
        inputs: [
          {
            valor: '', //valor ingresado por el usuario
            nombreObjeto: 'motivo', //objeto q se devolvera en la funcion callback
            texto: 'Motivo', //valor a postrar en laberl de input
            obligatorio: true, //valdiar antes de dar condifrmar
            tipoInput: 'textArea', //text,number,textArea,select,checkbox
            opciones: [], //validos para select y checkbox
          },
          {
            valor: '0', //valor ingresado por el usuario
            nombreObjeto: 'ind_movimiento', //objeto q se devolvera en la funcion callback
            texto: '¿Movió inventario?', //valor a postrar en laberl de input
            obligatorio: true, //valdiar antes de dar condifrmar
            tipoInput: 'radio', //text,number,textArea,select,checkbox
            opciones: [
              {
                text: 'Si. Responsable mesonero',
                value: '1',
              },
              {
                text: 'Si. Responsable empresa',
                value: '2',
              },
              {
                text: 'Si. Responsable cocina',
                value: '3',
              },
              {
                text: 'No movió inventario',
                value: '0',
              },
            ], //validos para select y checkbox
          },
        ],
        subTitulo:
          `<img style="border-radius: 50px;" src="${config.api_imagenes}/producto/img/m-${item.producto.img}" height="100px" max-width="100px"/> <br>` +
          '<h3>¿Seguro que quiere anular? </h3>' +
          `<span>(${item.cantidad}) ${item.producto.nombre}</span>` +
          `<p><small>${item.producto.nombre_puesto}</small></p>`,
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcionDatos: {
            id_comanda: item.id_comanda,
            id_comanda_detalle: item.id,
            id_empleado: store.state.user.id_empleado,
            //motivo,
          },
          funcion: async _dat => {
            await ComandaService.eliminarItemComandaAdmin(_dat)
              .then(response => {
                if (response.data.mensaje == 'COMANDA MODIFICADA CON EXITO') {
                  Vue.swal({
                    icon: 'success',
                    title: `Exito`,
                    text: response.data.mensaje,
                  })
                } else {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: response.data.mensaje,
                  })

                  elimiandoItem.value = false
                }
              })
              .catch(error => {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })

                elimiandoItem.value = false
              })

            elimiandoItem.value = false
            GetVerComanda({ id: item.id_comanda })
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })
    }

    const vaidarPermiso = (accion, funcion_in, funcion_dato) => {
      isDialogVisible.value = false
      if (validarAcciones(accion)) {
        funcion_in(funcion_dato)
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',
          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  Vue.swal.showValidationMessage(`Usuario o clave invalida, o el usuario no tiene permiso`)
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'vaidarPermiso',
                })
                isDialogVisible.value = true
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              funcion_in(funcion_dato)
            } else if (result.dismiss === 'cancel') {
              isDialogVisible.value = true
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              isDialogVisible.value = true
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
              isDialogVisible.value = true
            }
          }
        })
      }
    }

    const GetCambiarComision = valor => {
      comanda.value.por_comision = valor
      CalcularTotal()
    }

    const GetModificacionDetalles = comanda_in => {
      comanda.value.productos = comanda_in.productos
      comanda.value.por_descuento = comanda_in.por_descuento
      CalcularTotal()
    }

    const comprobarDuplicidadComanda = async comandaResutl => {
      /**
       * -1  = error
       * 0 = no duplicdo
       * 1 = duplicado
       */
      try {
        return ComandaService.nuevaComandaDuplicado(comandaResutl)
          .then(async response => {
            if (response.data.mensaje == 'OK') {
              return 0
            } else if (response.data.mensaje == 'REPETIDO') {
              return await Vue.swal({
                icon: 'warning',
                html:
                  '<h3>Esta ejecutando una marcha exactamente igual a la anterior </h3>' +
                  `¿Seguro que quiere realizar esta marcha?`,
                showCancelButton: true,
                confirmButtonText: 'Si, Seguro',
                cancelButtonText: 'No',
                preConfirm: async () => {
                  return true
                },
                allowOutsideClick: () => {
                  !Vue.swal.isLoading()
                },
              }).then(result => {
                if (result.isConfirmed) {
                  return 2
                } else {
                  return 1
                }
                console.log(result)
                //GetFiscalizando(false)
              })
              return 1
            } else {
              store.commit('setAlert', {
                message: 'Error al comprobar duplicado: No se devolvio un valor esperado ',
                type: 'error',
                error: response.data,
                funcion: 'ComandaService',
              })
              return -1
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'Error al comprobar duplicado ' + error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'ComandaService',
            })
            return -1
          })
      } catch (error) {
        store.commit('setAlert', {
          message: 'Error al comprobar duplicado ' + error,
          type: 'error',
          error: error,
          funcion: 'ComandaService',
        })
        return false
      }
    }
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }

    const loadGuardandoComandaSuccess = ref(false)
    const GetGuardarComanda = async data => {
      if (!loadGuardandoComanda.value) {
        try {
          if (
            (comanda.value.resta <= 0 && comanda.value.resta != comanda.value.total) ||
            comanda.value.pagos.length == 0
          ) {
            if (store.state.user.caja.id == undefined && comanda.value.pagos.length != 0) {
              store.commit('setAlert', {
                message: 'Disculpe debe tener una caja asignada para poder realizar pagos',
                type: 'warning',
              })
            } else if (
              comanda.value.mesa == 0 &&
              store.state.user.caja.id == undefined &&
              funciones.configuracionSelect(9) == 2
            ) {
              store.commit('setAlert', {
                message: 'Disculpe debe seleccionar una mesa',
                type: 'warning',
              })
            } else if (
              (comanda.value.id_cliente == -1 && funciones.configuracionSelect(10) == 2) ||
              (comanda.value.id_cliente == -1 &&
                comanda.value.pagos.length > 0 &&
                funciones.configuracionSelect(10) == 3)
            ) {
              store.commit('setAlert', {
                message: 'Disculpe debe seleccionar un cliente',
                type: 'warning',
              })
            } else {
              loadGuardandoComandaSuccess.value = false
              loadGuardandoComanda.value = true
              await delay(500)
              let comandaResutl = { ...comanda.value }
              comandaResutl.items = comandaResutl.productos.length
              comandaResutl.comandero = store.state.user.id_empleado
              comandaResutl.caja = store.state.user.caja.id
              comandaResutl.cajero = store.state.user.id_empleado
              comandaResutl.productos = comanda.value.productos.filter(item => item.id < 0 || item.ind_modifica)
              comandaResutl.id_grupo_impresora_pc = store.state.tikera.id
              if (!validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)) {
                comandaResutl.mesonero = store.state.user.id_empleado
              }

              console.log(comandaResutl)

              const duplicado = await comprobarDuplicidadComanda(comandaResutl)

              if (duplicado == 0 || duplicado == 2) {
                ComandaService.nuevaComanda(comandaResutl)
                  .then(async response => {
                    console.log(response)
                    if (
                      response.data.mensaje == 'COMANDA_GUARDADA_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_PAGADA_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_PAGO_PARCIAL_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_MODIFICADA_CON_EXITO'
                    ) {
                      if (
                        response.data.mensaje == 'COMANDA_PAGADA_CON_EXITO' ||
                        response.data.mensaje == 'COMANDA_PAGO_PARCIAL_CON_EXITO'
                      ) {
                        ImprimirGrupoPago(response.data.datos)
                        //procesarTiketsEstacionamiento(comandaResutl)
                        if (
                          (props.serialFiscal != '' || funciones.configuracionSelect(20) != '1') &&
                          funciones.configuracionSelect(21) != '0'
                        ) {
                          fiscalizar(response.data.datos)
                          console.log('getNumeroComanda 1')
                        } else {
                          console.log(response.data.datos)
                          context.emit('getNumeroComanda', response.data.datos)

                          comanda.value = JSON.parse(JSON.stringify(comandaINIT))
                          GetTabSelect(0)
                          loadGuardandoComandaSuccess.value = true
                          await delay(800)
                          loadGuardandoComanda.value = false

                          store.commit('setAlert', {
                            message: response.data.mensaje,
                            type: 'primary',
                          })

                          isDialogVisible.value = false
                        }
                      } else {
                        comanda.value = JSON.parse(JSON.stringify(comandaINIT))
                        GetTabSelect(0)
                        loadGuardandoComandaSuccess.value = true
                        await delay(800)
                        loadGuardandoComanda.value = false

                        store.commit('setAlert', {
                          message: response.data.mensaje,
                          type: 'primary',
                        })

                        isDialogVisible.value = false
                      }
                    } else {
                      loadGuardandoComanda.value = false
                      store.commit('setAlert', {
                        message: response.data.mensaje,
                        type: 'warning',
                      })
                    }
                  })
                  .catch(error => {
                    loadGuardandoComanda.value = false
                    console.error(error)

                    store.commit('setAlert', {
                      message: error,
                      type: 'error',
                      error: {
                        ...error,
                        response: error?.response?.data,
                      },
                      funcion: 'GetGuardarComanda',
                    })

                    store.commit('setAlert', {
                      message: error,
                      type: 'error',
                      error: error,
                      funcion: 'GetGuardarComanda',
                    })
                  })
              } else {
                loadGuardandoComanda.value = false
              }
            }
          } else {
            store.commit('setAlert', {
              message: 'Disculpe los pagos no cubren el total de la factura',
              type: 'warning',
            })
          }
        } catch (error) {
          store.commit('setAlert', {
            message:
              'Error general al guardar la comanda. si este error persiste comunicate con el administrador de sistemas',
            type: 'error',
            error: error,
            funcion: 'GetGuardarComanda',
          })
        }
      }
    }

    const procesarTiketsEstacionamiento = comandaResutl => {
      console.log('procesarTiketsEstacionamiento')

      const tikets = comandaResutl.productos.filter(
        ite =>
          ite.producto.id == config.VUE_APP_PRODUCTO_ESTACIONAMIENTO || process.env.VUE_APP_PRODUCTO_ESTACIONAMIENTO,
      )
      console.log(tikets)
      tikets.forEach(it => {
        EstacionamientoServices.notify(it.observacion)
          .then(response => {
            if (response.data.status != 'valid') {
              store.commit('setAlert', {
                message: response.data.error,
                type: 'error',
                error: response.data.error,
                funcion: 'procesarTiketsEstacionamiento',
              })
            }
          })
          .catch(err => {
            store.commit('setAlert', {
              message: err,
              type: 'error',
              error: {
                ...err,
                response: error?.response?.data,
              },
              funcion: 'procesarTiketsEstacionamiento',
            })
          })
      })
    }
    const CalcularTotal = () => {
      /*let sum = 0

      comanda.value.productos?.forEach(item => {
        sum += item.precio_total
      })
      comanda.value.total = sum
*/
      Totales()
    }

    let contador = -10000
    const idDefaultProducto = () => {
      contador = contador + 1
      return contador
    }

    let contadorPago = -10000
    const idDefaultPago = () => {
      contadorPago = contadorPago + 1
      return contadorPago
    }
    const GetAgregarProducto = data => {
      console.log('GetAgregarProducto', data.producto.producto.ind_servicio)
      if (data.id < 0) {
        const producto = {
          cantidad: data.cantidad,
          estado_despacho: 0,
          extras: data.extras,
          total_extra: data.total_extra,
          nuevo: true,
          observacion: data.observacion,
          ingredientes: data.ingredientes,
          ingredientes_variables: data.ingredientes_variables,
          precio_real: data.precio_real,
          precio_total: data.precio_total,
          producto: data.producto.producto,
          descuento: data.producto.descuento,
          por_descuento: data.producto.por_descuento,
          id: data.id,
          sub_total: data.precio_real * data.cantidad,
          precio_venta: data.precio_venta,
          iva: data.iva,
          id_empleado_responsable: data.id_empleado_responsable,
          ind_servicio_detalle: data.producto.producto.ind_servicio,
          ind_modifica_precio: data.ind_modifica_precio,
          id_producto: data.producto.producto.id,
        }
        var comandaAux = comanda.value.productos.filter(item => item.id != data.id)
        comanda.value.productos = [...comandaAux, producto]
      } else {
        if (comanda.value.ind_bloqueado != true) {
          const producto = {
            id: idDefaultProducto(),
            cantidad: data.cantidad,
            estado_despacho: 0,
            extras: data.extras,
            total_extra: data.total_extra,
            nuevo: true,
            observacion: data.observacion,
            ingredientes: data.ingredientes,
            ingredientes_variables: data.ingredientes_variables,
            precio_real: data.precio_real,
            precio_total: data.precio_total,
            producto: data.producto.producto,
            descuento: data.producto.descuento,
            por_descuento: data.producto.por_descuento == 0 ? comanda.value.por_descuento : data.producto.por_descuento,
            sub_total: data.precio_real * data.cantidad,
            precio_venta: data.precio_venta,
            iva: data.iva,
            id_empleado_responsable: data.id_empleado_responsable,
            ind_servicio_detalle: data.producto.producto.ind_servicio,
            ind_modifica_precio: false,
            id_producto: data.producto.producto.id,
          }
          comanda.value.productos = [...comanda.value.productos, producto]
        } else {
          store.commit('setAlert', {
            message: 'Disculpe la comanda se encuentra bloqueada',
            type: 'warning',
          })
        }
      }

      CalcularTotal()

      GetTabSelect(0)
    }

    const datosFiscalizar = () => {
      const devolucion = {
        nFacturaAfectada: '1-1',
        numControlContraDev: 13,
        fechaFactContDev: '2022-07-14T13:45:00.000Z',
        serialFactContDev: '13422',
      }
      let ctdProductos = 0

      comanda.value.productos.forEach(item => {
        ctdProductos += item.cantidad
      })
      let factura = {
        nFactura: comanda.value.id.toString(),
        cajero: comanda.value.comandero_nombre,
        ctdProductos: round(ctdProductos),
        montoRecDesc: (Number(comanda.value.servicioMonedaPais) + Number(comanda.value.RecargoivaMonedaPais)).toFixed(
          2,
        ),
        isPorcentajeRecDesc: false,
        isRecDesc: true,
        cliente: {
          nombre: comanda.value.cliente,
          cedula: (comanda.value.id_cliente = null ? '12345679' : '12345678'),
        },

        impresora: {
          modeloImpresora: 1,
          puerto: 99,
          bAudios: 9600,
          paridad: 2,
          datos: 8,
          stopBits: 1,
          handshake: 0,
          maxTiempoEspera: 5000,
          tiempoAntesLectura: 100,
        },
      }

      let producto = []

      comanda.value.productos.forEach(item => {
        producto.push({
          barra: item.producto.id.toString(),
          descripcion: item.producto.nombre,
          pvp: round(item.precio_total),
          cantidad: round(item.cantidad),
          IVA: round(item.iva),
          descuento: round(item.descuento),
          serial: item.producto.id.toString(),
        })
      })

      let pagosFacturas = []
      comanda.value.pagos.forEach(item => {
        pagosFacturas.push({
          descripcion: item.moneda.nombre,
          monto:
            item.moneda.id != MonedaPais.id
              ? round(MonedaPais.tasa * item.monto_real).toFixed(2)
              : item.monto_moneda.toFixed(2),
          tasa: item.moneda.id != MonedaPais.id ? round(MonedaPais.tasa).toFixed(2) : '1.00',
        })
      })

      const facturas = { ...factura, producto, pagosFacturas }
      console.log('facturaa')
      console.log(JSON.stringify(facturas))
    }

    const GetDatosCliente = dato => {
      //  alert(dato.grupo_mesa)
      console.log('GetDatosCliente')
      comanda.value.cliente = dato.cliente
      comanda.value.mesonero = dato.mesonero
      comanda.value.mesa = dato.mesa
      comanda.value.mesa_nombre = dato.mesa_nombre
      comanda.value.grupo_mesa = dato.grupo_mesa
      comanda.value.id_grupo_mesa = dato.id_grupo_mesa
      comanda.value.descripcion_cliente = dato.descripcion_cliente
      //comanda.value.numero_comensales = dato.numero_comensales
    }

    const GetClienteFull = datos => {
      /*
            if (datos.tipo >= 6 && datos.tipo <= 8) {
              comanda.value.cliente = datos.nombre
            } else {
              comanda.value.cliente = datos.nombre.split(' ')[0] + ' ' + datos.apellido.split(' ')[0]
            }*/
      comanda.value.documento = datos.documento
      comanda.value.nombre_cliente = datos.nombre
      comanda.value.apellido_cliente = datos.apellido
      comanda.value.id_cliente = datos.id
    }

    const GetGuardarComandaPago = () => {
      GetGuardarComanda()
    }

    const CargaInicial = comandaIn => {
      isDialogVisible.value = true
      CargarDatosProductos()
      console.log('CargaInicial', comandaIn)
      console.log('datos')

      if (comandaIn == null) {
        comanda.value = JSON.parse(JSON.stringify(comandaINIT))
      } else {
        comanda.value = JSON.parse(JSON.stringify(comandaIn))
      }

      GetTabSelect(0)
      Totales()
    }
    const CargarDatosProductos = () => {
      if (funciones.getNavegador() != 'safari') {
        DatosPuestos.value = store.state.puestoProductos
      } else {
        ProductosService.obtenerProductosPuestos({ id_puesto: -1, ind_detalle: false })
          .then(response => {
            console.log(response)
            DatosPuestos.value = response.data.datos
          })
          .catch(error => {
            console.log(error)
          })
      }

      /**/
    }

    return {
      isDialogVisible,
      icons: {
        mdiArrowLeft,
        mdiMagnify,
        mdiAccount,
        mdiCartPlus,
        mdiCashRegister,
        mdiPlus,
      },
      store,
      EliminarPropina,
      AgregarPropina,
      DatosPuestos,
      PuestoSelect,
      tab,
      ProductoSelect,
      filtroProducto,
      filtroProductoRef,
      listaDetallesProductosDetalleRef,
      CargarDatosProductos,
      comanda,
      GetPuesto,
      GetProducto,
      GetAtrasProductoDetalle,
      GetAgregarProducto,
      GetModificarProducto,
      CargaInicial,
      tabSelect,
      GetTabSelect,
      GetDatosCliente,
      tab2,
      dialog2,
      GetGuardarComanda,
      loadGuardandoComanda,
      dialogLoading,
      GetPagos,
      AgregarPropinaVuelto,
      GetTotales,
      GetEliminarPagos,
      GetGuardarComandaPago,
      GetClienteFull,
      validarAcciones,
      acciones,
      GetCambiarComision,
      GetEliminarProducto,
      tabSelectLocal,
      GetCambiarAgregarProducto,
      GetModificacionDetalles,
      GetisDialogVisible,
      filtroProductoTriger,
      filtroProductoTemp,
      filtroProductoCargando,
      ConfirmacionUsuarioRef,
      GetConfirmacionUsuario,
      ModalConfirmacionRef,
      checkboxFiltroProducto,
      funciones,
      loadGuardandoComandaSuccess,
    }
  },
}
</script>
 
<style lang="scss"  >
.header-productos {
  height: 200px;
}

.v-dialog--fullscreen {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
}

.contenedor-productos {
  height: calc(100vh - 154px);
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
}

.contenedor-productos-pago {
  height: calc(100vh - 64px);
  overflow-x: hidden;
  overflow-y: auto;
  background: #333333;
}

.contenedor-detalle-lista {
  overflow-y: auto;
  height: calc(100vh);
}

.contenedor-detalle {
  overflow-y: auto;
}

.footer-detalle {
  height: 110px;
  margin: 0px;
  padding: 0px;
  position: relative;
  bottom: 0px;
}

.contenedor-puesto {
  height: 90px;
  margin: 0px;
  padding: 0px;

  position: relative;
  bottom: 0px;
}

.dummy-positioning {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

// The icon specifics
// Variables
$green: #4cc93f;
$icon-base-size: 20px;

// Structure
.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: $icon-base-size;
  border-radius: 50%;
  border: 4px solid lighten($green, 20%);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
}

// Elements
.success-icon {
  &__tip,
  &__long {
    display: block;
    position: absolute;
    height: 4px;
    background-color: lighten($green, 20%);
    border-radius: 10px;
  }

  &__tip {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    transform: rotate(45deg);
    animation: tipInPlace 300ms ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 180ms;
    visibility: hidden;
  }

  &__long {
    width: 4em;
    transform: rotate(-45deg);
    top: 3.7em;
    left: 2.75em;
    animation: longInPlace 140ms ease-in-out;
    animation-fill-mode: forwards;
    visibility: hidden;
    animation-delay: 300ms + 140ms;
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}
</style>
 