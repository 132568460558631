<template>
  <v-scale-transition>
    <v-card v-if="transicion == 1" >
      <v-card-title :class="ColorEstadoProcesado() + ' ' + 'py-1'"> </v-card-title>
      <v-card-title :class="ColorEstadoProcesado() " class="py-3 justify-space-between">
        <div>
          <h5 > <span class="white--text">{{ comanda.puesto.nombre }}</span></h5>
        
        </div>
        <div>
          <small  class="white--text" >#{{ comanda.id }} </small>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title class="pb-3"  >
        <div>
          <h5 >{{ comanda.comanda.mesonero_nombre }}</h5>
          <h6>
            {{ comanda.comanda.mesa_nombre }}  | {{ comanda.comanda.cliente }}
           

            <v-chip v-if="comanda.estado_despacho == 14" x-small color="error"> Anulado </v-chip>
          </h6>
        </div>
      </v-card-title>
      <v-card-text   >
        <span>
          <v-icon small>{{ icon.mdiClockOutline }}</v-icon>
          {{ FuncionesGenerales.diferenciasHoras(store.state.horaActual, comanda.fecha) }} Min |
          <small>{{ FuncionesGenerales.formatoFecha(comanda.fecha, 3) }}</small>
        </span>
      </v-card-text>
      <v-card-text class="px-3" v-if="comanda.estado_despacho < 13">
        <v-row>
          <v-col cols="2" class="mx-0 px-0">
            <v-card rounded="0" @click="imprimirComandaPuesto({...comanda, copias: 1})" class="d-flex align-center secondary mr-1" height="50">
              <v-scroll-y-transition>
                <div class="flex-grow-1 text-center">
                  <v-icon color="white">{{ icon.mdiPrinterPosOutline }}</v-icon>
                </div>
              </v-scroll-y-transition>
            </v-card></v-col
          >
      
          <v-col cols="10" class="mx-0 px-0">
            <v-card
              rounded="0"
              @click="cambiarEstado(comanda)"
              class="d-flex align-center secondary"
              height="50"
              width="100%"
            >
              <v-scroll-y-transition>
                <div :class="'flex-grow-1 text-center ' + NombreEstadoProcesado().color + '--text'">
                  <v-icon left :color="NombreEstadoProcesado().color">{{ icon.mdiPrinterPosOutline }}</v-icon>
                  {{ NombreEstadoProcesado().nombre }}
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody v-for="(item, index) in comanda.detalles.filter(its => !its.ind_anulado_separa_unido )" :key="index">
                <tr :class="item.estado != 14 ? 'grey2' : 'error'">
                  <td class="text-center">
                    <v-badge
                      bordered
                      top
                      color="primary"
                      offset-x="10"
                      offset-y="10"
                      :content="item.cant"
                      class="mt-4 mb-2"
                    >
                      <v-avatar size="30">
                        <v-img
                          max-height="100%"
                          max-width="100%"
                          :src="`${config.api_imagenes}/producto/img/s-${item.img_producto}`"
                          :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                          alt="avatar"
                        >
                        </v-img>
                      </v-avatar>
                    
                    </v-badge>
                    
                  </td>
               
                  <td>
                    <span v-if="item.nombre_combo_producto">
                     
                      {{ item.nombre_combo_producto }}
                      <br />
                    </span>
                    <span>
                      <strong :class="item.estado == 14 ? 'white--text' : ''">
                        <small class="my-0 py-0">{{item.id}} <br> </small>
                        {{ item.nombre_producto }}
                      </strong>

                      <br />
                      <strong class="white--text" v-if="item.estado == 14"> Anulado </strong> <br>
                      <span v-if="item.estado == 14" >{{item.motivo_anulacion_detalle}}</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <strong :class="item.estado == 14 ? 'white--text' : ''">
                        {{ round(item.precio_real).toFixed(2) }}$</strong
                      >
                    </span>
                  </td>
                </tr>
                <!-- OBSERVACION -->
                <tr v-if="item.observacion != ''">
                  <td colspan="3">
                    <span><strong>Observacion</strong></span>
                  </td>
                </tr>
                <tr v-if="item.observacion != ''">
                  <td colspan="2">
                    <span>{{ item.observacion }} </span>
                  </td>
                </tr>

                <!-- ACOMPAÑANTES -->

                <tr v-if="filtrarIngredientesAcompañantes(item).length > 0">
                  <td colspan="3">
                    <span><strong>Acompañantes</strong></span>
                  </td>
                </tr>
                <tr v-for="(ingre, index2) in filtrarIngredientesAcompañantes(item)" :key="index2">
                  <td class="text-center">
                    <strong>{{ ingre.cantidad }}</strong>
                  </td>
                  <td colspan="2">
                    <span class="info--text"> {{ ingre.nombre_producto }}</span>
                  </td>
                </tr>
                <!-- EXCLUIDOS -->
                <tr v-if="filtrarIngredientesExcluidos(item).length > 0">
                  <td colspan="3">
                    <span><strong>Ingredientes excluidos</strong></span>
                  </td>
                </tr>
                <tr v-for="(ingre, index2) in filtrarIngredientesExcluidos(item)" :key="index2">
                  <td colspan="3">
                    <span class="error--text"> {{ ingre.nombre_producto }}</span>
                  </td>
                </tr>
                <!-- EXTRAS -->
                <tr v-for="(ingre, index2) in item.extras" :key="index2">
                  <td colspan="3">
                    <span class="error--text"> EXTRA: {{ ingre.nombre_producto }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-expand-transition>
    </v-card>
  </v-scale-transition>
</template>
<script>
import { ref, onBeforeMount, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import Vue from 'vue'
import config from '@/api/config'
import FuncionesGenerales from '@/funciones/funciones'
import {
  mdiArrowLeft,
  mdiMagnify,
  mdiAccount,
  mdiHeart,
  mdiShareVariant,
  mdiMapMarker,
  mdiEye,
  mdiPrinter,
  mdiChevronDown,
  mdiChevronUp,
  mdiCallSplit,
  mdiSwapHorizontal,
  mdiBlockHelper,
  mdiLabel,
  mdiClockOutline,
} from '@mdi/js'
import store from '@/store'
import { acciones } from '@/modulos'
import ComandaService from '@/api/servicios/ComandaService'
export default {
  components: {},
  props: {
    comanda: Object,
    showAll: Boolean,
  },
  setup(props, context) {
    const mdiPrinterPosOutline =
      'M18 10H17V4H7V10H6C4.89 10 4 10.9 4 12V19H20V12C20 10.9 19.11 10 18 10M9 6H15V10H9V6M18 17H6V12H18V17M17 15H13V13H17V15Z'
    const logoI = process.env.VUE_APP_IMG_LOGO
    const modalSeparar = ref(false)
    const modalTransferir = ref(false)
    const show = ref(false)
    const mensaje = ref({
      snackbar: false,
      text: 'alerta',
      color: 'success',
      timeout: 2000,
    })

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }

    let showAll_ = false

    onBeforeMount(async () => {
      showAll_ = props.showAll
      show.value = showAll_
      await delay(500)
      transicion.value = 1
    })

    watch(props, () => {
      if (showAll_ != props.showAll) {
        showAll_ = props.showAll
        show.value = showAll_
      }
    })
    const cambiarEstado = dato => {
      if (dato.estado_despacho == 10) {
        cambiarEstadoItemsPreparando(dato)
      } else if (dato.estado_despacho == 11) {
        cambiarEstadoItemsLista(dato)
      } else if (dato.estado_despacho == 12) {
        cambiarEstadoItemsServir(dato)
      } else {
        store.commit('setAlert', {
          message: 'Error al cambiar el estado',
          type: 'warning',
          
        })
      }
    }

    const cambiarEstadoItemsLista = dato => {
      ComandaService.cambiarEstadoItemsLista(dato)
        .then(response => {
          if (response.data.mensaje == 'ESTADO_ACTUALIZADO') {
            dato.estado_despacho = 12
            store.commit('setAlert', {
              message: 'ESTADO_ACTUALIZADO',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'cambiarEstadoItemsLista',
          })
        })
    }
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    const transicion = ref(0)
    const cambiarEstadoItemsServir = dato => {
      ComandaService.cambiarEstadoItemsServir(dato)
        .then(async response => {
          if (response.data.mensaje == 'ESTADO_ACTUALIZADO') {
            
            
            transicion.value = 0
            await delay(500)
            dato.estado_despacho = 13 
            transicion.value = 1
            store.commit('setAlert', {
              message: 'ESTADO_ACTUALIZADO',
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'cambiarEstadoItemsServir',
          })
        })
    }
    const cambiarEstadoItemsPreparando = dato => {
      ComandaService.cambiarEstadoItemsPreparando(dato)
        .then(response => {
          if (response.data.mensaje == 'ESTADO_ACTUALIZADO') {
            dato.estado_despacho = 11
            store.commit('setAlert', {
              message: 'ESTADO_ACTUALIZADO',
              type: 'success',
            })
            if (store.state.copiasTickera > 0) {
              imprimirComandaPuesto(dato)
            }
            
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'cambiarEstadoItemsPreparando',
          })
        })
    }
    const imprimirComandaPuesto = comanda => {
      console.log(comanda)
      MaestroService.imprimirComandaPuesto({
        id_comanda: comanda.id,
        fecha_comanda : comanda.fecha,
        puesto : comanda.id_puesto,
        id_grupo_impresora_pc: store.state.tikera.id,
        copias: comanda.copias ? comanda.copias : store.state.copiasTickera
      })
        .then(response => {
          console.log(response.data)
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: `Se envio a la impresora.`,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: `Ups, ${response.data.mensaje}`,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Ups, ${error}`,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'imprimirComandaPuesto',
          })
        })
    }

 

    const round = num => {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }

    const ColorEstadoProcesado = () => {
      if (props.comanda.estado_despacho == 10) {
        return 'error'
      } else if (props.comanda.estado_despacho == 11) {
        return 'warning'
      } else if (props.comanda.estado_despacho == 12) {
        return 'success'
      } else if (props.comanda.estado_despacho == 13) {
        return 'info'
      } else {
        return 'grey5'
      }
    }

    const NombreEstadoProcesado = () => {
      if (props.comanda.estado_despacho == 10) {
        return {
          nombre: 'Preparar',
          color: 'warning',
        }
      } else if (props.comanda.estado_despacho == 11) {
        return {
          nombre: 'Listo',
          color: 'success',
        }
      } else if (props.comanda.estado_despacho == 12) {
        return {
          nombre: 'Servir',
          color: 'info',
        }
      } else if (props.comanda.estado_despacho == 13) {
        return {
          nombre: 'Listo',
          color: 'secondary',
        }
      } else {
        return 'dark'
      }
    }

    const filtrarIngredientesExcluidos = itemIn => {
      let valor = []
      if (itemIn.ingredientes) {
        valor = itemIn.ingredientes?.filter(item => item.checkbox == false)
      }

      return valor
    }

    const filtrarIngredientesAcompañantes = itemIn => {
      let valor = []
      if (itemIn.ingredientes) {
        valor = itemIn.ingredientes?.filter(item => item.ind_variable == true)
      }

      return valor
    }

    return {
      icon: {
        mdiArrowLeft,
        mdiMagnify,
        mdiAccount,
        mdiHeart,
        mdiShareVariant,
        mdiMapMarker,
        mdiEye,
        mdiPrinter,
        mdiChevronDown,
        mdiChevronUp,
        mdiCallSplit,
        mdiSwapHorizontal,
        mdiBlockHelper,
        mdiLabel,
        mdiPrinterPosOutline,
        mdiClockOutline,
      },
      mensaje,
      validarAcciones,
      acciones,
      ColorEstadoProcesado,
      NombreEstadoProcesado,
      round, 
      show,
      store,
      config,
      logoI,
      filtrarIngredientesExcluidos,
      filtrarIngredientesAcompañantes,
      FuncionesGenerales,
      cambiarEstado,
      imprimirComandaPuesto,
      transicion
    }
  },
}
</script>