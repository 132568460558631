<template>
  <v-card>
    <v-row class="row-puesto">
      <!-- Influencing The Influencer -->
      <v-col md="2" lg="1" sm="3" cols="4" v-for="(item, index) in filtroPuesto()" :key="index">
        <a ref="#" @click="SeleccionarPuesto(item)">
          <v-card>
            <v-img
              :src="`${config.api_imagenes}/puesto/img/m-${item.img}`"
              :lazy-src="`${config.api_imagenes}/puesto/img/puesto_default.jpg`"
              class="img-producto"
            />
          </v-card>
        </a>
      </v-col>
      <!-- send request card -->
    </v-row>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'
import config from '@/api/config'
import ProductosService from '@/api/servicios/ProductosService'
import { acciones } from '@/modulos'
import store from '@/store' 
export default {
  props: {
    puestos: Array,
  },
  setup(props, context) {
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)
      return valor
    }

    console.log(props.puestos)
    const filtroPuesto = () => {
      
      var valor = props.puestos.filter(item => (
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_COMIDA) && item.puesto_tipo == 1 ||
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_TIKETS) && item.puesto_tipo == 2 ||
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_CONTROL_INTERNO) && item.puesto_tipo == -1 ||
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_ZONA_KIDS) && item.puesto_tipo == 3||
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_SERVICIO) && item.puesto_tipo == 4||
        validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_TIENDA_DE_ROPA) && item.puesto_tipo == 5
      )) 

      return valor
    }
    const isCardDetailsVisible = false
    const rating = ref(5)
    const SeleccionarPuesto = puesto => {
      context.emit('GetPuesto', puesto)
      /*ProductosService.obtenerProductosPuestos({ id_puesto: puesto.id })
        .then(response => {
          console.log(response) 
          context.emit('GetPuesto', response.data.datos[0])
        })
        .catch(error => {
          console.log(error)
        })*/
    }
    return {
      isCardDetailsVisible,
      rating,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
      SeleccionarPuesto,
      config,
      filtroPuesto,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';
.row-puesto {
  flex-wrap: nowrap;
  height: 90px;
  padding: 0px;
  margin: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  transform: translateZ(0);
  /**
    height: '90px',
    flexWrap: 'nowrap',
    padding:'0px',
    margin:'0px',
    overflowX:'auto',
    overflowY:'hidden',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    */
  //flexWrap: 'nowrap',
}
.img-producto {
  height: 60px;
  object-fit: cover;
}
.text-img {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
