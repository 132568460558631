<template>
    <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card>
            <v-toolbar color="primary" dark>Escanea el codigo</v-toolbar>
            <v-card-text class="pt-2">
                <v-form ref="form">
                    <v-text-field :rules="reglas.requerido" autofocus v-model="codigo" hide-details="auto" outlined
                        label="Codigo"></v-text-field>
                </v-form>
                <v-btn class="mt-2" color="primary" block @click="verificar()" :loading="cargando">Buscar</v-btn>

            </v-card-text>
            <v-card-text class="pt-2 text-center">
                <h4 v-if="precio > 0">Monto a pagar</h4>
                <h3 v-if="precio > 0">$ {{ precio }}</h3>
                <p class="error--text" v-if="errorMensaje">
                    {{ errorMensaje }}
                </p>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn small color="secondary" @click="dialog = false">Cerrar</v-btn>
                <v-btn small color="primary" :disabled="!(precio > 0)" @click="agregar()">Agregar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import EstacionamientoServices from '@/api/servicios/EstacionamientoServices'
export default {
    props: {
        monedas: Array
    },
    setup(props, context) {
        const dialog = ref(false)
        const codigo = ref('')
        const precio = ref(0.00)
        const errorMensaje = ref("")
        const form = ref()
        const produc = ref(null)
        const abrir = (item) => {
            dialog.value = true
            errorMensaje.value = ""
            precio.value = 0
            codigo.value = ''
            produc.value = item
        }

        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }

        const reglas = {
            requerido: [
                v => !!v || "Es requerido",

            ],
        }
        function round(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15));
            return Math.round(m) / 100 * Math.sign(num);
        }

        const agregar = () => {
            context.emit("agregarEstacionamiento", {
                ...produc.value,
                tiket: {
                    codigo: codigo.value,
                    precio: precio.value
                } 
            })
            dialog.value = false
        }
        let MonedaPais = ref({})
        const cargando = ref(false)
        onBeforeMount(() => {
            MonedaPais.value = props.monedas.filter(item => item.meneda_pais == true)[0]
            errorMensaje.value = ""
        })

        const verificar = () => {
            if (validarForm()) {
                errorMensaje.value = ""
                cargando.value = true
                EstacionamientoServices.verify(codigo.value)
                    .then(response => {
                        if (response.data.status == "valid") {
                            const valor = Number(response.data.amount.replace(/[.]/g, "").replace(/[,]/g, "."))
                            if (valor > 0) {
                             
                                produc.value.precio_venta =  round((valor / MonedaPais.value.tasa))  
                                produc.value.precio_venta_iva = round((valor / MonedaPais.value.tasa)) * ((produc.value.impuesto_tasa_valor /100)+1)
                                precio.value = produc.value.precio_venta_iva
                                console.log("aqui")
                                console.log( produc.value)
                            }

                        } else {
                            errorMensaje.value = response.data.error
                        }
                    })
                    .catch(err => {
                        errorMensaje.value = "Error al conectar con el servidor"
                        console.log(err)
                    })
                    .finally(() => {
                        cargando.value = false
                    })
            }
        }

        return {
            dialog,
            abrir,
            cargando,
            verificar,
            codigo,
            MonedaPais,
            precio,
            errorMensaje,
            form,
            reglas,
            agregar
        }
    },
}
</script>