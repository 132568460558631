
<template>
  <div>
    <v-card flat>
      <div class="lista-productos4">
        <formas-pagos
          @GetEliminarPagos="GetEliminarPagos"
          @GetPagos="GetPagos"
          @AgregarPropinaVuelto="AgregarPropinaVuelto"
          @AgregarPropina="AgregarPropina"
          @EliminarPropina="EliminarPropina"
          :comanda="comanda"
          :monedas="monedas"
          :configuraciones="configuraciones"

        ></formas-pagos>
      </div>
    </v-card>
    <v-footer dark padless class="footer-detalle">
      <v-container class="grey lighten-5 mb-6">
        <v-row class="d-flex justify-center mb-2 mt-0 white--text">
          <v-col class="py-0 my-0 text-center">
            <span>Total: $.{{ (totalesPropina().totalMoneda + comanda.total).toFixed(2) }}$ </span>
          </v-col>
          <v-col class="py-0 my-0 text-center">
            <span :class="(totalesPropina().totalMoneda + comanda.totalPagos  - (totalesPropina().totalMoneda + comanda.total)) >= 0 ? 'success--text' : 'error--text'"
              >
              {{ (totalesPropina().totalMoneda + comanda.totalPagos  - (totalesPropina().totalMoneda + comanda.total)) >= 0 ? 'Vuelto' : 'Resta' }}
              : $.{{
                (totalesPropina().totalMoneda + comanda.totalPagos  - (totalesPropina().totalMoneda + comanda.total)).toFixed(2)
              }}$
            </span>
          </v-col>
        </v-row>
        <v-row class="m-0">
          <v-btn
            block
            color="success"
            :disabled="(comanda.cliente == '' && funciones.configuracionSelect(12) == '2')  || comanda.productos.length == 0 || comanda.estado_pago == 9"
            @click="GuardarComandaPago()"
          >
            Finalizar</v-btn
          >
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
    
 

<script>
// eslint-disable-next-line object-curly-newline
import config from '@/api/config'
import {
  mdiClipboardTextOutline,
  mdiGestureTapButton,
  mdiOrderBoolDescending,
  mdiAccountStar,
  mdiMapMarker,
  mdiInformationOutline,
  mdiFolderOutline,
  mdiAccount,
  mdiCashRegister,
  mdiCartPlus,
} from '@mdi/js'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import FormasPagos from './FormasPagos.vue'
import funciones from '@/funciones/funciones.js'

export default {
  components: { FormasPagos },
  props: {
    comanda: Object,
    tabSelect: Number,
    monedas: Array,
    configuraciones: Array,
  },

  setup(props, context) {
    const MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    const tabSelectLocal = ref(1)
    const nombreCliente = ref('')
    const mesonero = ref('')
    const mesa = ref('')
    const descripcionCliente = ref('')

    const nombreClienteRef = ref(null)
    const totalesPropina = () => {
      let sumas = {
        totalMonedaPais: 0,
        totalMoneda: 0,
      }

      props.comanda.propinas.forEach(element => {
        sumas.totalMonedaPais += Number(element.monto_real) * MonedaPais.tasa
        sumas.totalMoneda += Number(element.monto_real)
      })

      return sumas
    }
    const mesoneros = [
      { id: 1, nombre: 'Sin asignar' },
      { id: 5, nombre: 'pepito' },
      { id: 2, nombre: 'manuel' },
      { id: 3, nombre: 'pablo' },
      { id: 4, nombre: 'pedro' },
    ]

    const mesas = [
      { id: 1, nombre: 'Sin asignar' },
      { id: 5, nombre: 'mesa1' },
      { id: 2, nombre: 'mesa2' },
      { id: 3, nombre: 'mesa3' },
      { id: 4, nombre: 'mesa4' },
    ]
    onBeforeMount(() => {
      tabSelectLocal.value = props.tabSelect
      nombreCliente.value = props.comanda.cliente
      mesa.value = props.comanda.mesa
      mesonero.value = props.comanda.mesonero
      descripcionCliente.value = props.comanda.descripcion_cliente
    })

    watch(props, (props, prevProps) => {
      tabSelectLocal.value = props.tabSelect
      nombreCliente.value = props.comanda.cliente
      mesa.value = props.comanda.mesa
      mesonero.value = props.comanda.mesonero
      descripcionCliente.value = props.comanda.descripcion_cliente
      if (props.tabSelect != prevProps.tabSelect) {
        nombreClienteRef.value?.focus()
      }
    })
    const GetEliminarPagos = value => {
      console.log('GetEliminarPagos2')
      context.emit('GetEliminarPagos', value)
    }

    const GuardarComandaPago = () => {
      console.log('GetGuardarComandaPago')
      context.emit('GetGuardarComandaPago')
    }

    const GetPagos = value => {
      context.emit('GetPagos', value)
    }
    const AgregarPropinaVuelto = value => {
      context.emit('AgregarPropinaVuelto', value)
    }

    const AgregarPropina =(value)=>{
      context.emit('AgregarPropina', value)
    }
    
    const EliminarPropina =(value)=>{
      context.emit('EliminarPropina', value)
    }
    
    const GetDatosCliente = () => {
      context.emit('GetDatosCliente', {
        cliente: nombreCliente.value,
        mesonero: mesonero.value,
        mesa: mesa.value,
        descripcion_cliente: descripcionCliente.value,
      })
    }

    watch(nombreCliente, () => {
      GetDatosCliente()
    })

    watch(mesonero, () => {
      GetDatosCliente()
    })

    watch(descripcionCliente, () => {
      GetDatosCliente()
    })

    watch(mesa, () => {
      GetDatosCliente()
    })

    watch(tabSelectLocal, () => {
      console.log(tabSelectLocal.value)
      SelectTab(tabSelectLocal.value)
    })

    const SelectTab = item => {
      console.log(item)
      context.emit('GetTabSelect', item)
    }

    return {
      mdiInformationOutline,
      mdiFolderOutline,
      mdiCashRegister,
      nombreCliente,
      mdiMapMarker,
      mdiAccountStar,
      mdiOrderBoolDescending,
      mesonero,
      mesa,
      mdiAccount,
      mdiCartPlus,
      listaDetallesProductosRef,
      tabSelectLocal,
      mesoneros,
      mesas,
      nombreClienteRef,
      descripcionCliente,
      SelectTab,
      GetPagos,
      AgregarPropinaVuelto,
      GetEliminarPagos,
      GuardarComandaPago,
      config,
      totalesPropina,
      AgregarPropina,
      EliminarPropina,
      funciones
    }
  },
}
</script>


<style lang="scss"  >
.lista-productos4 {
  height: calc(100vh - 154px);
  overflow-x: hidden;
  overflow-y: auto;
}

.header-producto4 {
  height: 64px;
}
</style>