<template>
  <div>
    <v-row>
      <!-- Influencing The Influencer -->
      <v-col cols="12" v-if="categoriaSeleccionada.id == undefined">
        <v-row>
          <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in categorias" :key="key">
            <a ref="#" v-on:click="SeleccionarCategoria(item)">
              <v-card>
                <v-img
                  :src="require(`@/assets/images/logos/${logoI}`)"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="100px"
                >
                  <small class="text-img-catgoria"> {{ item.nombre }}</small>
                </v-img>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="subCategoriaSeleccionada.id == undefined && categoriaSeleccionada.id != undefined">
        <v-row>
          <v-col lg="2" md="2" sm="4" cols="6">
            <a ref="#" v-on:click="SeleccionarCategoria(null)">
              <v-card>
                <v-img
                  :src="require(`@/assets/images/logos/${logoI}`)"
                  gradient="to bottom, rgba(145, 85, 253 ), rgba(145, 85, 253 )"
                  height="100px"
                >
                  <small class="text-img-catgoria">ATRAS</small>
                </v-img>
              </v-card>
            </a>
          </v-col>
          <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in subCategorias" :key="key">
            <a ref="#" v-on:click="SeleccionarSubCategoria(item)">
              <v-card>
                <v-img
                  :src="require(`@/assets/images/logos/${logoI}`)"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="100px"
                >
                  <small class="text-img-catgoria"> {{ item.nombre }}</small>
                </v-img>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="subCategoriaSeleccionada.id != undefined && categoriaSeleccionada.id != undefined">
        <v-row>
          <v-col lg="2" md="2" sm="4" cols="6">
            <a ref="#" v-on:click="SeleccionarSubCategoria(null)">
              <v-card>
                <v-img
                  :src="require(`@/assets/images/logos/${logoI}`)"
                  gradient="to bottom, rgba(145, 85, 253 ), rgba(145, 85, 253 )"
                  height="100px"
                >
                  <small class="text-img-catgoria">ATRAS</small>
                </v-img>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in FiltrarProducto()" :key="key">
        <a ref="#" v-on:click="SeleccionarProducto(item)">
          <v-card>
            <div class="text-center transparent">
              <strong
                ><small class="black--text">{{ filtrarNombrePuesto(item.id_puesto) }}</small>
              </strong>
            </div>

            <v-img
              :src="`${config.api_imagenes}/producto/img/m-${item.img}`"
              :lazy-src="require(`@/assets/images/logos/${logoI}`)"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="160px"
            >
              <small class="text-img"> {{ item.nombre }} </small>

              <small v-if="item.estado == 1" class="text-img2">
                {{ item.precio_venta.toFixed(2) }}$ <br /><small v-if="item.impuesto_tasa_valor > 0">
                  +iva {{ (item.precio_venta * (item.impuesto_tasa_valor / 100 + 1)).toFixed(2) }}$
                </small></small
              >
              <small v-if="item.estado == 2" class="text-img3"> AGOTADO</small>
            </v-img>
          </v-card>
        </a>
      </v-col>

      <!-- send request card -->
    </v-row>
    <ModalTiketEstacionamiento
      @agregarEstacionamiento="agregarEstacionamiento"
      :monedas="monedas"
      ref="ModalTiketEstacionamientoRef"
    ></ModalTiketEstacionamiento>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import ModalTiketEstacionamiento from './ModalTiketEstacionamiento.vue'
import { acciones } from '@/modulos'
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
} from '@mdi/js'
import store from '@/store'

export default {
  components: {
    ModalTiketEstacionamiento,
  },
  props: {
    puesto: Object,
    filtroProducto: String,
    monedas: Array,
    puestos: Array,
    checkboxFiltroProducto: Boolean,
  },
  setup(props, context) {
    const logoI = process.env.VUE_APP_IMG_LOGO
    const ModalTiketEstacionamientoRef = ref(null)
    const isCardDetailsVisible = false
    const rating = ref(5)
    const pageProducto = ref(1)
    const SeleccionarProducto = item => {
      if (item.estado == 1) {
        if (item.id !=  config.VUE_APP_PRODUCTO_ESTACIONAMIENTO || process.env.VUE_APP_PRODUCTO_ESTACIONAMIENTO   ) {
          console.log(item)

          context.emit('GetProducto', { ...item, nombre_puesto: filtrarNombrePuesto(item.id_puesto) })
        } else {
          ModalTiketEstacionamientoRef.value.abrir(item)
        }
      } else {
        store.commit('setAlert', {
          message: 'Disculpe el producto esta agotado',
          type: 'warning',
        })
      }
    }
    const agregarEstacionamiento = item => {
      console.log(item)
      context.emit('GetProducto', { ...item, nombre_puesto: filtrarNombrePuesto(item.id_puesto) })
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)
      return valor
    }
    const categoriaSeleccionada = ref({})
    const subCategoriaSeleccionada = ref({})
    const categorias = ref([])
    const subCategorias = ref([])
    const filtroPuesto = () => {
      var valor = props.puestos.filter(
        item =>
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_COMIDA) && item.puesto_tipo == 1) ||
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_TIKETS) && item.puesto_tipo == 2) ||
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_CONTROL_INTERNO) && item.puesto_tipo == -1) ||
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_ZONA_KIDS) && item.puesto_tipo == 3) ||
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_SERVICIO) && item.puesto_tipo == 4) ||
          (validarAcciones(acciones.DASHBOARD_COMANDA_TIPO_PUESTO_TIENDA_DE_ROPA) && item.puesto_tipo == 5),
      )

      return valor
    }
    const SeleccionarCategoria = item => {
      if (item != null) {
        categoriaSeleccionada.value = item
        subCategorias.value = item.sub_categorias
        subCategoriaSeleccionada.value = {}
      } else {
        categoriaSeleccionada.value = {}
      }
    }
    const SeleccionarSubCategoria = item => {
      if (item != null) {
        subCategoriaSeleccionada.value = item
      } else {
        subCategoriaSeleccionada.value = {}
      }
    }

    watch(props, () => {
      categorias.value = props.puesto?.categorias
      categoriaSeleccionada.value = {}
      subCategorias.value = []
      subCategoriaSeleccionada.value = {}
    })
    const filtrarNombrePuesto = pu => {
      return props.puestos.find(item => item.id == pu)?.nombre
    }

    const FiltrarProducto = () => {
      let valor = []
      console.log(valor)
      if (props.filtroProducto == '') {
        if (subCategoriaSeleccionada.value.id != undefined) {
          console.log()
          console.log(subCategoriaSeleccionada.value.id)
          valor = props.puesto?.productos?.filter(item => item.sub_categoria == subCategoriaSeleccionada.value.id)
        }
      } else {
        if (props.checkboxFiltroProducto == true) {
          filtroPuesto()?.forEach(pues => {
            valor = valor.concat(
              pues?.productos?.filter(item => 
              item.nombre.includes(props.filtroProducto.toUpperCase()) ||
              item.referencia.includes(props.filtroProducto.toUpperCase())
              ),
            )
          })
        } else {
          valor = props.puesto?.productos?.filter(item => 
          
              item.nombre.includes(props.filtroProducto.toUpperCase()) ||
              item.referencia.includes(props.filtroProducto.toUpperCase())
          )
        }

        /* */
      }
      return valor
    }

    return {
      isCardDetailsVisible,
      rating,

      // icons
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
      },
      SeleccionarProducto,
      SeleccionarCategoria,
      FiltrarProducto,
      config,
      categorias,
      categoriaSeleccionada,
      subCategoriaSeleccionada,
      SeleccionarSubCategoria,
      subCategorias,
      ModalTiketEstacionamientoRef,
      agregarEstacionamiento,
      logoI,
      filtrarNombrePuesto,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.img-producto {
  height: 140px;
  object-fit: cover;
}

.text-img-catgoria {
  top: 0rem;
  padding-top: 35px;
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}

.text-img {
  top: 0rem;
  padding: 3px;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}

.text-img2 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.text-img3 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.8);
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
