
<template>
  <div>
    <v-card flat>
      <div class="lista-productos4">
        <v-container fluid>
          <v-row align="center" class="my-0">
            <v-col
              cols="12"
              v-if="funciones.configuracionSelect(12) == '1' || funciones.configuracionSelect(12) == '2'"
            >
              <v-text-field
                hide-details="auto"
                label="Descripcion comanda"
                v-model="nombreCliente"
                :prepend-icon="mdiAccount"
                outlined
                dense
                autofocus
                ref="nombreClienteRef"
                :rules="funciones.configuracionSelect(12) == '2' ? reglas.requerido : ''"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="funciones.configuracionSelect(11) == '1' || funciones.configuracionSelect(11) == '2'">
              <v-text-field
               hide-details="auto"
                :prepend-icon="mdiNumeric1CircleOutline"
                label="Numero Comensales"
                v-model="comanda.numero_comensales"
                outlined
                dense
                :rules="funciones.configuracionSelect(11) == '2' ? reglas.nroComensales : ''"
                type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="validarAcciones(acciones.DASHBOARD_COMANDA_ASIGNAR_MENSONERO)">
              <v-autocomplete
                hide-details="auto"
                v-model="mesonero"
                :items="mesoneros"
                label="Mesonero"
                item-value="id"
                outlined
                :prepend-icon="mdiAccountStar"
                dense
                item-text="nombre_completo"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" class="mx-1 my-0">
            <v-col cols="12">
              <v-card
                :color="comanda.id_cliente > 0 ? 'primary' : 'secondary'"
                class="d-flex align-center"
                dark
                height="70"
                @click="BuscarCliente()"
              >
                <v-scroll-y-transition>
                  <div class="flex-grow-1 text-center white--text">
                    <v-row>
                      <v-col cols="auto" class="ml-2">
                        <v-icon color="white" large>{{ mdiAccount }}</v-icon>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col>
                        <small v-if="comanda.id_cliente <= 0">Agrega un cliente</small>
                        <small v-else
                          >{{ comanda.documento }}
                          <br />
                          {{ comanda.nombre_cliente + ' ' + comanda.apellido_cliente }}
                        </small>
                      </v-col>
                    </v-row>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
            <v-col cols="12">
             
              <modal-mesas
                :label="
                  mesaSelect.grupo_mesa != undefined
                    ? mesaSelect.grupo_mesa + ' ' + mesaSelect.nombre
                    : 'Seleccionar Mesa'
                "
                @GetMesa="GetMesa"
              ></modal-mesas>
            </v-col>
          </v-row>

          <v-container v-if="funciones.configuracionSelect(16) == '1'">
            <v-row>
              <v-col v-for="(item, index) in estadoFormaPago" :key="index">
                <v-item>
                  <v-card
                    :color="comanda.estado_forma_pago == item.id ? 'primary' : ''"
                    class="d-flex align-center"
                    dark
                    height="70"
                    @click="comanda.estado_forma_pago = item.id"
                  >
                    <v-scroll-y-transition>
                      <div class="flex-grow-1 text-center white--text">
                        <v-icon v-if="comanda.estado_forma_pago == item.id">{{ mdiCheck }}</v-icon>
                        <strong>{{ item.descripcion }}</strong>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-container>
          <!-- <v-row align="center" class="my-0 mx-2 ml-2">
            <v-textarea outlined name="input-7-4" label="Descripcion extendida" :prepend-icon="mdiOrderBoolDescending"
              v-model="descripcionCliente"></v-textarea>
                  </v-row>-->
        </v-container>
        <modal-cliente @GetClienteFull="GetClienteFull" ref="refModalClienteVue"></modal-cliente>
      </div>
    </v-card>

    <v-footer dark padless class="footer-detalle">
      <v-container class="grey lighten-5 mb-6">
        <v-row class="d-flex justify-center mb-2 mt-0 white--text">
          <span v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES)"> Total: {{ comanda.total }}$ </span>
          <span v-else>...</span>
        </v-row>
        <v-row class="m-0">
          <v-col
            :cols="
              !validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ||
              comanda.cliente == '' ||
              comanda.productos.length == 0
                ? 12
                : 8
            "
            class="pa-0"
          >
            <v-btn
              block
              color="primary"
              :disabled="
                !validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICAR_COMANDA) ||
                (comanda.cliente == '' && funciones.configuracionSelect(12) == '2') ||
                isNaN(comanda.numero_comensales) ||
                (comanda.numero_comensales <= 0 && funciones.configuracionSelect(11) == '2') ||
                comanda.productos.length == 0 ||
                comanda.estado_pago == 9
              "
              @click="GuardarComanda()"
              :loading="loadGuardandoComanda"
              >Enviar
            </v-btn>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-btn
              v-if="
                validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) &&
                !(comanda.cliente == '' || comanda.productos.length == 0)
              "
              @click="SelectTab(2)"
              block
              color="success"
              :disabled="comanda.estado_pago == 9"
            >
              Pagar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
    
 

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiClipboardTextOutline,
  mdiGestureTapButton,
  mdiOrderBoolDescending,
  mdiAccountStar,
  mdiMapMarker,
  mdiInformationOutline,
  mdiFolderOutline,
  mdiAccount,
  mdiCashRegister,
  mdiCartPlus,
  mdiAccountSearch,
  mdiNumeric1CircleOutline,
  mdiCheck,
  mdiTableChair,
} from '@mdi/js'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import ModalCliente from './ModalCliente.vue'
import store from '@/store'
import { acciones } from '@/modulos'
import ModalMesas from './ModalMesas.vue'
import funciones from '@/funciones/funciones.js'
export default {
  props: {
    comanda: Object,
    tabSelect: Number,
    mesoneros: Array,
    mesas: Array,
    loadGuardandoComanda: Boolean,
    estadoFormaPago: Array,
  },
  components: {
    ModalCliente,
    ModalMesas,
  },
  watch: {
    'comanda.id': function (news, old) {
      if (news != old) {
       
        //this.tabSelectLocal = this.tabSelect
        this.nombreCliente = this.comanda.cliente
        this.mesa = this.comanda.mesa
        this.mesonero = this.comanda.mesonero
        // this.nroComensales = this.comanda.numero_comensales
        this.descripcionCliente = this.comanda.descripcion_cliente
        this.mesaSelect = {
          id: this.comanda.mesa,
          nombre: this.comanda.mesa_nombre,
          grupo_mesa: this.comanda.grupo_mesa,
          id_grupo_mesa: this.comanda.id_grupo_mesa,
        }
      }
    },
    /*
    tabSelect: function (news, old) {
     
      this.tabSelectLocal = this.tabSelect
      this.nombreCliente = this.comanda.cliente
      this.mesa = this.comanda.mesa
      this.mesonero = this.comanda.mesonero
      // this.nroComensales = this.comanda.numero_comensales
      this.descripcionCliente = this.comanda.descripcion_cliente
      this.mesaSelect = {
        id: this.comanda.mesa,
        nombre: this.comanda.mesa_nombre,
        grupo_mesa: this.comanda.grupo_mesa,
        id_grupo_mesa: this.comanda.id_grupo_mesa,
      }
    },*/
  },
  setup(props, context) {
    const estadoFormaPagoValue = ref(20)
    const tabSelectLocal = ref(1)
    const nombreCliente = ref('')
    //const nroComensales = ref('')
    const mesonero = ref('')
    const mesa = ref('')
    const descripcionCliente = ref('')
    const nombreClienteRef = ref(null)
    const refModalClienteVue = ref(null)
    const mesaSelect = ref({
      id: null,
      nombre: '',
      grupo_mesa: '',
      id_grupo_mesa: '',
    })
    const GuardarComanda = () => {
      console.log('aquio')
      context.emit('GetGuardarComanda')
    }

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],

      nroComensales: [
        v => !!v || 'Es requerido',
        v => (v && !isNaN(v)) || 'Numero debe ser valido',
        v => (v && v > 0) || 'Numero debe mayor que 0',
      ],
    }
    onBeforeMount(() => {
      tabSelectLocal.value = props.tabSelect
      nombreCliente.value = props.comanda.cliente
      mesa.value = props.comanda.mesa
      //nroComensales.value = props.comanda.numero_comensales
      mesaSelect.value = {
        id: props.comanda.mesa,
        nombre: props.comanda.mesa_nombre,
        grupo_mesa: props.comanda.grupo_mesa,
        id_grupo_mesa: props.comanda.id_grupo_mesa,
      }
      mesonero.value = props.comanda.mesonero
      descripcionCliente.value = props.comanda.descripcion_cliente
    })

    const GetClienteFull = datos => {
      console.log('GetClienteFull - 2')
      context.emit('GetClienteFull', datos)
    }

    const GetDatosCliente = () => { 
      context.emit('GetDatosCliente', {
        cliente: nombreCliente.value,
        mesonero: mesonero.value,
        mesa: mesaSelect.value.id,
        mesa_nombre: mesaSelect.value.nombre,
        id_grupo_mesa: mesaSelect.value.id_grupo_mesa,
        grupo_mesa: mesaSelect.value.grupo_mesa,
        descripcion_cliente: descripcionCliente.value,
        // numero_comensales: nroComensales.value,
      })
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const BuscarCliente = () => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_BUSCAR_CLIENTE)) {
        refModalClienteVue.value.CambiarEstadoDialogo(true)
      } else {
        store.commit('setAlert', {
          message: 'No tienes permiso para acceder',
          type: 'info',
        })
      }
    }

    const GetMesa = item => {
      mesaSelect.value = item
      mesa.value = item.id
      GetDatosCliente()
    }
    /*watch(nroComensales, () => {
      GetDatosCliente()
    })*/
    watch(nombreCliente, () => {
      GetDatosCliente()
    })
    watch(mesonero, () => {
      GetDatosCliente()
    })
    watch(descripcionCliente, () => {
      GetDatosCliente()
    })
    watch(mesa, () => {
      GetDatosCliente()
    })
    watch(tabSelectLocal, () => {
      console.log(tabSelectLocal.value)
      SelectTab(tabSelectLocal.value)
    })
    const SelectTab = item => {
      console.log(item)
      context.emit('GetTabSelect', item)
    }
    return {
      GetMesa,
      mdiInformationOutline,
      mdiFolderOutline,
      mdiCashRegister,
      nombreCliente,
      mdiMapMarker,
      mdiAccountStar,
      mdiOrderBoolDescending,
      mdiAccountSearch,
      mesonero,
      mesa,
      mdiAccount,
      mdiCartPlus,
      listaDetallesProductosRef,
      tabSelectLocal,
      nombreClienteRef,
      descripcionCliente,
      mdiNumeric1CircleOutline,
      SelectTab,
      BuscarCliente,
      GuardarComanda,
      refModalClienteVue,
      GetClienteFull,
      acciones,
      validarAcciones,
      mesaSelect,
      //nroComensales,
      reglas,
      funciones,
      mdiCheck,
      estadoFormaPagoValue,
      mdiTableChair,
    }
  },
}
</script>

    ModalMesas

<style lang="scss"  >
.lista-productos4 {
  height: calc(100vh - 154px);
  overflow-x: hidden;
  overflow-y: auto;
}

.header-producto4 {
  height: 64px;
}
</style>