<template>
    <div>
        <v-btn block color="primary" x-small @click="dialog = true">
            Ver detalles
        </v-btn>


        <v-dialog v-model="dialog" width="500">

            <v-card>
                <template slot="progress">
                    <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                </template>

                <v-img height="250" :src="`${config.api_imagenes}/producto/img/l-${producto.producto.img}`">
                </v-img>

                <v-card-title><small>{{producto.producto.nombre }}</small></v-card-title>

                <v-card-text>
                    <v-row align="center" class="mx-0">
                        <v-rating :value="5" color="primary" dense half-increments readonly size="14"></v-rating>

                        <div class="grey--text ms-4">
                            5
                        </div>
                    </v-row>

                    <div class="my-4 text-subtitle-1">
                        $ {{round(producto.producto.precio_venta* ((producto.producto.impuesto_tasa_valor / 100)+1))}} •
                        {{producto.producto.categoria_nombre}},
                        {{producto.producto.sub_categoria_nombre}}
                    </div>

                    <div>{{producto.producto.descripcion}}.</div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title v-if="producto.producto.ingredientes != null">Ingredientes</v-card-title>

                <v-card-text v-if="producto.producto.ingredientes != null">
                    <v-chip v-for="item in  producto.producto.ingredientes" :key="item.id" class="ma-1" color="success">
                        {{item.nombre}}
                    </v-chip>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>

                <v-card-title v-if="producto.producto.extras != null">Extras</v-card-title>

                <v-card-text v-if="producto.producto.extras != null">
                    <v-chip v-for="item in  producto.producto.extras" :key="item.id" class="ma-1" color="primary">
                        {{item.nombre}} (${{item.precio_venta}})
                    </v-chip>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import config from '@/api/config'
export default {
    props: {
        producto: Object
    },
    setup() {
        function round(num) {
            var m = Number((Math.abs(num) * 100).toPrecision(15));
            return Math.round(m) / 100 * Math.sign(num);
        }
        const dialog = ref(false)
        return {
            dialog,
            config,
            round
        }
    },
}
</script>