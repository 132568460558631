 
<template>
  <span>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <template>
        <v-card>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-icon size="100">{{ icon }}</v-icon>
                  <div v-if="titulo" class="text-h3 pa-3 text-center" v-html="titulo"></div>
                  <p class="text-center pt-3" v-html="subTitulo"></p>
                </v-col>

                <v-col cols="12" v-for="(item, index) in inputs" :key="index" class="my-0 py-2">
                  <v-textarea
                    :rules="item.obligatorio ? reglas.requerido : ''"
                    dense
                    outlined
                    v-if="item.tipoInput == 'textArea'"
                    :label="item.texto"
                    v-model="item.valor"
                    hide-details="auto"
                    :autofocus="item.autofocus"
                  ></v-textarea>
                  <v-select
                    v-if="item.tipoInput == 'select'"
                    :items="item.opciones"
                    dense
                    outlined
                    :label="item.texto"
                    v-model="item.valor"
                    :autofocus="item.autofocus"
                  ></v-select>
                  <v-text-field
                    v-if="item.tipoInput == 'text' || item.tipoInput == 'number'"
                    :label="item.texto"
                    v-model="item.valor"
                    dense
                    outlined
                    :autofocus="item.autofocus"
                    :rules="item.obligatorio ? reglas.requerido : ''"
                  ></v-text-field>
                  <div v-if="item.tipoInput == 'checkbox'">
                    <p>{{ item.texto }}</p>
                    <v-checkbox
                      v-for="(opcion, index) in item.opciones"
                      :key="index"
                      v-model="item.valor"
                      :label="opcion.text"
                      :value="opcion.value"
                      hide-details
                    ></v-checkbox>
                  </div>
                  <div v-if="item.tipoInput == 'radio'">
                    <p>{{ item.texto }}</p>
                    <v-radio-group v-model="item.valor" column>
                      <v-radio
                        v-for="(opcion, index) in item.opciones"
                        :key="index"
                        :label="opcion.text"
                        :value="opcion.value"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn
              :loading="loading"
              v-if="btnConfirmar"
              :color="btnConfirmar.color ? btnConfirmar.color : 'primary'"
              @click="confirmar()"
              >{{ btnConfirmar.texto ? btnConfirmar.texto : 'Confirmar' }}</v-btn
            >
            <v-btn v-if="btnDenegar" :color="btnDenegar.color ? btnDenegar.color : 'error'" @click="denegar()">{{
              btnDenegar.texto ? btnDenegar.texto : 'Denegar'
            }}</v-btn>
            <v-btn
              v-if="btnCancelar"
              :color="btnCancelar.color ? btnCancelar.color : 'secondary'"
              @click="cancelar()"
              >{{ btnCancelar.texto ? btnCancelar.texto : 'Cancelar' }}</v-btn
            >

            {{ btnDenegar }}
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
  </span>
</template>

<script>
import { ref } from '@vue/composition-api'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
export default {
  components: {
    KeyBoardEvents,
  },
  methods: {
    keyboardEvent(e) {
      if (this.dialog) {
        let tecla = e.which
       

        if (tecla == 13) {
          e.preventDefault()
          e.stopPropagation()
          this.confirmar()
          return false
        }

        if (tecla == 27) {
          e.preventDefault()
          e.stopPropagation()
          this.cancelar()
          return false
        }
      }
    },
  },
  setup(props, context) {
    const form = ref(null)
    const btnConfirmar = ref({
      funcion: null,
      visible: false,
    })
    const btnDenegar = ref({
      funcion: null,
      visible: false,
    })
    const btnCancelar = ref({
      funcion: null,
      visible: false,
    })

    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }

    const validarForm = () => {
      const val = form.value?.validate()

      return val
    }
    const titulo = ref('')
    const subTitulo = ref('')
    const icon = ref('')
    const inputs = ref([
      /*{
        valor: '', //valor ingresado por el usuario
        nombreObjeto: 'Texto', //objeto q se devolvera en la funcion callback
        texto: 'Motivo', //valor a postrar en laberl de input
        obligatorio: true, //valdiar antes de dar condifrmar
        tipoInput: 'textArea', //text,number,textArea,select,checkbox
        opciones: [], //validos para select y checkbox
      },*/
    ])

    const btnRespuesta = ref({
      confirmar: false,
      denegado: false,
      cancelado: false,
    })
    const dialog = ref(false)
    const loading = ref(false)
    const confirmar = async () => {
      let _datos = { ...btnConfirmar.value.funcionDatos }
      if (validarForm()) {
        inputs.value?.forEach(element => {
          _datos = { ..._datos, [element.nombreObjeto]: element.valor }
        })

        loading.value = true
        await btnConfirmar.value.funcion(_datos)
        dialog.value = false
      }
    }
    const denegar = () => {
      btnDenegar.value.funcion()
      // context.emit('GetModalConfirmacion', btnDenegar.value.funcion)

      dialog.value = false
    }
    const cancelar = () => {
      btnCancelar.value.funcion()
      //context.emit('GetModalConfirmacion', btnCancelar.value.funcion)
      dialog.value = false
    }
    const cerrar = _datos => {
      dialog.value = false
    }

    const abrir = _datos => {
      dialog.value = true
      loading.value = false
      btnConfirmar.value = _datos?.btnConfirmar
      btnDenegar.value = _datos?.btnDenegar
      btnCancelar.value = _datos?.btnCancelar
      titulo.value = _datos?.titulo
      subTitulo.value = _datos?.subTitulo
      icon.value = _datos?.icon
      inputs.value = _datos?.inputs
    }
    return {
      btnRespuesta,
      confirmar,
      denegar,
      cancelar,
      dialog,
      btnConfirmar,
      btnDenegar,
      btnCancelar,
      abrir,
      titulo,
      subTitulo,
      icon,
      loading,
      cerrar,
      inputs,
      form,
      reglas,
    }
  },
}
</script>