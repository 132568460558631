<template>
  <div>
    <v-btn color="primary" @click="dialog = true"> Abrir Escaner </v-btn>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <p>aqui</p>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import store from '@/store'
export default {
  components: {
    StreamBarcodeReader,
  },
  setup() {
    const dialog = ref(false)
    const onDecode = result => {
      store.commit('setAlert', {
        message: result,
        type: 'warning',
      })
      console.log(result)
      dialog.value = false
    }

    return {
      dialog,
      onDecode,
    }
  },
}
</script>