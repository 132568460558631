<template>
  <div class="text-center">
    <template v-if="verBtn">
      <v-card
        :class="label == 'Seleccionar Mesa' ? 'secondary' : 'primary'"
        class="d-flex align-center"
        dark
        height="70"
        @click="dialog = true"
      >
        <v-scroll-y-transition>
          <div class="flex-grow-1 text-center white--text">
            <v-row>
              <v-col cols="auto" class="ml-2">
                <v-icon color="white" large>{{ icons.mdiTableChair }}</v-icon> </v-col
              >
              <v-divider vertical></v-divider>
              <v-col>
                <small>
                  {{ label }}
                </small>
              </v-col>
            </v-row>
          </div>
        </v-scroll-y-transition>
      </v-card>
    </template>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-subtitle class="text-h5 grey lighten-2"> Selecciona una mesa </v-card-subtitle>
        <v-card-text v-if="cargando" class="text-center">
          <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
          <p class="mt-2">Cargando Mesas...</p>
        </v-card-text>
        <v-card-text v-if="!cargando">
          <v-col cols="12" v-if="GrupoSeleccionada.id == undefined">
            <v-row>
              <v-col lg="2" md="2" sm="4" cols="6" v-for="(item, key) in grupos" :key="key">
                <a ref="#" v-on:click="SeleccionarGrupo(item)">
                  <v-card>
                    <v-img
                      :src="require(`@/assets/images/logos/${logoI}`)"
                      :gradient="`to bottom, rgba(${item.color},.5), rgba(${item.color})`"
                      height="100px"
                    >
                      <small class="text-img-catgoria"> {{ item.descripcion }}</small>
                    </v-img>
                  </v-card>
                </a>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="MesaSeleccionada.id == undefined && GrupoSeleccionada.id != undefined">
            <v-row>
              <v-col lg="3" md="3" sm="4" cols="6">
                <a ref="#" v-on:click="SeleccionarGrupo(null)">
                  <v-card>
                    <v-img
                      :src="require(`@/assets/images/logos/${logoI}`)"
                      gradient="to bottom, rgba(145, 85, 253 ), rgba(145, 85, 253 )"
                      height="100px"
                    >
                      <small class="text-img-catgoria">ATRAS</small>
                    </v-img>
                  </v-card>
                </a>
              </v-col>
              <v-col lg="3" md="3" sm="4" cols="6" v-for="(item, key) in mesas" :key="key" class="mt-4">
                <a ref="#" v-on:click="SeleccionarMesa(item)">
                  <v-card
                    @click="SeleccionarMesa(item)"
                    :class="colorEstadoDisponibilidad2(item.estado_disponibilidad)"
                  >
                    <v-row>
                      <v-col cols="5" style="height: 100px" class="text-center">
                        <v-img src="@/assets/images/mesas/mesa_4.png" width="50px"> </v-img>

                        <small class="text-center white--text">{{ item.nombre.split(' ')[0] }}</small>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col class="text-center ml-0 pl-0">
                        <span>
                          <br />
                          <h3 class="text-center">
                            <span class="white--text">{{ item.nombre.split(' ')[1] }}</span>
                          </h3>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MaestroService from '@/api/servicios/MaestroService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiSeatOutline, mdiTableChair } from '@mdi/js'
export default {
  props: {
    value:Boolean,
    label: {
      type: String,
      default: 'Seleccionar mesa',
    },
    verBtn: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    dialog: function (_new, _old) {
      if (_new != _old) {
        this.retornar()
      }
      
    },
    value: function (_new, _old) {
      if (_new != this.dialog) {
        this.dialog = _new
      }
    },
  },
  setup(props, context) {
    const logoI = process.env.VUE_APP_IMG_LOGO
    onBeforeMount(() => {
      cargarMesas()
      SeleccionarGrupo(null)
    })
    const GrupoSeleccionada = ref({
      id: null,
    })
    const MesaSeleccionada = ref({
      id: null,
    })
    const retornar = () => {
      context.emit('input', dialog.value)
    }
    const grupos = ref([])
    const dialog = ref(false)
    const mesas = ref([])
    watch(dialog, () => {
      if (dialog.value == true) {
        SeleccionarGrupo(null)
        cargarMesas()
      }
    })
    const cargando = ref(false)
    const cargarMesas = () => {
      cargando.value = true
      MaestroService.GruposMesasListar()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            grupos.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const SeleccionarGrupo = item => {
      if (item != null) {
        GrupoSeleccionada.value = item
        mesas.value = item.mesa
        MesaSeleccionada.value = {}
      } else {
        GrupoSeleccionada.value = {}
      }
    }

    const SeleccionarMesa = item => {
      if (item != null) {
        MesaSeleccionada.value = item
        dialog.value = false
        GetMesa({
          ...item,
          mesa: item.id,
          grupo_mesa: GrupoSeleccionada.value.descripcion,
        })
      } else {
        MesaSeleccionada.value = {}
      }
    }

    const colorEstadoDisponibilidad2 = id => {
      switch (id) {
        case 52:
          return 'primary'
          break
        case 53:
          return 'info'
          break
        case 54:
          return 'warning'
          break
        case 55:
          return 'warning'
          break
        case 56:
          return 'error'
          break
        default:
          return 'primary'
          break
      }
    }
    const GetMesa = item => {
      context.emit('GetMesa', item)
    }
    return {
      cargarMesas,
      dialog,
      grupos,
      cargando,
      GrupoSeleccionada,
      MesaSeleccionada,
      SeleccionarGrupo,
      mesas,
      SeleccionarMesa,
      GetMesa,
      logoI,
      retornar,
      colorEstadoDisponibilidad2,
      icons: {
        mdiSeatOutline,
        mdiTableChair,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.text-img-catgoria {
  top: 0rem;
  padding-top: 35px;
  height: 100%;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}
</style>