<template>
  <div>
    <v-card>
      <p class="text-2xl mb-6">Reporte Cierre Ventas Dia</p>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="4">
                <DatePicker label="Fecha" v-model="filtros.fecha" :dense="true" :outlined="true" />
              </v-col>
              <v-col cols="12" md="4">
                <SelectPuesto2Vue
                  v-model="filtros.id_puesto"
                  :add="false"
                  :dense="true"
                  :outlined="true"
                  :filtros="{ modulos: '104' }"
                />
              </v-col>
              <v-col cols="12" md="4">
               
                <v-select
                  :items="impresoras" 
                   v-model="filtros.id_grupo_impresora_pc"
                  item-value="id"
                  item-text="nombre"
                  label="Tickera"
                  dense
                  outlined
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn class="primary mr-2">PDF</v-btn>
                <v-btn class="primary" @click="imprimirTiket()" >Tiket</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { onBeforeMount, ref } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker.vue'
import SelectPuesto2Vue from '../../administracion/archivo/puesto/components/SelectPuesto2.vue'
import moment from 'moment'
import MaestroService from '@/api/servicios/MaestroService'
import store from '@/store'
import { acciones } from '@/modulos'
import ComandaService from '@/api/servicios/ComandaService'
// demos

export default {
  components: {
    DatePicker,
    SelectPuesto2Vue,
  },
  setup() {
    const filtros = ref({
      fecha: moment().format('YYYY-MM-DD'),
      id_puesto: null,
      id_grupo_impresora_pc: store.state.tikera
    })

    onBeforeMount(()=>{
      consultarImpresoras()
    })
    const impresoras = ref([])

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const imprimirTiket =()=>{
      ComandaService.imprimirCierrePuesto({...filtros.value})
      .then(response=>{
          
      })
      .catch(error=>{

      })
      .finally(()=>{

      })
    }
    const consultarImpresoras = () => {
      MaestroService.obtenerGrupoImpresora()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            impresoras.value = []

            if (validarAcciones(acciones.DASHBOARD_ADMINISTRACION_VER)) {
              impresoras.value = response.data.datos
            } else {
              if (validarAcciones(acciones.DASHBOARD_COMANDA_IMPRIMIR)) {
                impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_mesoneros == true))
              }
              if (validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)) {
                impresoras.value = impresoras.value.concat(response.data.datos.filter(its => its.ind_puestos == true))
              }
            }
          }
        })
        .catch(erro => {
          console.error(erro)
        })
    }
    return {
      filtros,
      impresoras,
      imprimirTiket
    }
  },
}
</script>
  