<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Separar Comandas </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="6">
                <v-subheader>Comanda #{{ comanda.id }} {{ comanda.cliente }}</v-subheader>

                <v-list flat subheader style="height: 300px; overflow-y: auto">
                  <v-list-item-group active-class="">
                    <v-list-item v-for="(item, index) in itemsComandaOld" :key="index" @click="MoverItemOld(item)">
                      <template>
                        <v-list-item-icon>
                          <v-badge bordered top color="primary" offset-x="10" offset-y="10" :content="item.cantidad">
                            <v-avatar size="30">
                              <v-img :src="`${config.api_imagenes}/assets/images/product/${item.producto.img}`">
                              </v-img>
                            </v-avatar>
                          </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            ><small>{{ item.producto.nombre }}</small>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>
                            <strong>{{ round(item.total + item.iva).toFixed(2) }}$</strong>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
                <strong class="mt-2">TOTAL: {{ round(total).toFixed(2) }}$</strong>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col md="6">
                <v-subheader>Comanda Nueva.</v-subheader>
                <v-list flat subheader style="height: 300px; overflow-y: auto">
                  <v-list-item-group active-class="">
                    <v-list-item v-for="(item, index) in itemsComandaNew" :key="index" @click="MoverItemNew(item)">
                      <template>
                        <v-list-item-icon>
                          <v-badge bordered top color="primary" offset-x="10" offset-y="10" :content="item.cantidad">
                            <v-avatar size="30">
                              <v-img :src="`${config.api_imagenes}/assets/images/product/${item.producto.img}`">
                              </v-img>
                            </v-avatar>
                          </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            ><small>{{ item.producto.nombre }}</small>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>
                            <strong>{{ round(item.total + item.iva).toFixed(2) }}$</strong>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
                <strong  class="mt-2">TOTAL: {{ totalNew.toFixed(2) }}$</strong>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions> 
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="Separar()" :disabled="total <= 0 || cargando == true" :loading="cargando">
            Separar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiCallSplit } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
export default {
  props: {
    comanda: Object,
    color: String,
    modalSeparar: Boolean,
  },
  setup(props, context) {
    const settings = ref(null)
    const settings2 = ref(null)
    const detalles = ref([])
    const total = ref(0)
    const dialog = ref(false)
    const itemsComandaOld = ref([])
    const itemsComandaNew = ref([])
    const comanda_detalle = ref({})
    const cargando = ref(false)

    watch(props, () => {
      if (props.modalSeparar != dialog.value) {
        dialog.value = props.modalSeparar
      }

      if (dialog.value == true) {
        ComandaService.listarComanda({ id: props.comanda.id })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              comanda_detalle.value = response.data.datos
              itemsComandaOld.value = comanda_detalle.value.productos
              itemsComandaNew.value = []
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    })
    watch(dialog, () => {
      context.emit('GetModalSeparar', dialog.value)
      if (dialog.value == true) {
        settings.value = null
      }
    })

    const Separar = () => {
      cargando.value = true
      const comanda = { ...props.comanda, productos: itemsComandaNew.value , id_comanda_destino : -1}
      console.log(comanda)

      ComandaService.separarComanda(comanda)
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'COMANDA CREADA CON EXITO') {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'success',
            })
            dialog.value = false
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response) { 
            alert("aqui")
          }

          store.commit('setAlert',{
              message: error,
              type: 'error',
              error: {
                ...error,
                response:error?.response?.data
              },
              funcion: 'Separar',
            })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const MoverItemOld = settings => {
      if (
        itemsComandaOld.value.length > 1 ||
        (itemsComandaOld.value.length == 1 && itemsComandaOld.value[0].cantidad > 1)
      ) {
        let itemNew = itemsComandaNew.value
        let itemOld = []
        const itemNewTemp = itemsComandaNew.value.find((item, i) => item.id == settings.id)
        console.log('itemNewTemp', itemNewTemp)
        if (itemNewTemp) {
          itemNewTemp.cantidad += 1
          itemNewTemp.total = itemNewTemp.cantidad * itemNewTemp.precio_real
          itemNewTemp.sub_total = itemNewTemp.cantidad * itemNewTemp.precio_real
          itemNewTemp.iva =
            itemNewTemp.cantidad * (itemNewTemp.precio_real * (itemNewTemp.producto.impuesto_tasa_valor / 100))
        } else {
          itemNew.push({
            ...settings,
            cantidad: 1,
            total: settings.precio_real,
            sub_total: settings.precio_real,
            iva: settings.precio_real * (settings.producto.impuesto_tasa_valor / 100),
          })
          itemsComandaNew.value = itemNew
        }

        if (settings.cantidad == 1) {
          itemOld = itemsComandaOld.value.filter((item, i) => item.id != settings.id)
          itemsComandaOld.value = itemOld
        } else {
          settings.cantidad -= 1
          settings.total = settings.cantidad * settings.precio_real
          settings.sub_total = settings.cantidad * settings.precio_real
          settings.iva = settings.cantidad * (settings.precio_real * (settings.producto.impuesto_tasa_valor / 100))
        }

        //total.value = tot
      } else {
        store.commit('setAlert', {
          message: 'no puede dejar la comanda sin productos',
          type: 'warning',
        })
      }
    }

    const MoverItemNew = settings => {
      if (
        itemsComandaNew.value.length > 1 ||
        (itemsComandaNew.value.length == 1 && itemsComandaNew.value[0].cantidad > 1)
      ) {
        let itemNew = itemsComandaOld.value
        let itemOld = []
        const itemNewTemp = itemsComandaOld.value.find((item, i) => item.id == settings.id)
        console.log('itemNewTemp', itemNewTemp)
        if (itemNewTemp) {
          itemNewTemp.cantidad += 1
          itemNewTemp.total = itemNewTemp.cantidad * itemNewTemp.precio_real
          itemNewTemp.sub_total = itemNewTemp.cantidad * itemNewTemp.precio_real
          itemNewTemp.iva =
            itemNewTemp.cantidad * (itemNewTemp.precio_real * (itemNewTemp.producto.impuesto_tasa_valor / 100))
        } else {
          itemNew.push({
            ...settings,
            cantidad: 1,
            total: settings.precio_real,
            sub_total: settings.precio_real,
            iva: settings.precio_real * (settings.producto.impuesto_tasa_valor / 100),
          })
          itemsComandaOld.value = itemNew
        }

        if (settings.cantidad == 1) {
          itemOld = itemsComandaNew.value.filter((item, i) => item.id != settings.id)
          itemsComandaNew.value = itemOld
        } else {
          settings.cantidad -= 1
          settings.total = settings.cantidad * settings.precio_real
          settings.sub_total = settings.cantidad * settings.precio_real
          settings.iva = settings.cantidad * (settings.precio_real * (settings.producto.impuesto_tasa_valor / 100))
        }

        //total.value = tot
      } else {
        store.commit('setAlert', {
          message: 'no puede dejar la comanda sin productos',
          type: 'warning',
        })
      }
      //total.value = tot
    }

    watch(itemsComandaOld, () => {
      let tol = 0
      itemsComandaOld.value.forEach(item => {
        tol += item.total + item.iva
      })

      total.value = tol
    })

    const totalNew = ref(0)
    watch(itemsComandaNew, () => {
      let tol = 0
      itemsComandaNew.value.forEach(item => {
        tol += item.total + item.iva
      })

      totalNew.value = tol
    })

    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    return {
      icons: {
        mdiCallSplit,
      },
      dialog,
      settings,
      detalles,
      config,
      round,
      total,
      itemsComandaOld,
      itemsComandaNew,
      MoverItemOld,
      MoverItemNew,
      Separar,
      cargando,
      totalNew,
    }
  },
}
</script>