<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Cajas </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="(item, i) in cajas" :key="i" cols="6" sm="4" md="3">
              <v-card
                :color="item.ind_facturacion ? 'primary' : 'secondary'"
                class="d-flex align-center white--text"
                height="100"
                @click="abrirDialgo2(item)"
              >
                <v-scroll-y-transition>
                  <div class="text flex-grow-1 text-center white--text">
                    {{ item.nombre }}
                    <br />
                    <small>{{ item.nombre_completo }}</small>
                    <br />
                    <small v-if="item.ind_facturacion">FACTURANDO</small>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small append @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" scrollable max-width="400px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Asignar cajero </v-card-title>
        <v-card-text>
          <v-autocomplete
            dense
            outlined
            :items="cajeros"
            v-model="cajeroSelect"
            item-text="nombre_completo"
            item-value="id"
            label="Cajero"
            hide-details="auto"
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small append @click="dialog2 = false"> Cerrar </v-btn>
          <v-btn color="primary" small append @click="vincularCaja()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mdiCallSplit } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import SeguridadService from '@/api/servicios/SeguridadService'
import store from '@/store'
import ModalValidarLoginVue from '@/views/sistema/seguridad/ModalValidarLogin.vue'
import MaestroService from '@/api/servicios/MaestroService'
import Vue from 'vue'
export default {
  components: {
    ModalValidarLoginVue,
  },
  props: {
    modalTransferir: Boolean,
  },
  setup(props, context) {
    const dialog = ref(false)
    const cajas = ref([])
    const cajeros = ref([])
    const cajaSelect = ref(null)
    const cajeroSelect = ref(null)
    const dialog2 = ref(null)
    const abrirDialgo2 = item => {
      if (!item.empleado_nombre) {
        cajaSelect.value = item
        dialog2.value = true
      } else {
        desasignarCajero(item)
      }
    }

    const desasignarCajero = item => {
      Vue.swal({
        icon: 'info',
        text: 'Esta seguro que quiere desvincular el usuario de esta caja',
        showCancelButton: true,
        confirmButtonText: 'Si',
        showLoaderOnConfirm: true,
        cancelButtonText: 'no',

        preConfirm: login => {
          return MaestroService.cajaDesvincularEmpleado({ id_caja: item.id, id_empleado: cajeroSelect.value })
            .then(response => {
              try {
                abrir()
                Vue.swal({
                  icon: 'success',
                  title: `Exito.`,
                  text: response.data.mensaje,
                })
              } catch (error) {
                Vue.swal({
                  icon: 'error',
                  title: `Oops...`,
                  text: `hubo un error: ${error}`,
                })
              }

              return true
            })
            .catch(error => {
              Vue.swal({
                icon: 'error',
                title: `Oops...`,
                text: `hubo un error: ${error}`,
              })

              return false
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        console.log(result)
      })
    }

    const vincularCaja = () => {
      MaestroService.cajaVincularEmpleado({ id_caja: cajaSelect.value.id, id_empleado: cajeroSelect.value })
        .then(response => {
          console.log(response)
          abrir()
          dialog2.value = false
          if (response.data.mensaje == 'EMPLEADO VINCULADO') {
            //  mesoneros.value =response.data.datos
            cajas.value = response.data.datos
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
            error: response,
                funcion: 'vincularCaja',
            })
          }
        })
        .catch(error => {
          console.log(error)
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'vincularCaja',
          })
        })
    }

    const abrir = () => {
      dialog.value = true
      MaestroService.CajasListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            //  mesoneros.value =response.data.datos
            cajas.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })

      MaestroService.CajerosListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            //  mesoneros.value =response.data.datos
            cajeros.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      icons: {
        mdiCallSplit,
      },
      dialog,
      cajas,
      cajeros,
      abrir,
      dialog2,
      abrirDialgo2,
      cajaSelect,
      cajeroSelect,
      vincularCaja,
    }
  },
}
</script>