<template>
  <span>
   
    


        <v-hover v-slot="{ hover }">
            <v-card   :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }"  @click="dialog = true">
              <v-card-text class="text-center py-1  ">
                <v-icon color="primary">{{ icons.mdiCallMerge }} </v-icon>
              
                <small v-if="indLabel" >   <br /> <strong>Unir comanda</strong> </small>
              </v-card-text>
            </v-card>
          </v-hover>
     
      


     
     

    <v-dialog v-model="dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Unir Comandas </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field hide-details outlined dense label="Filtrar" v-model="filtoComanda"></v-text-field>
              </v-col>
              <v-col lg="6">
                <span>Comandas</span>

                <v-list style="height: 300px; overflow-y: auto">
                  <v-list-item
                    @click="seleccionar(item)"
                    :disabled="item.ind_bloqueado"
                    v-for="(item, index) in filtrar()"
                    :key="index"
                  >
                    <template>
                      <v-list-item-icon>
                        <v-icon color="primary" v-if="item.seleccionado">{{ icons.mdiCheckboxMarked }}</v-icon>
                        <v-icon v-else>{{ icons.mdiCheckboxBlankOutline }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>#{{ item.id }} {{ item.cliente }} </v-list-item-title>
                        <v-list-item-subtitle>{{ item.mesonero_nombre }} </v-list-item-subtitle>
                        <v-list-item-subtitle>{{ item.mesa_nombre }} </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <small v-if="item.ind_bloqueado">(Bloqueada)</small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon> ${{ item.total }} </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col lg="6">
                <span>Comandas</span>
                <v-list subheader flat style="height: 300px; overflow-y: auto">
                  <div v-for="(item, index) in listaComanda.filter(item => item.seleccionado)" :key="index">
                    <template v-for="(item, index2) in item.detalles">
                      <v-divider :key="'divider' + index2" inset></v-divider>
                      <v-list-item :key="'item' + index2">
                        <v-list-item-icon>
                          <v-badge bordered top color="primary" offset-x="10" offset-y="10" :content="item.cantidad">
                            <v-avatar size="30">
                              <v-img :src="`${config.api_imagenes}/assets/images/product/${item.img}`"> </v-img>
                            </v-avatar>
                          </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            ><small>{{ item.nombre_producto }}</small>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text>
                            <strong>{{ round(item.total_iva).toFixed(2) }}$</strong>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <span>Total: {{ round(totalizador()).toFixed(2) }}$</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="UnirComanda()" :disabled="listaComanda.filter(item => item.seleccionado).length <= 1"> Unir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import { mdiCallMerge, mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import ComandaService from '@/api/servicios/ComandaService'
import store from '@/store'
export default {
  props: {
    comandas: Array,
    indLabel:{
      type:Boolean,
      default:true
    }
  },
  setup(props) {
    const settings = ref([])
    const detalles = ref([])
    const total = ref(0)
    const dialog = ref(false)
    const filtoComanda = ref('')
    const seleccionados = ref([])
    const listaComanda = ref([])
    watch(dialog, () => {
      listaComanda.value = []
      props.comandas.forEach(item => {
        listaComanda.value.push({ ...item, seleccionado: false })
      })
    })

    let comandaPrincipal = null
    const seleccionar = item => {
      item.seleccionado = !item.seleccionado
      if (listaComanda.value.filter(item => item.seleccionado).length == 1) {
        comandaPrincipal = listaComanda.value.filter(item => item.seleccionado)[0].id
      }
    }

    const filtrar = () => {
      const valor = listaComanda.value?.filter(
        item =>
          filtoComanda.value == '' ||
          item.id.toString().includes(filtoComanda.value) ||
          item.cliente.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
          item.mesonero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
          item.mesa_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
          item.comandero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()),
      )

      return valor
    }

    const totalizador = () => {
      let tot = 0
      listaComanda.value
        ?.filter(item => item.seleccionado)
        .forEach(elem => {
          tot += elem.total
        })

      return tot
    }

    watch(settings, () => {
      console.log(settings)
      let array = []
      let tot = 0
      settings.value.forEach(element => {
        props.comandas[element].detalles.forEach(i => {
          array.push(i)
        })
        tot += props.comandas[element].total
      })
      detalles.value = array

      total.value = tot
    })
    const uniendo = ref(false)

    const UnirComanda = () => {
      uniendo.value = true
      let comandas = []
      listaComanda.value.forEach((element, i) => {
        if (element.seleccionado && element.id != comandaPrincipal) {
          comandas.push({ id_comanda: element.id })
        }
      })

      console.log({ id_comanda_principal: comandaPrincipal, comandas: comandas })
      ComandaService.unirComanda({ id_comanda_principal: comandaPrincipal, comandas: comandas })
        .then(response => {
          if (response.data.mensaje == 'unido con exito') {
            store.commit('setAlert', {
              message: 'unido con exito',
              type: 'info',
            })
            dialog.value = false
          } else {
            store.commit('setAlert', {
              message: 'ups, ' + response.data.mensaje,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: 'upd, hubo un error' + error,
            type: 'info',
          })
        })
        .finally(datos => {
          uniendo.value = false
        })
    }

    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    return {
      icons: {
        mdiCallMerge,
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
      },
      dialog,
      settings,
      detalles,
      config,
      round,
      total,
      UnirComanda,
      filtoComanda,
      filtrar,
      listaComanda,
      totalizador,
      seleccionar,
    }
  },
}
</script>