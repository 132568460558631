<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
      <v-card v-if="arqueoFinal.id == 0">
        <v-card-title>
          <span class="text-h5">Arqueo Caja</span>
        </v-card-title>

        <v-card-text style="max-height: 500px">
          <v-container>
            <v-row>
              <v-col cols="12">
                <h4>Formas de pago</h4>
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Tipo Pago</th>
                              <th class="text-right">Monto</th>
                            </tr>
                          </thead>
                          <tbody v-for="(mo, index) in monedas" :key="index">
                            <tr>
                              <td colspan="2" class="text-center secondary">
                                <span class="white--text">{{ mo.nombre }} </span>
                              </td>
                            </tr>
                            <tr v-for="(tp, index) in mo.tipo_pago" :key="index">
                              <td>{{ tp.nombre }}</td>
                              <td>
                                <v-text-field
                                  class="my-1"
                                  :prefix="mo.simbolo"
                                  v-model="tp.monto_moneda"
                                  outlined
                                  clearable
                                  dense
                                  type="number"
                                  hide-details
                                />
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Salir </v-btn>
          <v-btn color="warning" small @click="imprimirCorte()"> Impimir Corte </v-btn>
          <v-btn color="primary" small @click="guardar()"> Procesar </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="arqueoFinal.id > 0 && ind_cerrar == false">
        <v-card-title>
          <span class="text-h5">Arqueo Caja # {{ arqueoFinal.id }} </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-row>
              <v-col cols="6">
                <span>Caja: {{ arqueoFinal.caja.nombre }} </span> <br />
                <span>Empleado: {{ arqueoFinal.empleado.nombre }} {{ arqueoFinal.empleado.apellido }}</span
                ><br />
                <span
                  >Empleado Autoriza: {{ arqueoFinal.empleado_autoriza.nombre }}
                  {{ arqueoFinal.empleado_autoriza.apellido }}</span
                >
              </v-col>
              <v-col cols="6">
                <span>Fecha: {{ funciones.formatoFecha(arqueoFinal.fecha_creacion) }} </span> <br />
                <span>Hora: {{ funciones.formatoFecha(arqueoFinal.fecha_creacion, 2) }} </span> <br />
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col cols="12">
                <p>Detalles</p>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Moneda</th>
                        <th class="text-left">Tipo Pago</th>
                        <th class="text-right">Monto Sistema</th>
                        <th class="text-right">Monto Caja</th>
                        <th class="text-right">Diferencias</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in arqueoFinal.detalles" :key="index">
                        <td>{{ item.tipo_pago.moneda.nombre }}</td>
                        <td>{{ item.tipo_pago.nombre }}</td>
                        <td class="text-right">
                          {{ item.tipo_pago.moneda.simbolo }} {{ item.pago_moneda.toFixed(2) }}
                        </td>
                        <td class="text-right">
                          {{ item.tipo_pago.moneda.simbolo }} {{ item.pago_moneda_usuario.toFixed(2) }}
                        </td>
                        <td class="text-right">
                          <span
                            :class="item.diferencia < 0 ? 'error--text' : item.diferencia > 0 ? 'success--text' : ''"
                            >{{ item.tipo_pago.moneda.simbolo }} {{ item.diferencia.toFixed(2) }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-6"></v-divider>
          <div>
            <v-row>
              <v-col cols="6">
                <span>Total Ventas: $ {{ arqueoFinal.totales.total_venta.toFixed(2) }}</span> <br />
                <span>Total Propinas: $ {{ arqueoFinal.totales.total_propina.toFixed(2) }}</span> <br />
                <span>Total Entradas: $ {{ arqueoFinal.totales.total_entradas.toFixed(2) }}</span> <br />
                <span>Total Salidas: $ {{ arqueoFinal.totales.total_salidas.toFixed(2) }}</span> <br />
                <span>Total Transferencias: $ {{ arqueoFinal.totales.total_transferencias.toFixed(2) }}</span> <br />
              </v-col>
              <v-col cols="6" class="text-center">
                <span>Total {{ arqueoFinal.totales.total_diferencias > 0 ? 'Sobrante' : 'Faltante' }} </span> <br />
                <h3>
                  <span
                    :class="
                      arqueoFinal.totales.total_diferencias < 0
                        ? 'error--text'
                        : arqueoFinal.totales.total_diferencias > 0
                        ? 'success--text'
                        : ''
                    "
                  >
                    $ {{ arqueoFinal.totales.total_diferencias.toFixed(2) }}</span
                  >
                </h3>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Salir </v-btn>
          <v-btn color="secondary" v-if="false" small> Ver detallado </v-btn>
          <v-btn color="info" @click="imprimirInfoImpresoraDiferencias()" small> Imprimir </v-btn>
          <v-btn color="primary" @click="cerrar()" small> Cerrar caja </v-btn>
          <v-btn color="info" v-if="false" small> PDF </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="ind_cerrar">
        <v-card-text>
          <h4>Puntos de venta</h4>
          <p>Ingrese los datos de los puntos de ventas.</p>
          <p class="error--text">Importante.</p>
          <p class="error--text">
            1. Si los montos no coinciden con el punto de venta no ingrese ningun lote hasta que los montos coinsidan en
            su totalidad.
          </p>
          <p class="error--text">2. No es obligatorio suministrar los datos del punto de venta para cerrar la caja.</p>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Tipo Pago</th>
                  <th class="text-left">Monto</th>
                  <th class="text-left">Lotes</th>
                </tr>
              </thead>
              <tbody v-for="(pv, index) in punto_venta" :key="index">
                <tr>
                  <td colspan="3" class="text-center secondary">
                    <span class="white--text">{{ pv.banco_nombre }} | {{ pv.codigo }}  <v-btn @click="abrirTransacciones(pv.id)" x-small class="info" >Transacciones</v-btn> </span>
                  </td>
                </tr>
                <tr v-for="(tp, index) in pv.tipo_pago" :key="index">
                  <td>
                    {{ tp.moneda_nombre }} <br />
                    {{ tp.tipo_pago_nombre }}
                  </td>

                  <td>
                    <v-text-field
                      v-if="false"
                      class="my-1"
                      :prefix="tp.moneda_simbolo"
                      v-model="tp.monto_moneda"
                      outlined
                      clearable
                      dense
                      type="number"
                      hide-details
                    />
                    {{ tp.moneda_simbolo }} {{ tp.monto_moneda_sistema }}
                  </td>
                  <td>
                    <v-text-field
                      label="Lote"
                      class="my-1"
                      v-model="tp.lote"
                      outlined
                      clearable
                      dense
                      type="text"
                      hide-details
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Salir </v-btn>
          <v-btn color="primary" @click="cerrar(1)" small> Cerrar Caja</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirmacion ref="ModalConfirmacionRef" />
    <PuntoVentaTransaccionesActual ref="PuntoVentaTransaccionesActualRef" />
  </v-row>
</template>

 
<script>
import { ref, watch } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import ComandaService from '@/api/servicios/ComandaService'
import CuentasService from '@/api/servicios/CuentasService'

import store from '@/store'
import funciones from '@/funciones/funciones'
import { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiSwapVerticalCircleOutline, mdiInformation } from '@mdi/js'
import ModalConfirmacion from '../../../../../components/ModalConfirmacion.vue'
import PuntoVentaTransaccionesActual from '../../../administracion/banco-caja/punto-venta/components/PuntoVentaTransaccionesActual.vue'
import BtnPdf from '../../../puesto/inventario/ajuste/componentes/BtnPdf.vue'
export default {
  components: { ModalConfirmacion, PuntoVentaTransaccionesActual, BtnPdf },
  watch: {
    'pago.moneda': function (news, old) {
      if (news != undefined && news?.tipo_pago != undefined) {
        console.log('news', news?.tipo_pago)
        this.SelectFormaPago(news?.tipo_pago[0])
      }

      this.pago.monto_real = ''
      this.pago.monto_moneda = ''
    },
    tipo: function (news, old) {
      if (news + 1 != 3) {
        this.pago.id_empleado_destino = null
        this.pago.id_caja_destino = null
        this.validarForm()
      }
    },
  },
  setup() {
    const imprimirInfoImpresoraDiferencias = () => {
      MaestroService.imprimirArqueoCajaDiferencias({
        id_arqueo: arqueoFinal.value.id,
        id_grupo_impresora_pc: store.state.tikera.id,
      })
        .then(response => {
          try {
            if (response.data.mensaje == 'guardado con exito')
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
            else {
            }
          } catch (error) {
            store.commit('setAlert', {
              message: error,
              type: 'error',
              
            error: error,
                funcion: 'imprimirInfoImpresoraDiferencias',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'imprimirInfoImpresoraDiferencias',
          })
        })
    }
    const PuntoVentaTransaccionesActualRef = ref(null)
    const ModalConfirmacionRef = ref(null)

    const abrirTransacciones = id_punto_venta => {
      PuntoVentaTransaccionesActualRef.value.abrir(id_punto_venta)
    }

    const ind_cerrar = ref(false)
    const cerrar = (_valor = 0) => {
      if (punto_venta.value.length > 0) {
        ind_cerrar.value = true
      } else {
        ind_cerrar.value = false
        _valor = 1
      }

      if (_valor == 1) {
        let _punto_venta = []

        punto_venta.value.forEach(it => {
          it.tipo_pago.forEach(tp => {
            if (tp.monto_moneda > 0) {
              _punto_venta.push({
                id_punto_venta: it.id,
                id_tipo_pago: tp.id_tipo_pago,
                lote: tp.lote,
                monto_moneda: tp.monto_moneda,
              })
            }
          })
        })

        const _datos = {
          id_empleado: datos.value.id_empleado_responsable,
          id_log_arqueo_caja: arqueoFinal.value.id,
          puntos_ventas: _punto_venta,
        }

        console.log(_datos)

        ModalConfirmacionRef.value.abrir({
          subTitulo: '¿Esta seguro que quiere cerra la caja?',
          icon: mdiInformation,

          btnConfirmar: {
            esperarRespuesta: true,
            texto: 'Si',
            color: 'primary',
            funcion: async () => {
              ind_cerrar.value = true
              await ComandaService.ArqueoCajaCerrarActualizar(impresora)
                .then(response => {
                  try {
                    Vue.swal({
                      icon: 'success',
                      title: `Exito.`,
                      text: `Se envio el reporte X a la impresora. ${response.data}`,
                    })
                  } catch (error) {
                    Vue.swal({
                      icon: 'error',
                      title: `Oops...`,
                      text: `hubo un error: ${error}`,
                    })
                  }
                })
                .catch(error => {
                  Vue.swal({
                    icon: 'error',
                    title: `Oops...`,
                    text: `hubo un error: ${error}`,
                  })
                })
              ModalConfirmacionRef.value.cerrar()
            },
          },
          btnCancelar: {
            texto: 'No',
            color: 'secondary',
            funcion: () => {
              ModalConfirmacionRef.value.cerrar()
            },
          },
        })
      }
    }

    const imprimirCorte = () => {
      MaestroService.imprimirArqueoCajaTikera({
        id_grupo_impresora_pc: store.state.tikera.id,
        id_caja: store.state.user.caja.id,
      })
        .then(response => {
          try {
            if (response.data.mensaje == 'guardado con exito')
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
            else {
            }
          } catch (error) {
            store.commit('setAlert', {
              message: error,
              type: 'error',
            error: error,
                funcion: 'imprimirCorte',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'imprimirCorte',
          })
        })
    }

    const datos = ref({
      id_caja: 0,
      id_empleado: 0,
      id_empleado_responsable: 0,
      detalles: [],
    })
    const pagos = ref([])
    const comanda_pagoINIT = {
      id: 0,
      id_caja_origen: 0,
      id_caja_destino: 0,
      id_empleado_origen: 0,
      id_empleado_destino: 0,
      id_empleado_responsable: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      id_tipo_movimiento_caja: 1,
      id_tipo_pago: '',
      monto_moneda: '',
      monto_real: '',
      descripcion: '',
      tasa: 0,
      id_estado: 81,
    }
    const form = ref(null)
    const pago = ref(
      JSON.parse(
        JSON.stringify({
          ...comanda_pagoINIT,
          id_caja_origen: store.state.user.caja.id,
        }),
      ),
    )
    const punto_venta = ref([])
    const dialog = ref(false)
    const tipo = ref(0)
    const tipos = ref([
      { id: 1, descripcion: 'Entrada', icon: mdiPlusCircleOutline },
      { id: 2, descripcion: 'Salida', icon: mdiMinusCircleOutline },
      { id: 3, descripcion: 'Transferencia', icon: mdiSwapVerticalCircleOutline },
    ])
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }

    const arqueoFinal = ref({
      id: 0,
    })

    const guardar = () => {
      datos.value.detalles = []
      monedas.value.forEach(mo => {
        mo.tipo_pago.forEach(tp => {
          if (tp.monto_moneda > 0 || tp.monto_moneda < 0) {
            datos.value.detalles.push({
              id_tipo_pago: tp.id,
              monto_moneda: tp.monto_moneda,
            })
          }
        })
      })

      console.log(datos)
      //ArqueoCajaCerrarActualizar
      if (datos.value.detalles.length > 0) {
        ComandaService.ArqueoCajaActualizar(datos.value)
          .then(response => {
            if (response.data.estatus) {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })

              arqueoFinal.value = response.data.datos

              limpiar()
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'guardar',
            })
          })

        console.log('guardar', datos.value)
      }
    }
    const limpiar = () => {
      pago.value = JSON.parse(
        JSON.stringify({
          ...comanda_pagoINIT,
        }),
      )
      ind_cerrar.value = false
      monedas.value = JSON.parse(
        JSON.stringify({
          ...monedas_temp.value,
        }),
      )
    }
    const agregar = () => {
      if (validarForm()) {
        pagos.value.push(pago.value)
        limpiar()
      }
    }
    const cajas = ref([])
    const EmpleadoEncargado = ref(null)
    const abrir = _dato => {
      console.log(_dato)
      dialog.value = true
      CargarDatosGenerales()
      puntoVentaCajaListar()
      pagos.value = []
      datos.value.id_caja = store.state.user.caja.id
      datos.value.id_empleado = store.state.user.id_empleado
      datos.value.id_empleado_responsable = _dato.id_empleado_autoriza
      datos.value.detalles = []
      limpiar()
      arqueoFinal.value.id = 0
    }
    const calcularMontoDolar = valor => {
      console.log(valor)
      if (valor == 1) {
        pago.value.monto_real = (Number(pago.value.monto_moneda) / Number(pago.value.moneda.tasa)).toFixed(2)
      } else {
        pago.value.monto_moneda = (Number(pago.value.monto_real) * Number(pago.value.moneda.tasa)).toFixed(2)
      }
    }
    const cargarCajas = () => {
      MaestroService.CajasListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            //  mesoneros.value =response.data.datos
            cajas.value = response.data.datos.filter(item => item.empleado_actual != null)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const monedas = ref([])
    const monedas_temp = ref([])
    const CargarDatosGenerales = () => {
      ComandaService.obtenerDatosGeneralesComanda()
        .then(response => {
          monedas_temp.value = response.data.datos.monedas
          monedas.value = monedas.value = JSON.parse(JSON.stringify(response.data.datos.monedas))
          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
        })
    }

    const puntoVentaCajaListar = () => {
      punto_venta.value = []
      CuentasService.puntoVentaCajaListar({ fecha: '', id_caja: store.state.user.caja.id })
        .then(response => {
          punto_venta.value = JSON.parse(JSON.stringify(response.data.datos))
          //MonedaSelect(monedas.value[0])
        })
        .catch(error => {
          console.log(error)
        })
    }

    const monedasFilter = () => {
      return monedas.value //.filter(item => item.id != 4)
    }
    const SelectFormaPago = item => {
      pago.value.id_tipo_pago = item.id
      pago.value.monto_real = ''
      pago.value.monto_moneda = ''
    }

    const tasaIgualDolar = () => {
      if (!pago.value.moneda.tasa) {
        return true
      } else {
        if (pago.value.moneda.tasa == 1) {
          return true
        } else {
          return false
        }
      }
    }
    return {
      funciones,
      datos,
      dialog,
      abrir,
      tipo,
      tipos,
      cajas,
      pago,
      monedasFilter,
      monedas,
      SelectFormaPago,
      calcularMontoDolar,
      tasaIgualDolar,
      guardar,
      validarForm,
      form,
      pagos,
      agregar,
      reglas: {
        required: [value => !!value || 'Requerido.'],
        diferenteCaja: [
          value => !!value || 'Requerido.',
          value => pago.value.id_caja_destino != pago.value.id_caja_origen || 'No pueden ser las cajas iguales',
        ],
      },
      icons: {
        mdiPlusCircleOutline,
        mdiMinusCircleOutline,
        mdiSwapVerticalCircleOutline,
        mdiInformation,
      },
      arqueoFinal,
      imprimirCorte,
      imprimirInfoImpresoraDiferencias,
      punto_venta,
      puntoVentaCajaListar,
      ind_cerrar,
      cerrar,
      ModalConfirmacionRef,
      PuntoVentaTransaccionesActualRef,
      abrirTransacciones
    }
  },
}
</script>