
<template>
  <div>
    <v-card flat>
      <div class="lista-productos3">
        <v-row flat ref="listaDetallesProductosRef" id="listaDetallesProductosRef">
          <v-col cols="12" slot="" class="my-0 pb-0 mt-1">
            <v-card rounded="0" class="primary " >
              <v-list-item v-if="comanda.cliente != ''">
                
                <v-list-item-content>
                  <v-list-item-title class="white--text"> {{ comanda.cliente }}</v-list-item-title>
                  <v-list-item-subtitle class="white--text">Comanda:  {{ comanda.id }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="white--text">{{ comanda.mesa_nombre }} - {{ comanda.mesonero_nombre }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="white--text">{{ comanda.descripcion_cliente }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col> 
          <v-col cols="12" class="my-0 py-1" v-for="(item, index) in comanda.productos" :key="'item' + index">
            <v-card class="rounded-0" @click="btnf()">
              <v-list-item>
                <v-list-item-icon>
                  <v-badge
                    depressed
                    top
                    color="secondary"
                    offset-x="40"
                    offset-y="40"
                    :value="item.fecha && item.estado != 13 ? 1 : 0"
                    :content="FuncionesGenerales.diferenciasHoras(store.state.horaActual, item.fecha)"
                  >
                    <v-badge
                      bordered
                      top
                      :color="colorEstadoDespacho(item.estado)"
                      offset-x="12"
                      offset-y="12"
                      :content="item.cantidad"
                    >
                      <v-avatar size="40">
                        <v-img :src="`${config.api_imagenes}/producto/img/s-${item.producto.img}`"
                        
                        :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                        ></v-img>
                        
                      </v-avatar>
                    </v-badge>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content @click="ModificarProducto(item)">
                  <v-list-item-title v-html="item.producto.nombre"></v-list-item-title>
                  <v-list-item-subtitle>
                    <small > {{ item.producto.nombre_puesto }}  <br></small>   
                   <strong v-if="item.nombre_empleado_responsable"><small > {{ item.nombre_empleado_responsable  }}  </small></strong> 
                   <strong v-else-if="(mesoneros.find((item2)=>(item2.id ==item.id_empleado_responsable)))"><small > {{ mesoneros.find(item2=>item2.id ==item.id_empleado_responsable).nombre  }} {{ mesoneros.find(item2=>item2.id ==item.id_empleado_responsable).apellido  }}</small></strong> 
                   </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <strong v-if="validarAcciones(acciones.DASHBOARD_PUESTOS_COMANDA_VER)"  >{{ round(item.precio_total) }}$</strong>
                    <a @click="Eliminar(item)" >
                      <v-icon color="error">{{ mdiDelete }}</v-icon>
                    </a>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-if="
                  item.observacion ||
                  item.ingredientes.filter(item => item.opcional).filter(item => !item.checkbox).length > 0 ||
                  item.extras.length > 0 ||
                  item.ingredientes_variables.length > 0
                "
              >
                <v-list-item-content @click="ModificarProducto(item)">
                  <v-list-item-subtitle v-if="item.observacion">
                    {{ item.observacion }}
                    <v-divider class="mt-1"></v-divider>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-container>
                      <v-row v-if="item.ingredientes.filter(item => !item.checkbox).length > 0">
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <small>Ingredientes excluidos:</small>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <v-chip
                            class="ma-1"
                            color="error"
                            x-small
                            v-for="(ing, index2) in item.ingredientes.filter(item => !item.checkbox)"
                            :key="'chip1-' + index2"
                          >
                            {{ ing.nombre }}
                          </v-chip>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.extras.length > 0">
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <small>Extras</small>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <v-chip
                            class="ma-1"
                            color="success"
                            x-small
                            v-for="(ext, index2) in item.extras"
                            :key="'chip2-' + index2"
                          >
                            {{ ext.cantidad }} / {{ ext.nombre }} / ${{ ext.cantidad * ext.precio_venta }}
                          </v-chip>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.ingredientes_variables.length > 0">
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <small>Acompañantes</small>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 mx-0 px-0">
                          <v-chip
                            class="ma-1"
                            color="success"
                            x-small
                            v-for="(ext, index2) in item.ingredientes_variables.filter(item => item.checkbox)"
                            :key="'chip2-' + index2"
                          >
                            {{ ext.cantidad }} / {{ ext.nombre }}
                          </v-chip>
                          <v-divider></v-divider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-footer dark padless class="footer-detalle">
      <v-container class="grey lighten-5 mb-6">
        <v-row class="d-flex justify-center mb-2 mt-0 white--text">
          <span v-if="validarAcciones(acciones.DASHBOARD_COMANDA_VER_TOTALES)"> Total: {{ comanda.total }}$ </span>
          <span v-else>...</span>
        </v-row>
        <v-row> </v-row>

        <v-row class="m-0">
          <v-col cols="2" class="pa-0" v-if="!$vuetify.breakpoint.smAndUp">
            <v-btn @click="GetCambiarAgregarProducto()" block color="success"> + </v-btn>
          </v-col>

          <v-col
            :cols="auto"
            class="pa-0"
          >
          
            <v-btn
              block
              color="primary"
              v-if="
              (comanda.cliente == '' && FuncionesGenerales.configuracionSelect(12) == '2') || 
              (comanda.id_cliente == -1 && FuncionesGenerales.configuracionSelect(10) == '2') ||
              (comanda.mesa == 0 && store.state.user.caja.id == undefined && FuncionesGenerales.configuracionSelect(9) == '2')
              "
              @click="SelectTab(1)"
              :disabled="comanda.productos.length == 0"
            >
              Siguiente
            </v-btn>
            <v-btn
              v-else
              block
              color="primary"
              :disabled=" comanda.productos.length == 0 || comanda.estado_pago == 9"
              @click="GuardarComanda()"
              :loading="loadGuardandoComanda"
              >Enviar</v-btn
            >
          </v-col>

          <v-col cols="4" class="pa-0"  v-if="
                validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) &&
                !(

                 (comanda.cliente == '' && FuncionesGenerales.configuracionSelect(12) == '2') || 
                  (comanda.id_cliente == -1 && FuncionesGenerales.configuracionSelect(10) == '2') ||
                  (comanda.mesa == 0 && store.state.user.caja.id == undefined && FuncionesGenerales.configuracionSelect(9) == '2')
            
                )
              ">
            <v-btn
              @click="SelectTab(2)"
              block
              color="success"
              :disabled="comanda.estado_pago == 9"
             
            >
              Pagar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiClipboardTextOutline,
  mdiGestureTapButton,
  mdiInformationOutline,
  mdiFolderOutline,
  mdiAccount,
  mdiCashRegister,
  mdiCartPlus,
  mdiMapMarker,
  mdiDelete,
} from '@mdi/js'
import { watch, ref, onBeforeMount } from '@vue/composition-api'
import config from '@/api/config'
import store from '@/store'
import { acciones } from '@/modulos'
import FuncionesGenerales from '@/funciones/funciones'
export default {
  props: {
    comanda: Object,
    tabSelect: Number,
    loadGuardandoComanda: Boolean,
    mesoneros : Array
  },

  setup(props, context) {
    const listaDetallesProductosRef = ref(undefined)
    const tabSelectLocal = ref(0)
    const logoI = process.env.VUE_APP_IMG_LOGO
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    watch(props, () => {
      console.log('props.tabSelect,')
      tabSelectLocal.value = props.tabSelect
    })

    watch(tabSelectLocal, () => {
      if (tabSelectLocal.value != props.tabSelect) {
        console.log(tabSelectLocal.value)
        SelectTab(tabSelectLocal.value)
      }
    })
    const btnf = () => {
      console.log('.')
    }

    const GuardarComanda = () => {
      context.emit('GetGuardarComanda')
    }
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const SelectTab = item => {
      // console.log(item)
      context.emit('GetTabSelect', item)
    }

    const ModificarProducto = item => {
      // console.log(item)
      context.emit('GetModificarProducto', item)
    }
    const GetCambiarAgregarProducto = () => {
      context.emit('GetCambiarAgregarProducto')
    }
    const Eliminar = item => {
      context.emit('GetEliminarProducto', item)
    }
    const colorEstadoDespacho = id => {
      switch (id) {
        case 10:
          return 'error'
          break
        case 11:
          return 'warning'
          break
        case 12:
          return 'success'
          break
        case 13:
          return 'info'
          break
        default:
          return 'primary'
          break
      }
    }
    return {
      mdiInformationOutline,
      mdiFolderOutline,
      mdiCashRegister,
      mdiDelete,
      mdiAccount,
      mdiCartPlus,
      listaDetallesProductosRef,
      tabSelectLocal,
      mdiMapMarker,
      ModificarProducto,
      SelectTab,
      GuardarComanda,
      config,
      round,
      acciones,
      validarAcciones,
      btnf,
      Eliminar,
      colorEstadoDespacho,
      FuncionesGenerales,
      GetCambiarAgregarProducto,
      store,
      logoI
    }
  },
}
</script>


<style lang="scss"  >
.lista-productos3 {
  height: calc(100vh - 154px);
  overflow-x: hidden;
  overflow-y: auto;
}

.header-producto4 {
  height: 64px;
  background-color: var(--v-primary-base);
}
</style>