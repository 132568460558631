<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px"   scrollable>
      <v-card>
        <v-card-title> 
          <small>{{ Tipo == 1 ? 'Ingredientes' : Tipo == 2 ? 'Extras' : 'Acompañantes' }}</small>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row v-for="(ListaDatos, key) in ListaDatosAgrupado" :key="key">
              <v-col class="text-center" cols="12" v-if="ListaDatos.datos.filter(item=>item.opcional && Tipo == 1 ||  (Tipo == 2 || Tipo == 3) ).length > 0">
                <strong> {{ ListaDatos.nombre_padre }}</strong>
              </v-col>
              <v-col lg="4" md="4" sm="4" cols="6" v-for="(item, key2) in ListaDatos.datos.filter(item=>item.opcional && Tipo == 1 ||  (Tipo == 2 || Tipo == 3) )" :key="key2">
        
                <a v-on:click="SelectItem(item, ListaDatos.variable_maximo, ListaDatos)">
                  <v-card
                    color="primary"
                    :style="
                      (Tipo == 2 || Tipo == 3) && item.checkbox
                        ? 'border-bottom-left-radius: 0px; border-bottom-right-radius: 0px'
                        : ''
                    "
                  >
                    <v-img
                      :src="`${config.api_imagenes}/producto/img/m-${item.img}`"
                      :lazy-src="require(`@/assets/images/logos/${logoI}`)"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="140px"
                    >
                      <small class="text-img"> {{ item.nombre }}</small>
                      <span class="text-img-select" v-if="item.checkbox == true">
                        <v-chip v-if="Tipo == 2 || Tipo == 3" color="success" text-color="primary">
                          {{ item.cantidad }}
                        </v-chip>
                        <v-icon v-else color="white" size="40">{{ icons.mdiCheckboxMarked }}</v-icon>
                      </span>
                      <span class="text-img-select" v-else>
                        <v-icon color="error" size="40">{{ icons.mdiCheckboxBlankOutline }}</v-icon>
                      </span>
                      <small v-if="Tipo == 2 && item.estado == 1" class="text-img2">
                        {{ (item.precio_venta * item.cantidad ? item.cantidad : 1).toFixed(2) }}$ <br />
                      </small>
                      <small v-if="item.Tipo == 2 && item.estado == 2" class="text-img3"> AGOTADO</small>
                    </v-img>
                  </v-card>
                </a>
                <v-row v-show="(Tipo == 2 || Tipo == 3) && item.checkbox">
                  <v-col cols="6" class="mx-0 pr-0"
                    ><v-btn
                      small
                      color="secondary"
                      style="
                        border-top-left-radius: 0px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        border-top-right-radius: 0px;
                      "
                      block
                      @click="agregarCantidad(item, -1, ListaDatos.variable_maximo, ListaDatos)"
                      ><span class="text-h5 py-0 px-0 my-0 mx-0">-</span></v-btn
                    ></v-col
                  >

                  <v-col cols="6" class="mx-0 pl-0"
                    ><v-btn
                      small
                      color="primary"
                      style="border-top-left-radius: 0px; border-top-right-radius: 0px; border-bottom-left-radius: 0px"
                      block
                      @click="agregarCantidad(item, 1, ListaDatos.variable_maximo, ListaDatos)"
                      ><span class="text-h5 py-0 px-0 my-0 mx-0">+</span></v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
              <v-col cols="12" v-if="indMaximo">
                <span >{{ ListaDatos.cantidadTotal }} de {{ ListaDatos.variable_maximo }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="blue darken-1" text @click="aceptar()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import { mdiCheckboxMarked, mdiCheckboxBlankOutline } from '@mdi/js'
import store from '@/store'
export default {
  props: {
    Lista: {
      type: Array,
      default: null,
    },
    Tipo: {
      type: Number,
      default: 1, //1 ingrediente, 2 extras
    },
    indMaximo: {
      type: Boolean,
      default: false, //1 ingrediente, 2 extras
    },
    minino: {
      type: Number,
      default: 1, //1 ingrediente, 2 extras
    },
    maximo: {
      type: Number,
      default: 1, //1 ingrediente, 2 extras
    },
  },
  setup(props, context) {
    const ListaDatos = ref([])
    const logoI = process.env.VUE_APP_IMG_LOGO
    const cantidadTotal = ref(0)

    const SelectItem = (item, maximo, padre) => {
      
      if (!item.checkbox) {
        if (props.indMaximo) {
          if (padre.cantidadTotal < maximo) {
            item.checkbox = !item.checkbox
            item.cantidad = 1
            console.log(item)
            sumarCantidadTotal(padre)
          } else {
            store.commit('setAlert', {
              message: `Tiene un maximo de ${maximo}`,
              type: 'info',
            })
          }
        } else {
          item.checkbox = !item.checkbox
          item.cantidad = 1
          sumarCantidadTotal(padre)
        }
      } else {
        item.checkbox = !item.checkbox
        item.cantidad = 1
        sumarCantidadTotal(padre)
      }
    }
    const ListaDatosAgrupado = ref([])
    const groupByProducto = () => {
      const array_new = []
      ListaDatos.value.forEach(principal => {
        if (array_new.filter(item => item.id_producto_padre == principal.id_producto_padre).length > 0) {
          const ll = array_new.find(item2 => item2.id_producto_padre == principal.id_producto_padre)
          ll.datos.push(principal)
        } else {
          array_new.push({
            id_producto_padre: principal.id_producto_padre,
            nombre_padre: principal.nombre_padre,
            variable_minimo: principal.variable_minimo,
            variable_maximo: principal.variable_maximo,
            cantidadTotal: 0,
            datos: [principal],
          })
        }
      })

      ListaDatosAgrupado.value = array_new
      
      ListaDatosAgrupado.value.forEach(item=>{
        sumarCantidadTotal(item)
      })
   
    }
    const agregarCantidad = (item, valor, maximo, padre) => {
      if (valor < 0) {
        if (item.cantidad > 1) {
          item.cantidad = item.cantidad - 1
        }
      } else {
        if (props.indMaximo) {
          if (padre.cantidadTotal < maximo) {
            item.cantidad = item.cantidad + 1
          } else {
            store.commit('setAlert', {
              message: `Tiene un maximo de ${maximo}`,
              type: 'info',
            })
          }
        } else {
          item.cantidad = item.cantidad + 1
        }
      }

      sumarCantidadTotal(padre)
    }

    const sumarCantidadTotal = padre => {
      console.log(padre)
      var sum = 0
      if (padre != null) {
        padre.datos.forEach(element => {
          if (element.checkbox) {
            sum = sum + element.cantidad
          }
        })
        padre.cantidadTotal = sum
      }

    
    }
    const dialog = ref(false)
    const abrir = (valor, lista) => {
      dialog.value = valor
      if (valor == true) {
         ListaDatos.value = lista
        groupByProducto()
        //cantidadTotal.value = 0
       
      }
    }
    const aceptar = () => {
      dialog.value = false
      context.emit('aceptar', ListaDatos.value)
    }
    return {
      ListaDatosAgrupado,
      groupByProducto,
      SelectItem,
      config,
      logoI,
      dialog,
      abrir,
      aceptar,
      agregarCantidad,
      cantidadTotal,
      ListaDatos,
      icons: {
        mdiCheckboxMarked,
        mdiCheckboxBlankOutline,
      },
    }
  },
}
</script>

<style  lang="scss" scoped>
.text-img {
  top: 0rem;
  padding: 3px;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 3;
  background-image: linear-gradient(rgb(0, 0, 0), rgba(10, 10, 10, 0.445));
}
.text-img-select {
  top: 0rem;
  padding: 3rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: linear-gradient(rgba(7, 7, 7, 0.137), rgba(0, 0, 0, 0.192));
}

.text-img2 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.text-img3 {
  bottom: 0rem;
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.8);
}
</style>