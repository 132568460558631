//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../Estacionamiento";
import router from '@/router'


class EstacionamientoService {

   
    verify(codigo) {
        return http.get(`/ticket/verify/${codigo}`);
    }
    notify(codigo) {
        return http.get(`/ticket/notify/${codigo}`);
    }


}

export default new EstacionamientoService();