<template>
  <div class="mx-2 my-2">
    <v-data-table
      height="calc(100vh - 348px)"
      :headers="headers"
      :items="comanda.productos"
      :items-per-page="-1"
      fixed-header
      hide-default-footer
    >
      <template #[`item.producto.nombre`]="{ item }">
        <div class="d-flex align-center">
          <v-avatar
            :color="item.producto.img ? '' : 'primary'"
            :class="item.producto.img ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img v-if="item.producto.img" :src="`${config.api_imagenes}/producto/img/s-${item.producto.img}`">
            </v-img>
            <span v-else>{{ item.producto.nombre.slice(0, 2).toUpperCase() }}</span>
          </v-avatar>
          <small :class="item.id < 1 ? 'primary--text' : item.ind_modifica ? 'error--text' : ''">{{
            item.id < 1 ? 'N' : item.ind_modifica ? 'M' : ''
          }}</small>

          <v-icon
            x-small
            :color="Modificar || validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS) ? 'success' : 'error'"
            >{{
              Modificar || validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS)
                ? icons.mdiLockOpenVariant
                : icons.mdiLock
            }}</v-icon
          >

          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.producto.nombre }}</span>
            <small>{{ item.producto.nombre_puesto }}</small>
            <small v-if="!item.ind_servicio_detalle" class="warning--text">Sin servicio</small>
            <small class="error--text" v-if="item.precio_real < item.producto.costo"
              ><v-icon x-small color="error">{{ icons.mdiAlert }}</v-icon> Precio menor al costo</small
            >
            <small class="warning--text" v-if="item.precio_real == item.producto.costo">
              <v-icon x-small color="warning">{{ icons.mdiAlert }}</v-icon> Precio igual al costo</small
            >
            <br />
            {{ comanda.indExonerarIva }}
          </div>
        </div>
      </template>

      <template #[`item.precio_real`]="{ item }">
        <v-edit-dialog :return-value.sync="item.precio_real">
          <div class="d-flex flex-column">
            <span
              class="d-block font-weight-semibold text-truncate"
              :class="item.ind_modifica_precio ? 'error--text' : ''"
              >{{ `$.${round(Number(item.precio_real)).toFixed(2)}` }}</span
            >
            <small>{{
              `${MonedaPais.simbolo}.${round(round(Number(item.precio_real) * MonedaPais.tasa)).toFixed(2)}`
            }}</small>
          </div>

          <template v-slot:input>
            <v-text-field
              v-if="Modificar || validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS)"
              v-on:keyup.enter="GetModificacionDetalles(item, 'precio')"
              v-model="item.precio_real_temp"
              type="number"
              label="Edit"
              single-line
              hint="Precio"
              :rules="[rules.required, rules.min2]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template #[`item.sub_total`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
            `$.${round(Number(item.precio_real) * item.cantidad).toFixed(2)}`
          }}</span>
          <small>{{
            `${MonedaPais.simbolo}.${round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad).toFixed(
              2,
            )}`
          }}</small>
        </div>
      </template>

      <template #[`item.por_descuento`]="{ item }">
        <v-edit-dialog :return-value.sync="item.por_descuento">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold text--primary text-truncate"
              >{{ `$.${round((item.por_descuento / 100) * (Number(item.precio_real) * item.cantidad)).toFixed(2)}` }}
              <small class="error--text" v-if="item.por_descuento > 0">{{ item.por_descuento }}%</small>
            </span>
            <small>{{
              `${MonedaPais.simbolo}.${(
                round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                (item.por_descuento / 100)
              ).toFixed(2)}`
            }}</small>
          </div>

          <template v-slot:input>
            <v-text-field
              v-if="Modificar || validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS)"
              v-on:keyup.enter="GetModificacionDetalles(item, 'procentaje')"
              type="number"
              v-model="item.por_descuento_temp"
              label="Edit"
              single-line
              hint="max 100%"
              :rules="[rules.required, rules.min]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template #[`item.iva`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
            `$.${round(
              (round(round(item.precio_real) * item.cantidad).toFixed(2) -
                round(round(round(item.precio_real) * item.cantidad) * (item.por_descuento / 100))) *
                (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
            ).toFixed(2)}`
          }}</span>
          <small>{{
            `${MonedaPais.simbolo}.${round(
              (round(round(item.precio_real * MonedaPais.tasa) * item.cantidad).toFixed(2) -
                round(round(round(item.precio_real * MonedaPais.tasa) * item.cantidad) * (item.por_descuento / 100))) *
                (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
            ).toFixed(2)}`
          }}</small>
        </div>
      </template>
      <template #[`item.precio_total`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{
            `$.${round(
              (Number(item.precio_real) * item.cantidad -
                round(round(round(Number(item.precio_real)) * item.cantidad) * (item.por_descuento / 100))) *
                (1 + (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0)),
            ).toFixed(2)}`
          }}</span>
          <small>{{
            `${MonedaPais.simbolo}.${round(
              round(
                Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                  round(
                    round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                      (item.por_descuento / 100),
                  ),
              ) +
                round(
                  (Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                    round(
                      round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                        (item.por_descuento / 100),
                    )) *
                    (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                ),
            ).toFixed(2)}`
          }}</small>
        </div>
      </template>
    </v-data-table>
    <v-card-text class="my-0 py-0 pt-2 mr-0 pr-0 text-right">
      <v-btn
        class="mr-2 error"
        small
        v-if="Modificar || validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS)"
        @click="llevarCosto()"
        :disabled="verificarLlevarCosto()"
        dark
        >Llevar pvp a costo</v-btn
      >
      <v-btn class="mr-2 secondary" small @click="dialogServicio = true">% Servicio</v-btn>
      <v-btn class="secondary" small @click="dialogDescuento = true">% Descuento</v-btn>
    </v-card-text>
    <v-card class="mt-2 py-4 table-comandas">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-simple-table dense>
              <template>
                <tbody>
                  <tr>
                    <td class="text-left"><strong>SUBTOTAL: </strong></td>
                    <td class="text-right">
                      <span>$.{{ round(comanda.subtotal).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span>{{ MonedaPais.simbolo }}.{{ round(comanda.subtotalMonedaPais).toFixed(2) }}</span>
                    </td>
                  </tr>

                  <tr>
                    <td class="text-left">
                      <strong>DESC:({{ comanda.por_descuento }}%)</strong>
                    </td>
                    <td class="text-right">
                      <span>-$.{{ round(comanda.descuento).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span>-{{ MonedaPais.simbolo }}.{{ round(comanda.descuentoMonedaPais).toFixed(2) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      <strong>SERV({{ comanda.por_comision }}%):</strong>
                    </td>
                    <td class="text-right">
                      <span>+$ {{ comanda.servicio.toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.servicioMonedaPais.toFixed(2)) }} </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left"><strong>IVA:</strong></td>
                    <td class="text-right">
                      <span>+$.{{ round(comanda.iva).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.ivaMonedaPais).toFixed(2) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left"><strong>IGTF:</strong></td>
                    <td class="text-right">
                      <span>+$.{{ round(comanda.igtf).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span>+{{ MonedaPais.simbolo }}.{{ comanda.igtfMonedaPais.toFixed(2) }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6">
            <v-simple-table dense>
              <template>
                <tbody>
                  <tr>
                    <td class="text-left"><strong>TOTAL:</strong></td>
                    <td class="text-right">$.{{ round(comanda.total).toFixed(2) }}</td>
                    <td class="text-right">{{ MonedaPais.simbolo }}.{{ round(comanda.totalMonedaPais).toFixed(2) }}</td>
                  </tr>
                  <tr>
                    <td class="text-left"><strong>PROPINA:</strong></td>

                    <td class="text-right">
                      <span class=" ">$.{{ round(totalesPropina().totalMoneda).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span class=" ">Bs.{{ round(totalesPropina().totalMonedaPais).toFixed(2) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left"><strong>PAGOS:</strong></td>
                    <td class="text-right">
                      <span class=" ">$.{{ round(comanda.totalPagos).toFixed(2) }}</span>
                    </td>
                    <td class="text-right">
                      <span class=" "
                        >{{ MonedaPais.simbolo }}.{{ round(comanda.totalPagosMonedaPais).toFixed(2) }}</span
                      >
                    </td>
                  </tr>

                  <tr>
                    <td class="text-left"><strong>TOTAL REAL:</strong></td>
                    <td class="text-right">
                      <strong class=" "
                        >$.{{
                          round(
                            totalesPropina().totalMoneda + comanda.totalPagos + (comanda.resta < 0 ? comanda.resta : 0),
                          ).toFixed(2)
                        }}</strong
                      >
                    </td>
                    <td class="text-right">
                      <strong class=" "
                        >{{ MonedaPais.simbolo }}.{{
                          round(
                            totalesPropina().totalMonedaPais +
                              comanda.totalPagosMonedaPais +
                              (comanda.restaMonedaPais < 0 ? comanda.restaMonedaPais : 0),
                          ).toFixed(2)
                        }}</strong
                      >
                    </td>
                  </tr>

                  <tr>
                    <td class="text-left">
                      <strong :class="comanda.resta > 0 ? 'error--text' : 'success--text'">
                        {{ comanda.resta > 0 ? 'RESTA' : 'VUELTO' }}:</strong
                      >
                    </td>
                    <td class="text-right">
                      <span :class="comanda.resta > 0 ? 'error--text' : 'success--text'"
                        >$.{{ round(comanda.resta * -1).toFixed(2) }}</span
                      >
                    </td>
                    <td class="text-right">
                      <span :class="comanda.resta > 0 ? 'error--text' : 'success--text'"
                        >{{ MonedaPais.simbolo }}.{{ round(comanda.restaMonedaPais * -1).toFixed(2) }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>

    <v-dialog v-model="dialogDescuento" scrollable max-width="300px">
      <v-card>
        <v-card-title>Ingrese el % de descuento</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-item-group mandatory v-model="tipo_descuento">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-item v-slot="{ active, toggle }">
                    <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark @click="toggle">
                      <v-scroll-y-transition>
                        <div class="white--text my-4 flex-grow-1 text-center">TOTAL</div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
                <v-col cols="12" md="6">
                  <v-item v-slot="{ active, toggle }">
                    <v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark @click="toggle">
                      <v-scroll-y-transition>
                        <div class="white--text my-4 flex-grow-1 text-center">POR AREA</div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
          <v-row class="mb-4" v-show="tipo_descuento == 1">
            <v-col cols="6" v-for="(item, index) in areas" :key="index">
              <v-checkbox v-model="item.value" :label="item.text" color="error" hide-details></v-checkbox>
            </v-col>
          </v-row>

          <v-text-field
            v-model="_por_descuento"
            label="Descuento"
            :rules="[rules.required, rules.min]"
            type="number"
            outlined
            suffix="%"
            v-on:keyup.enter="GetCambiarPorDescuentoGeneral(_por_descuento)"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogDescuento = false"> Cerrar </v-btn>
          <v-btn small color="primary" @click="GetCambiarPorDescuentoGeneral(_por_descuento)"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmacionUsuario @GetConfirmacionUsuario="GetConfirmacionUsuario" ref="ConfirmacionUsuarioRef" />
    <v-dialog v-model="dialogServicio" scrollable max-width="300px">
      <v-card>
        <v-card-title>Ingrese el % de servicio</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="_por_servicio"
            label="Descuento"
            :rules="[rules.required, rules.min]"
            type="number"
            outlined
            suffix="%"
            v-on:keyup.enter="GetCambiarComision(_por_servicio)"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogServicio = false"> Cerrar </v-btn>
          <v-btn small color="primary" @click="GetCambiarComision(_por_servicio)"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalConfirmacion ref="ModalConfirmacionRef" />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiCheck,
  mdiCancel,
  mdiLock,
  mdiLockOpenVariant,
  mdiInformation,
  mdiAlert,
} from '@mdi/js'
import { watch, ref, onBeforeMount, onMounted, onUnmounted } from '@vue/composition-api'
import config from '@/api/config'
import ModalPropinaVue from './ModalPropina.vue'
import EscanerBarra from '@/components/EscanerBarra.vue'
import Icons from '../../../../icons/Icons.vue'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
import Vue from 'vue'
import { acciones } from '@/modulos'
import store from '@/store'
import SeguridadService from '@/api/servicios/SeguridadService'
import ConfirmacionUsuario from '@/components/ConfirmacionUsuario.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
export default {
  props: {
    comanda: Object,
    monedas: Array,
  },
  components: {
    ModalPropinaVue,
    EscanerBarra,
    Icons,
    KeyBoardEvents,
    ConfirmacionUsuario,
    ModalConfirmacion,
  },

  setup(props, context) {
    const MonedaPais = props.monedas.filter(item => item.meneda_pais == true)[0]
    const Modificar = ref(false)
    const dialogDescuento = ref(false)
    const _por_descuento = ref(0)
    const dialogServicio = ref(false)
    const _por_servicio = ref(0)
    const ConfirmacionUsuarioRef = ref(null)
    const tipo_descuento = ref(0)
    const statusColor = {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }
    const rules = {
      required: value => !!value || 'Required.',
      min: v => (Number(v) < 100 && Number(v) >= 0) || 'debe ser un valor entre 0 y 99',
      min2: v => Number(v) > 0 || 'debe ser un valor mayor a 0',
    }
    let data = []
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    watch(props, () => {
      console.log('props aquiiiiiii,')
    })

    watch(tipo_descuento, () => {
      if (tipo_descuento.value == 1) {
        areasAgrupadas()
      }
    })

    const verificarLlevarCosto = () => {
      let retur = props.comanda.productos.find(
        item => item.producto.ind_llevar_costo && item.precio_real != item.producto.costo,
      )

      return retur == undefined || retur == null
    }
    const areas = ref([])
    const areasAgrupadas = () => {
      var _areas = []
      props.comanda.productos.forEach(elm => {
        if (_areas.find(el => el.text == elm.producto.nombre_puesto) == undefined) {
          _areas.push({
            text: elm.producto.nombre_puesto,
            value: false,
          })
        }
      })
      areas.value = _areas
    }

    const ModalConfirmacionRef = ref()
    const llevarCosto = () => {
      ModalConfirmacionRef.value.abrir({
        subTitulo: '¿Seguro que quiere llevar los precios de los productos al costo? ',
        icon: mdiInformation,
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcion: async () => {
            console.log(props.comanda.productos)
            props.comanda.productos.forEach(item => {
              if (item.producto.ind_llevar_costo) {
                item.ind_modifica = true
                item.ind_modifica_precio = true
                item.precio_real = item.producto.costo
              }
            })
            context.emit('GetModificacionDetalles', props.comanda)
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })
    }

    const GetCambiarPorDescuentoGeneral = async valor => {
      ConfirmacionUsuarioRef.value.abrir(
        { id_accion_modulo: acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS },
        async dato => {
            const valor = dato.valor
          console.log(valor)
          if (tipo_descuento.value == 0) {
            if (Number(valor) >= 0 && Number(valor) < 100) {
              dialogDescuento.value = false
              var todos = false
              if (props.comanda.productos.filter(it => it.por_descuento != props.comanda.por_descuento).length > 0) {
                await Vue.swal({
                  html:
                    '<h3>¿Realizar cambio a todo? </h3>' +
                    `<p>hay productos que tienen un porcentaje de descuento individual diferente ¿quiere reemplazarlos?</p>`,

                  showCancelButton: true,
                  confirmButtonText: 'Aplicar a todos',
                  showLoaderOnConfirm: true,
                  cancelButtonText: 'Cancelar',
                  showDenyButton: true,
                  denyButtonText: 'Aplicar al resto',
                  denyButtonColor: '#36dd35',

                  preConfirm: () => {
                    return true
                  },
                  allowOutsideClick: () => {
                    if (!Vue.swal.isLoading()) {
                      Vue.swal.close()
                      elimiandoItem.value = false
                    }
                  },
                }).then(result => {
                  console.log(result)
                  if (result.isConfirmed) {
                    props.comanda.productos.forEach(item => {
                      if (item.por_descuento != props.comanda.por_descuento) {
                        item.ind_modifica = true
                      }
                      item.por_descuento = valor
                    })
                    props.comanda.por_descuento = valor
                    context.emit('GetModificacionDetalles', props.comanda)
                  } else if (result.isDenied) {
                    props.comanda.productos.forEach(item => {
                      if (item.por_descuento == props.comanda.por_descuento) {
                        item.por_descuento = valor
                      }
                    })
                    props.comanda.por_descuento = valor
                    context.emit('GetModificacionDetalles', props.comanda)
                  }
                  return null
                })
              } else {
                props.comanda.productos.forEach(item => {
                  item.por_descuento = valor
                })
                props.comanda.por_descuento = valor
                context.emit('GetModificacionDetalles', props.comanda)
              }
            }
          } else {
            //caso  de descuentos por areas
            props.comanda.productos.forEach(item => {
              if (areas.value.find(it => it.text == item.producto.nombre_puesto && it.value == true) != undefined) {
                item.ind_modifica = true
                item.por_descuento = valor
              }
            })
            dialogDescuento.value = false
            context.emit('GetModificacionDetalles', props.comanda)
          }
        },
        {valor},
        () => {},
      )
      //validarAcciones(acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS)
    }

    const GetCambiarComision = (valor = 0) => {
      if (Number(valor) >= 0 && Number(valor) < 100) {
        dialogServicio.value = false
        context.emit('GetCambiarComision', valor)
      }
    }
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)
      return valor
    }

    const isDialogVisible = ref(false)
    const vaidarPermiso = (accion, funcion_in, funcion_dato) => {
      isDialogVisible.value = false
      if (validarAcciones(accion)) {
        funcion_in(funcion_dato)
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',
          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  Vue.swal.showValidationMessage(`Usuario o clave invalida, o el usuario no tiene permiso`)
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                    ...error,
                    response: error?.response?.data,
                  },
                  funcion: 'vaidarPermiso',
                })
                isDialogVisible.value = true
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              funcion_in(funcion_dato)
            } else if (result.dismiss === 'cancel') {
              isDialogVisible.value = true
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              isDialogVisible.value = true
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
              isDialogVisible.value = true
            }
          }
        })
      }
    }
    const GetModificacionDetalles = async (item, tipo) => {
      if (
        (tipo == 'precio' && Number(item.precio_real_temp) > 0) ||
        (tipo == 'procentaje' && Number(item.por_descuento_temp) >= 0 && Number(item.por_descuento_temp) <= 99)
      ) {
        item.ind_modifica = true

        if (tipo == 'precio') {
          item.ind_modifica_precio = true

          item.precio_real = item.precio_real_temp
        }

        if (tipo == 'procentaje') {
          item.por_descuento = item.por_descuento_temp
        }
        const otrosProductos = props.comanda.productos.filter(
          it => it.id_producto == item.id_producto && it.id != item.id,
        )

        if (otrosProductos.length > 0) {
          await Vue.swal({
            html:
              `<img style="border-radius: 50px;" src="${config.api_imagenes}/producto/img/s-${item.producto.img}" height="100px" max-width="100px"/> <br>` +
              '<h3>¿Desea realizar este cambio a todos los productos agregados? </h3>' +
              `<p>${item.producto.nombre}</p>`,

            showCancelButton: true,
            confirmButtonText: 'Si, Seguro',
            showLoaderOnConfirm: true,
            cancelButtonText: 'No',
            preConfirm: () => {
              otrosProductos.forEach(items => {
                items.precio_real = item.precio_real
                items.por_descuento = item.por_descuento
                items.ind_modifica = true
                if (tipo == 'precio') {
                  items.ind_modifica_precio = true
                }
              })
              return true
            },
            allowOutsideClick: () => {
              if (!Vue.swal.isLoading()) {
                Vue.swal.close()
                elimiandoItem.value = false
              }
            },
          }).then(result => {
            return null
          })
        }

        context.emit('GetModificacionDetalles', props.comanda)
      }
    }
    const totalesPropina = () => {
      let sumas = {
        totalMonedaPais: 0,
        totalMoneda: 0,
      }

      props.comanda.propinas.forEach(element => {
        sumas.totalMonedaPais += Number(element.monto_real) * MonedaPais.tasa
        sumas.totalMoneda += Number(element.monto_real)
      })

      return sumas
    }
    const keyboardEvent = e => {
      if (e.altKey) {
        // Evitar el comportamiento por defecto del nevagador:
        e.preventDefault()
        e.stopPropagation()
        if (e.which === 77) {
          if (Modificar.value == false) {
            ConfirmacionUsuarioRef.value.abrir(
              { id_accion_modulo: acciones.DASHBOARD_COMANDA_MODIFICA_PRECIOS },
              (dato = '') => {
                Modificar.value = true
              },
              '',
              () => {},
            )
          } else {
            Modificar.value = false
          }
        }
      }
    }

    const GetConfirmacionUsuario = (_funcion, _datos, _funcionCancel, _cancel) => {
      if (_cancel) {
        _funcionCancel()
      } else {
        _funcion(_datos)
      }
    }

    return {
      ConfirmacionUsuarioRef,
      headers: [
        { text: 'Producto', value: 'producto.nombre' },
        { text: 'Cantidad', value: 'cantidad' },
        { text: 'Precio', value: 'precio_real' },
        { text: 'SubTotal', value: 'sub_total' },
        { text: 'Descuento', value: 'por_descuento' },
        { text: 'Iva', value: 'iva' },
        { text: 'Total', value: 'precio_total' },
      ],
      detalle: data,
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCheck,
        mdiCancel,
        mdiLock,
        mdiLockOpenVariant,
        mdiAlert,
      },
      MonedaPais,
      config,
      round,
      GetCambiarComision,
      totalesPropina,
      GetModificacionDetalles,
      Modificar,
      keyboardEvent,
      vaidarPermiso,
      acciones,
      validarAcciones,
      GetCambiarPorDescuentoGeneral,
      rules,
      dialogDescuento,
      _por_descuento,
      dialogServicio,
      _por_servicio,
      GetConfirmacionUsuario,
      ModalConfirmacionRef,
      llevarCosto,
      verificarLlevarCosto,
      tipo_descuento,
      areasAgrupadas,
      areas,
    }
  },
}
</script>

 
<style lang="scss"  >
.table-producto {
  height: calc(100vh - 220px);
}

.table-comandas {
  height: 220px;
}
</style>