<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card v-if="mensaje == 0">
        <v-card-title class="text-h5 grey text-center"> Validacion de credenciales </v-card-title>
       
        <v-card-text class="text-center">
          <v-row class="mt-4">
            <v-col cols="4" class="my-0 py-0">
              <v-item>
             
                <v-card
                  :disabled="cargando"
                  :color="tipoValidacion == 'CLAVE' ? 'secondary' : ''"
                  class="d-flex align-center"
                  height="40"
                  @click="tipoValidacion = 'CLAVE'"
                >
                  <v-scroll-y-transition>
                    <div class="flex-grow-1 text-center" :class="tipoValidacion == 'CLAVE' ? 'white--text' : ''">
                      Clave
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="4" class="my-0 py-0">
              <v-item>
                <v-card
                  :disabled="cargando"
                  :color="tipoValidacion == 'CONFIRMACION' ? 'secondary' : ''"
                  class="d-flex align-center"
                  height="40"
                  @click="selectConfirmacion()"
                >
                  <v-scroll-y-transition>
                    <div class="flex-grow-1 text-center" :class="tipoValidacion == 'CONFIRMACION' ? 'white--text' : ''">
                      Codigo
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="4" class="my-0 py-0">
              <v-item>
                <v-card
                  :disabled="cargando"
                  :color="tipoValidacion == 'QR' ? 'secondary' : ''"
                  class="d-flex align-center"
                  height="40"
                  @click="selectQR()"
                >
                  <v-scroll-y-transition>
                    <div class="flex-grow-1 text-center" :class="tipoValidacion == 'QR' ? 'white--text' : ''">
                      Qr
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
            <v-col cols="12" v-if="tipoValidacion == 'CLAVE' || tipoValidacion == 'CONFIRMACION'">
              <v-text-field
                v-model="usuario"
                :disabled="cargando"
                outlined
                hide-details="auto"
                label="Usuario"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="tipoValidacion == 'CLAVE'">
              <v-text-field v-model="clave" outlined label="Clave" hide-details="auto" type="password"></v-text-field>
            </v-col>
            <v-col cols="12" v-if="tipoValidacion == 'CONFIRMACION'">
              <h3>Seleccione en la pantalla el numero</h3>
              <h1>{{ numeroAleatorio }}</h1>
              <br />
              {{ idTran }}
            </v-col>

            <v-col cols="12" v-if="tipoValidacion == 'QR'">
              <h3  v-if="idTran">Escanee para autorizar</h3>
              <h3  v-else>Genere un Qr</h3>
              <qrcode-vue  v-if="idTran" :value="idTran" :size="300" level="H"></qrcode-vue>
              {{ idTran }}
              <br /> 
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-text class="text-center">
          <v-btn
          
            color="primary"
            class="mr-2"
            @click="enviarConfirmacion()"
            :disabled="cargando"
            :loading="tipoValidacion == 'CLAVE' ? cargando : false"
          >
            {{ temporizador > 0 ? temporizador : 'Generar' }}
          </v-btn>
          <v-btn color="secondary" @click="cancelar()"> Cancelar </v-btn>
        </v-card-text>
      </v-card>

      <v-card v-if="mensaje == 1">
        <v-card-text class="text-center">
          <h4>Autorizado</h4>
          <v-icon size="100" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
        </v-card-text>
      </v-card>
      <v-card v-if="mensaje == 2">
        <v-card-text class="text-center">
          <h4 v-if="tipoValidacion == 'CLAVE'">Clave usuario invalida o no posee permisos</h4>
          <h4 v-else>Error al seleccionar el codigo</h4>
          <v-icon size="100" color="error">{{ icons.mdiCancel }}</v-icon>
        </v-card-text>
      </v-card>
      <v-card v-if="mensaje == 3">
        <v-card-text class="text-center">
          <h4>Cancelado</h4>
          <v-icon size="100" color="secondary">{{ icons.mdiCancel }}</v-icon>
        </v-card-text>
      </v-card>
      <v-card v-if="mensaje == 4">
        <v-card-text class="text-center">
          <h4>Usuario no existe o no tiene permiso a esta accion</h4>
          <v-icon size="100" color="secondary">{{ icons.mdiCancel }}</v-icon>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import { socket, state as stateSocket } from '@/plugins/sockets'
import store from '@/store'
import SeguridadService from '@/api/servicios/SeguridadService'
import { mdiCheckCircleOutline, mdiCancel } from '@mdi/js'
  import QrcodeVue from 'qrcode.vue'
export default {
  components:{
    QrcodeVue
  },
  setup(props, context) {
    const dialog = ref(false)
    let tipoValidacion = ref('CLAVE')
    const qrvValue = ref(null)
    //CLAVE , CONFIRMACION
    let datos = ref(null)
    let IdAccion = ref(null)
    let funcion = null
    let datosFuncion = null
    let funcionCancelar = null
    const usuario = ref('')
    const clave = ref('')
    const numeroAleatorio = ref(null)
    const idTran = ref('')
    const inicializar = () => {
      idTran.value = ''
      mensaje.value = 0
      tipoValidacion.value = 'CLAVE'
      temporizador.value = 0
      clearInterval(myInterval)
      cargando.value = false

      usuario.value = ''
      clave.value = ''
    }
    const selectConfirmacion = () => {
      tipoValidacion.value = 'CONFIRMACION'
      numeroAleatorio.value = funciones.aleatorio(0, 99)
    }

    const selectQR = () => { 
      tipoValidacion.value = 'QR'
      numeroAleatorio.value = funciones.aleatorio(0, 99)
    }
    const temporizador = ref(0)
    var myInterval = null
    const iniciarTemporizador = () => {
      temporizador.value = 30
      myInterval = setInterval(function () {
        temporizador.value = temporizador.value - 1
        if (temporizador.value == 0) {
          clearInterval(myInterval)
          cargando.value = false
          idTran.value = null
        }
      }, 1000)
    }
    const cargando = ref(false)
    const enviarConfirmacion = () => {
      cargando.value = true
      if (tipoValidacion.value == 'CLAVE') {
        SeguridadService.validarCredencialesAccionModulo({
          usuario: usuario.value,
          clave: clave.value,
          id_accion_modulo: datos.value.id_accion_modulo,
        })
          .then(response => {
            console.log(response.data)
            if (response.data.datos == 'ok') {
              mensaje.value = 1
              setTimeout(function () {
                context.emit(
                  'GetConfirmacionUsuario',
                  funcion,
                  { ...datosFuncion, id_empleado_autoriza: response.data.id_empleado },
                  funcionCancelar,
                  false,
                )
                dialog.value = false
              }, 1000)
            } else {
              context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
              mensaje.value = 2
              setTimeout(function () {
                dialog.value = false
              }, 1000)
            }
          })
          .catch(error => {
            context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)

            mensaje.value = 2
            setTimeout(function () {
              dialog.value = false
            }, 1000)
          })
          .finally(() => {
            cargando.value = false
          })
      } 
      else if (tipoValidacion.value == 'CONFIRMACION')   {
        idTran.value = funciones.getRanHex(12)
        cargando.value = true
        iniciarTemporizador()
        socket.emit('autorizacion', {
          usuario: usuario.value,
          clave: numeroAleatorio.value,
          accion: datos.value.id_accion_modulo,
          idTran: idTran.value,
          empleado: {
            id: store.state.user.id,
            nombre: store.state.user.nombre,
          },
        })
      } else{
         
        idTran.value = funciones.getRanHex(12)
        cargando.value = true
        iniciarTemporizador()
         
      }
    }
    const mensaje = ref(0)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const abrir = (_datos, _funcion, _datosFuncion, _funcionCancelar) => {
      datos.value = _datos
      if (validarAcciones(datos.value.id_accion_modulo)) {
        context.emit(
          'GetConfirmacionUsuario',
          _funcion,
          { ..._datosFuncion, id_empleado_autoriza: store.state.user.id_empleado },
          _funcionCancelar,
          false,
        )
        dialog.value = false
      } else {
        dialog.value = true
        inicializar()
        funcion = _funcion
        datosFuncion = _datosFuncion
        funcionCancelar = _funcionCancelar
      }
    }
    socket.on('autorizar-qr', args => {
      
      console.log('autorizar-qr', args)

      if (args.idTran == idTran.value) {
        SeguridadService.validarUsuarioAccionModulo({
          usuario: args.usuario, 
          id_accion_modulo: datos.value.id_accion_modulo,
        })
          .then(response => {
            console.log(response.data)
            if (response.data.datos == 'ok') {
              mensaje.value = 1
              setTimeout(function () {
                context.emit(
                  'GetConfirmacionUsuario',
                  funcion,
                  { ...datosFuncion, id_empleado_autoriza: response.data.id_empleado },
                  funcionCancelar,
                  false,
                )
                dialog.value = false
              }, 1000)
            } else {
              context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
              mensaje.value = 2
              setTimeout(function () {
                dialog.value = false
              }, 1000)
            }
          })
          .catch(error => {
            context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)

            mensaje.value = 2
            setTimeout(function () {
              dialog.value = false
            }, 1000)
          })
          .finally(() => {
            cargando.value = false
          })
      }
      
    })
    socket.on('autorizar', args => {
      console.log('autorizar', args)

      if (args.idTran == idTran.value) {
        if (args.autorizacion == 1) {
          mensaje.value = 1
          setTimeout(function () {
            context.emit(
              'GetConfirmacionUsuario',
              funcion,
              { ...datosFuncion, id_empleado_autoriza: args.id_empleado },
              funcionCancelar,
              false,
            )
            dialog.value = false
          }, 1000)
        } else if (args.autorizacion == 2) {
          mensaje.value = 2
          setTimeout(function () {
            context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
            dialog.value = false
          }, 1000)
        } else if (args.autorizacion == 3) {
          mensaje.value = 3
          setTimeout(function () {
            context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
            dialog.value = false
          }, 1000)
        } else {
          mensaje.value = 4
          setTimeout(function () {
            context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
            dialog.value = false
          }, 1000)
        }
      }
      cargando.value = false
    })
    const cancelar = () => {
      context.emit('GetConfirmacionUsuario', funcion, datosFuncion, funcionCancelar, true)
      dialog.value = false
    }
    return {
      dialog,
      abrir,
      tipoValidacion,
      numeroAleatorio,
      selectConfirmacion,
      enviarConfirmacion,
      temporizador,
      usuario,
      clave,
      mensaje,
      icons: {
        mdiCheckCircleOutline,
        mdiCancel,
      },
      cargando,
      idTran,
      cancelar,
      selectQR
    }
  },
}
</script>